import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCourse, getCourseData } from "../database/OptionsData";
import choosePayment from "../images/payment/pague.jpeg";
import pixPayment from "../images/payment/pix_icon.svg";
import cardPayment from "../images/payment/credit-card.svg";
import { PaymentProps } from "../interfaces/PropsModels";
import CustomQrCodePix from "./CustomQrCodePix";
import { Button, Form } from "react-bootstrap";
import IframePayment from "./IframePayment";

// Styles
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/StudentForm.css";
import "../styles/PaymentsComponent.css";
import { signOut } from "../services/SingOut";
import { TurmaOptionData } from "../interfaces/Turmas";

const PaymentsComponent: React.FC<PaymentProps> = (props: PaymentProps) => {
  const navigate = useNavigate();
  
  const [typePayment, setTypePayment] = useState(props.typePayment);
  const [isPaymented, setIsPaymented] = useState(true);

  // Lógica para verificar se a idade é menor que 18 anos
  const isUnder18 = () => {
    const currentYear = new Date().getFullYear();
    const birthYear = parseInt(props.birthday.substring(0, 4), 10);
    const age = currentYear - birthYear;

    return age < 18;
  };
  
  function getPaymentId(code: string): string {
    
    const cursoEscolhido: TurmaOptionData | any = getCourseData(code, props.cursoData);

    return cursoEscolhido ? cursoEscolhido.pay.creditCardId : '';

  }

  const handleSendPayment = () => {
    const url =
      "https://api.whatsapp.com/send?phone=558199592264&text=Acabei%20de%20efetuar%20minha%20matrícula!%20Segue%20abaixo%20meu%20comprovante%20de%20pagamento.";
    window.open(url, "_blank");
  };

  const handleAfterPayment = async () => {
    try {
      await signOut();
      // Redirecione para a página de login após o logout
      navigate("/");
    } catch (error: any) {
      console.error("Erro ao fazer logout:", error.message);
    }
  };

  return (
    <div className="form-container_student">
      <div className="form-box-student form-box-pix">
        <h1 className="payment-title">
          {props.cursoCode.includes("PMPE")
            ? "ISOLADA DE REDAÇÃO - PMPE/BMPE/PRAÇA/POLÍCIA CIVIL"
            : "Pagamento - Núcleo de Redação 2024"}
        </h1>

        {typePayment === "" ? (
          <div className="payments-container">
            <img src={choosePayment} alt="choose" width={125} />
            <p className="payment-subtitle">Escolha sua forma de pagamento?</p>
            <div className="payments-types">
              <div className="payment-types-img">
                <img
                  src={pixPayment}
                  alt="pix"
                  width={80}
                  height={80}
                  className="img-type-button"
                  onClick={() => setTypePayment("pix")}
                />
                <p className="text-type-button">PAGAR COM PIX</p>
              </div>
              <div className="payment-types-img">
                <img
                  src={cardPayment}
                  alt="card"
                  width={80}
                  height={80}
                  className="img-type-button"
                  onClick={() => setTypePayment("card")}
                />
                <p className="text-type-button">PAGAR COM CARTÃO</p>
              </div>
            </div>
          </div>
        ) : typePayment.includes("pix") ? (
          <>
            <p className="payment-description">
              Parabéns seu cadastro foi efetuado com sucesso e para que sua
              matrícula seja efetivada você deve efetuar o pagamento e enviar o
              comprovante para o whatsapp do Professor Elias Ancelmo, caso
              contrário seu cadastro será deletado da nossa base de dados.
            </p>
            <div>
              <p className="payment-userdata">
                <b>Nome do {isUnder18() ? 'Responsável' : 'Aluno'}:</b>{" "}
                {isUnder18() ? props.nomeDoResponsavel : props.nomeDoaluno}
              </p>
              <p className="payment-userdata">
                <b>Curso Matriculado:</b>{" "}
                {getCourse(props.cursoCode, props.cidade, props.cursoData)}
              </p>
              <p className="payment-userdata">
                <b>Valor Total:</b>{" "}
                {typePayment === "pix-mensalidade" ? 'Valor a ser combinado na secretaria do curso!' : props.valorFormatado}
              </p>
            </div>
            <div>
              <CustomQrCodePix
                valor={typePayment === "pix-mensalidade" ? 0 : props.valor}
              />
              <br />
              <Form>
                <div id="inline-checkbox" className="">
                  <Form.Check
                    inline
                    label="Confirmo o pagamento e agora vou enviar o comprovante para o Professor Elias"
                    name="group1"
                    type="checkbox"
                    id="inline-checkbox"
                    onChange={(event) => setIsPaymented(!event.target.checked)}
                  />
                </div>
              </Form>
              <div className="payment-buttons-footer">
                <Button
                  disabled={isPaymented}
                  variant="success"
                  onClick={handleSendPayment}
                  className=""
                >
                  ENVIAR COMPROVANTE
                </Button>
                {!props.isLogged && (
                  <Button
                    variant="secondary"
                    onClick={handleAfterPayment}
                    className=""
                  >
                    PAGAR DEPOIS
                  </Button>
                )}

                {props.isLogged && (
                  <Button
                    variant="secondary"
                    onClick={() => props.backPage()}
                    className=""
                  >
                    CANCELAR
                  </Button>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <IframePayment
              title=""
              width="100%"
              height="650"
              src={getPaymentId(props.cursoCode)}
            />
            <Form>
              <div id="inline-checkbox" className="text-black">
                <Form.Check
                  inline
                  label="Confirmo o pagamento e agora vou enviar o comprovante para o Professor Elias"
                  name="group1"
                  type="checkbox"
                  id="inline-checkbox"
                  onChange={(event) => setIsPaymented(!event.target.checked)}
                />
              </div>
            </Form>
            <div className="payment-buttons-footer">
              <Button
                disabled={isPaymented}
                variant="primary"
                onClick={handleSendPayment}
                className=""
              >
                ENVIAR COMPROVANTE
              </Button>
              {!props.isLogged && (
                <Button
                  variant="secondary"
                  onClick={handleAfterPayment}
                  className=""
                >
                  PAGAR DEPOIS
                </Button>
              )}

              {props.isLogged && (
                <Button
                  variant="secondary"
                  onClick={() => navigate(-1)}
                  className=""
                >
                  CANCELAR
                </Button>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PaymentsComponent;
