import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import '../styles/LoginPage.css'

const RecaptchaComponent = (props: any) => {
  function onChange(value: any) {
    //console.log("Captcha value:", value);
    props.getToken(value);
  }

  return (
    <div style={{display: 'flex', justifyContent: 'center', marginTop: '10px'}}>
      <ReCAPTCHA
        sitekey="6LcIYWIpAAAAAHhaCPNyAIKXtZHJPXdx1CjrlGpg"
        //sitekey="6Ld1jWIpAAAAAH0RMmFQILq7QqFGkR9I5Nv_7NTE" //invisible token
        theme="light"
        size="normal"
        onChange={onChange}
        className="recaptcha-container"
      />
    </div>
  );
};

export default RecaptchaComponent;
