import React, { useEffect, useState } from "react";
import AlertModal from "../components/AlertModal";
import "../styles/AdmsCreateNewUsers.css";
import "../styles/AdmsCreateCourses.css";
import { TurmaOptionData } from "../interfaces/Turmas";
import {
  getCoursesFirestore,
  removeCoursesFirestore,
  updateCourseFirestore,
} from "../database/firestore";

const AdmsUpdateCourses = (props: { state: any }) => {
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [courses, setCourses] = useState<TurmaOptionData[]>([]);
  const [editMode, setEditMode] = useState<string | null>(null);
  const [editedCourseCode, setEditedCourseCode] = useState("");
  const [editedNomeDoCurso, setEditedNomeDoCurso] = useState("");
  const [editedDisabled, setEditedDisabled] = useState(false);
  const [editedFormattedValue, setEditedFormattedValue] = useState("");
  const [editedValue, setEditedValue] = useState(0.0);
  const [editedCreditCardId, setEditedCreditCardId] = useState("");

  // Função para carregar os cursos ao montar o componente
  useEffect(() => {
    const loadCourses = async () => {
      try {
        const coursesData = await getCoursesFirestore();
        setCourses(coursesData);
        //localStorage.setItem("courses", JSON.stringify(coursesData));
      } catch (error) {
        console.error("Erro ao carregar cursos:", error);
      }
    };

    //Carrega apenas uma vez a chamada;
    loadCourses();
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
    setError("");
    setSuccessMessage("");
  };

  const handleEditClick = (courseId: string) => {
    const courseToEdit = courses.find((course) => course.id === courseId);

    if (courseToEdit) {
      setEditedCourseCode(courseToEdit.courseCode);
      setEditedNomeDoCurso(courseToEdit.nomeDoCurso);
      setEditedDisabled(courseToEdit.disabled);
      setEditedFormattedValue(courseToEdit.pay.formattedValue);
      setEditedValue(courseToEdit.pay.value);
      setEditedCreditCardId(courseToEdit.pay.creditCardId);
      setEditMode(courseId);
    }
  };

  const handleUpdateClick = async (course: TurmaOptionData) => {
    try {
      // Crie um novo objeto com os dados atualizados
      const updatedCourse: TurmaOptionData = {
        ...course,
        courseCode: editedCourseCode,
        nomeDoCurso: editedNomeDoCurso,
        disabled: editedDisabled,
        pay: {
          formattedValue: editedFormattedValue,
          value: editedValue,
          creditCardId: editedCreditCardId,
        },
      };

      await updateCourseFirestore(updatedCourse);
      setEditMode(null);
      // Atualize a lista de cursos após a edição
      //loadCourses();

      setSuccessMessage("CURSO ATUALIZADO COM SUCESSO");
      setShowModal(true);
    } catch (error) {
      console.error("Erro ao atualizar curso:", error);
    }
  };

  const handleCancelEdit = () => {
    setEditMode(null);
  };

  const handleRemoveClick = async (courseId: string) => {
    try {
      // Adicione aqui a lógica para remover o curso do Firestore
      await removeCoursesFirestore(courseId);
      // Em seguida, atualize a lista de cursos
      //loadCourses();
    } catch (error) {
      console.error("Erro ao remover curso:", error);
    }
  };

  return (
    <div className="adms-page-create">
      {showModal && successMessage && (
        <AlertModal message={successMessage} onClose={handleCloseModal} />
      )}

      {showModal && error && (
        <AlertModal message={error} onClose={handleCloseModal} />
      )}

      <div
        style={{
          width: "100%",
          overflow: "auto",
          borderRadius: "8px",
          backgroundColor: "#fff",
          padding: "10px",
        }}
      >
        <table className="styled-table">
          <thead>
            <tr>
              <th>Código do Curso</th>
              <th>Nome do Curso</th>
              <th>Bloquear Curso</th>
              <th>Valor Formatado</th>
              <th>Valor Numérico</th>
              <th>Link Cartão de Crédito</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {courses.map((course) => (
              <tr
                key={course.id}
                className={editMode === course.id ? "edit-mode" : ""}
              >
                <td>
                  {editMode === course.id ? (
                    <div
                      className="form-control"
                      style={{ border: "none", width: "100%" }}
                    >
                      <input
                        type="text"
                        value={editedCourseCode}
                        onChange={(e) => setEditedCourseCode(e.target.value)}
                        className="input-field"
                        style={{ color: "#000", border: "1px solid #000" }}
                      />
                    </div>
                  ) : (
                    course.courseCode
                  )}
                </td>
                <td>
                  {editMode === course.id ? (
                    <div
                      className="form-control"
                      style={{ border: "none", width: "100%" }}
                    >
                      <textarea
                        //type="text"
                        value={editedNomeDoCurso}
                        onChange={(e) => setEditedNomeDoCurso(e.target.value)}
                        className="input-field"
                        style={{ color: "#000", border: "1px solid #000" }}
                      />
                    </div>
                  ) : (
                    course.nomeDoCurso
                  )}
                </td>
                <td>
                  {editMode === course.id ? (
                    <input
                      type="checkbox"
                      checked={editedDisabled}
                      onChange={(e) => setEditedDisabled(e.target.checked)}
                      className="input-field"
                      style={{ color: "#000", border: "1px solid #000" }}
                    />
                  ) : (
                    String(course.disabled)
                  )}
                </td>
                <td>
                  {editMode === course.id ? (
                    <div
                      className="form-control"
                      style={{ border: "none", width: "100%" }}
                    >
                      <input
                        type="text"
                        value={editedFormattedValue}
                        onChange={(e) =>
                          setEditedFormattedValue(e.target.value)
                        }
                        className="input-field"
                        style={{ color: "#000", border: "1px solid #000" }}
                      />
                    </div>
                  ) : (
                    course.pay.formattedValue
                  )}
                </td>
                <td>
                  {editMode === course.id ? (
                    <input
                      type="number"
                      value={editedValue.toString()}
                      onChange={(e) =>
                        setEditedValue(parseFloat(e.target.value))
                      }
                      className="input-field"
                      style={{ color: "#000", border: "1px solid #000" }}
                    />
                  ) : (
                    course.pay.value
                  )}
                </td>
                <td>
                  {editMode === course.id ? (
                    <div
                      className="form-control"
                      style={{ border: "none", width: "100%" }}
                    >
                      <input
                        type="text"
                        value={editedCreditCardId}
                        onChange={(e) => setEditedCreditCardId(e.target.value)}
                        className="input-field"
                        style={{ color: "#000", border: "1px solid #000" }}
                      />
                    </div>
                  ) : (
                    course.pay.creditCardId
                  )}
                </td>
                <td>
                  {editMode === course.id ? (
                    <div style={{ display: "flex", gap: "2px" }}>
                      <button
                        className="btn btn-primary update-btn"
                        onClick={() => handleUpdateClick(course)}
                      >
                        ATUALIZAR
                      </button>
                      <button
                        className="btn btn-secondary cancel-btn"
                        onClick={handleCancelEdit}
                      >
                        CANCELAR
                      </button>
                    </div>
                  ) : (
                    <div style={{ display: "flex", gap: "2px" }}>
                      <button
                        className="edit-btn btn btn-warning"
                        onClick={() => handleEditClick(course.id)}
                      >
                        EDITAR
                      </button>
                      <button
                        className="btn btn-danger"
                        onClick={() => handleRemoveClick(course.id)}
                      >
                        REMOVER
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdmsUpdateCourses;
