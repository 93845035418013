import * as React from 'react';
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport, GridValueGetterParams } from '@mui/x-data-grid';
import '../styles/TableDataGrid.css'

const localizedTextsMap = {
  columnMenuUnsort: "não classificado",
  columnMenuSortAsc: "Classificar por ordem crescente",
  columnMenuSortDesc: "Classificar por ordem decrescente",
  columnMenuFilter: "Filtro",
  columnMenuHideColumn: "Ocultar",
  columnMenuShowColumns: "Mostrar colunas",
  noRowsLabel: 'Sem matriculados nessa turma!',
  noResultsOverlayLabel: 'Sem dados encontrados! Erro na api!',
  columnHeaderFiltersLabel: 'Mostrar filtros',
  columnHeaderSortIconLabel: 'Ordenação',
  // Export selector toolbar button text
  toolbarExport: 'Exportar',
  toolbarExportLabel: 'Exportar',
  toolbarExportCSV: 'Baixar em Excel',
  toolbarExportPrint: 'Imprimir/Baixar em PDF',
  toolbarExportExcel: 'Baixar como Excel',
  //MuiTablePagination: {rowsPerPage: 'Cabrito'},

};

interface TableProps {
  rows: any;
}

export default function TabelaPacotes(props: TableProps) {

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Id', width: 50 },
    { field: 'cpf', headerName: 'CPF', width: 150 },
    { field: 'nome', headerName: 'Nome', width: 200 },
    { field: 'telefone', headerName: 'Telefone', width: 150 },
    { field: 'dataDeCompra', headerName: 'Data da Compra', width: 350 },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport csvOptions={{
                delimiter: ';',
                utf8WithBom: true,
              }}/>
      </GridToolbarContainer>
    );
  }

  return (
    <div className='bg-white rounded table-data-grid'>      
      <DataGrid
        autoHeight={true}
        rows={props.rows}
        columns={columns}
        //pageSize={25}
        //rowsPerPageOptions={[10, 15, 20, 25]}
        checkboxSelection={true}
        localeText={localizedTextsMap}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
    </div>
  );
}