import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import loading from "../images/home/background-default.gif";
import AlertModal from "../components/AlertModal";
import "../styles/AdmsCreateNewUsers.css";
import "../styles/AdmsCreateCourses.css";
import { PackageData } from "../interfaces/Turmas";
import { v4 as uuidv4 } from "uuid"; // Importa a função v4 da biblioteca uuid
import {
  addPackagesFirestore,
  getPackagesFirestore,
  removePackageFirestore,
} from "../database/firestore";
import { Spinner } from "react-bootstrap";
import TabelaPacotes from "../components/TabelaPacotes";
import { AlunoPackages } from "../interfaces/Students";
import { FaTrash, FaUserAlt } from "react-icons/fa";

const AdmsCreatePackages = (props: {state: any}) => {
  const { state } = props;

  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [nomeDoPacote, setNomeDoPacote] = useState("");
  const [qtdPacotes, setQtdPacotes] = useState(0);
  const [packages, setPackages] = useState<PackageData[]>([]);
  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [linhas, setLinhas] = useState<any>([]);
  const [valorDoPacoteFormatted, setValorDoPacoteFormatted] = useState("");
  const [valorDoPacote, setValorDoPacote] = useState(0.0);


  const loadPacotes = async () => {
    try {
      let pacotes = await getPackagesFirestore();
      setPackages(pacotes);
    } catch (error) {
      setError(`Erro ao buscar pacotes: ${error}`);
      setShowModal(true);
    }
  };


  const handleCloseModal = () => {
    setShowModal(false);
    setError("");
    setSuccessMessage("");
    // Optionally, clear the email and password fields after successful signup
  };

  const handleCreateSchedule = async () => {
    setSpinnerLoading(true);

    const packageData: PackageData = {
      id: uuidv4(),
      created: Date.now().toString(),
      disabled: false,
      qtdPacotes,
      alunosQueCompraram: [],
      nomeDoPacote,
      pacoteCode: "PCT" + (packages.length + 1),
      valorNumber: valorDoPacote,
      valor: valorDoPacoteFormatted,
    };

    try {
      await addPackagesFirestore(packageData);
      // SignUp successful, update the state with success message
      setSuccessMessage("Cadastro do pacote foi realizado com sucesso!");
      setShowModal(true);
      setSpinnerLoading(false);

      //limpar os inputs
      setNomeDoPacote("");
      setQtdPacotes(0);

      //chama os pacotes
      loadPacotes();
      // window.location.reload();
    } catch (error: any) {
      setError(error.message);
      setShowModal(true);
      setSpinnerLoading(false);
    }
  };

  function handleClick(id: string) {
    const pacoteSelected = packages.find((item) => item.id === id);

    let alunosArray: Array<AlunoPackages> =
      pacoteSelected?.alunosQueCompraram ?? [];

    if (alunosArray.length > 0) {
      setLinhas(alunosArray);

      setShowTable(true);
    } else {
      setError("Nenhum aluno agendou para este atendimento");
      setShowModal(true);
    }
  }

  function pacoteCard(pacote: PackageData) {
    return (
        <div className="card" 
        //onClick={() => handleClick(pacote.id)}
        >
          <div className="card-body">
            {/* <h5 className="card-title">{schedule.id}</h5> */}
            <h4 className="card-subtitle mb-2 bold">{pacote.nomeDoPacote}</h4>
            <p className="card-text">
              <span style={{ fontWeight: "bold", fontStyle: "normal" }}>
                Código do Pacote:
              </span>
              {` ${pacote.pacoteCode}`}
            </p>
            <p className="card-text">
              <span style={{ fontWeight: "bold", fontStyle: "normal" }}>
                Pacotes disponíveis:
              </span>
              {` ${pacote.qtdPacotes}`}
            </p>
            <p className="card-text">
              <span style={{ fontWeight: "bold", fontStyle: "normal" }}>
                Quantidade de Alunos que compraram:
              </span>
              {` ${pacote.alunosQueCompraram.length}`}
            </p>
          </div>

          <div className="card-buttons-container">
          <button
            className="card-button"
            onClick={() => handleClick(pacote.id)}
          >
            <FaUserAlt /> <span className="ml-2">Alunos</span>
          </button>

          <button
            className="card-button card-button-delete"
            onClick={() => handleDeletePacote(pacote.id)}
          >
            <FaTrash /> <span className="ml-2">Apagar</span>
          </button>
        </div>
        </div>
    );
  }

  function pacoteList() {
    const activePackages = packages.filter((item) => item.disabled === false);

    const sortedPackages = [...activePackages].sort((a, b) => {
      let dateA = a.created
        ? new Date(a.created).getTime()
        : new Date().getTime();
      let dateB = b.created
        ? new Date(b.created).getTime()
        : new Date().getTime();
      return dateA - dateB;
    });
    return (
      <div className="container-schedules">
        <div className="row">
          {sortedPackages.map((pacote, index) => (
            <div className="col-sm-6 mb-3" key={index}>
              {pacoteCard(pacote)}
            </div>
          ))}
        </div>
      </div>
    );
  }

  const handleVoltar = () => {
    setShowTable(false);
  };

  const handleDeletePacote = async (pacoteId: string) => {
    await removePackageFirestore(pacoteId);
    loadPacotes();
    // window.location.reload();
  }

  return (
    <div className="adms-page-create">
      <div className="card-container-users">
        <strong
          style={{
            display: "flex",
            textAlign: "center",
            width: "100%",
            color: "#fff",
            textTransform: "uppercase",
            justifyContent: "center",
            fontSize: "24px",
            marginBottom: "20px",
          }}
        >
          Criar pacotes de redação
        </strong>

        <form className="form-signup new-courses was-validated gap-4 d-flex flex-column">
          <div className="div-form-group">
            <label className="div-input-label" htmlFor="turma">
              Nome do Pacote:
            </label>
            <input
              type="text"
              className="div-input-field"
              id="turma"
              value={nomeDoPacote}
              onChange={(event) => setNomeDoPacote(event.target.value)}
              required
            />
          </div>

          <div className="div-form-group">
            <label className="div-input-label" htmlFor="qtdVagas">
              Quantidade de pacotes disponíveis:
            </label>
            <input
              className="div-input-field"
              type="number"
              id="qtdVagas"
              min={1}
              value={qtdPacotes}
              onChange={(event) => setQtdPacotes(parseInt(event.target.value))}
              required
            />
          </div>

          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="R$ 0,00 - Valor do Pacote"
              value={valorDoPacoteFormatted}
              onChange={(e) => {
                const numericValue = e.target.value.replace(/[^\d.,]/g, ""); // Remove caracteres não numéricos, exceto ponto e vírgula

                var valor = numericValue;

                valor = valor + "";
                valor = parseInt(valor.replace(/[\D]+/g, "")).toString();
                valor = valor + "";
                valor = valor.replace(/([0-9]{2})$/g, ",$1");

                if (valor.length > 6) {
                  valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
                }

                e.target.value = `R$ ${valor}`;
                if (valor === "NaN") e.target.value = "";

                setValorDoPacoteFormatted(e.target.value);

                let value = parseFloat(valor.replace(",", "."));

                setValorDoPacote(value);
              }}
              required
              autoComplete="off"
            />
          </div>

          <button
            disabled={spinnerLoading}
            className="btn btn-primary"
            onClick={handleCreateSchedule}
            style={{ width: "100%", maxWidth: "200px", alignSelf: "center" }}
          >
            {spinnerLoading ? (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              "CRIAR PACOTE"
            )}
          </button>
        </form>

        {showModal && successMessage && (
          <AlertModal message={successMessage} onClose={handleCloseModal} />
        )}

        {showModal && error && (
          <AlertModal message={error} onClose={handleCloseModal} />
        )}

        <div className="card-container-schedules w-100 mt-4">
          <div
            className="w-100"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <strong
              style={{
                display: "flex",
                textAlign: "center",
                width: "100%",
                color: "#fff",
                textTransform: "uppercase",
                justifyContent: "center",
                fontSize: "24px",
                marginBottom: "20px",
              }}
            >
              {packages.length
                ? "Pacotes disponíveis"
                : "Nenhum pacote encontrado no momento"}
            </strong>
            <div className="w-100 d-flex card-total-schedules">
              {!showTable ? (
                <div
                  id="totalResults"
                  className="w-100 d-flex total-results-schedules"
                >
                  {pacoteList()}
                </div>
              ) : (
                <div></div>
              )}
            </div>

            {showTable ? (
              <button
                onClick={handleVoltar}
                className="mb-3 p-2 btn btn-danger w-100"
                style={{
                  maxWidth: "200px",
                  alignSelf: "center",
                  textAlign: "center",
                }}
              >
                FECHAR LISTA
              </button>
            ) : (
              <div></div>
            )}

            {showTable && <TabelaPacotes rows={linhas} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdmsCreatePackages;
