import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { auth } from "../database/firebaseConfig";
import { getFileURL } from "../database/storage";
import loading from "../images/home/background-default.gif";
import {
  getCoursesFirestore,
  getStudentFullDataFirestoreByIdOrEmail,
  getStudentUserFirestoreByUidOrEmail,
} from "../database/firestore";
import { StudentData, UserData } from "../interfaces/Students";
import "../styles/StudentsPage.css";
import { signOut } from "../services/SingOut";
import { TurmaOptionData } from "../interfaces/Turmas";
import StudentDrawer from "../components/StudentMenuDrawer";

const Students: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const uid = state?.uid;
  const role = state?.role;
  const email = state?.email;
  const userData = state?.userData;
  const signup = state?.signup;
  const [imgUrl, setImgUrl] = useState(loading);
  const [userFirstData, setUserFirstData] = useState<UserData>({
    uid,
    id: uid,
    isFirstAccess: userData?.isFirstAccess,
    email,
    role,
  });
  const [studentFullData, setStudentFullData] = useState<StudentData | any>({});
  const [opcoesTurmaData, setOpcoesTurmaData] = useState<TurmaOptionData[]>([]);

  //check is first access of the user
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        navigate("/");
      }
    });

    // Define a função dentro do escopo do useEffect
    const checkIsFirstAccess = async () => {
      try {
        const user: UserData = await getStudentUserFirestoreByUidOrEmail(
          "uid",
          uid
        );

        if (user.role === "adm") {
          handleLogout();
        }



        if (user) {
          setUserFirstData(user);
        }
      } catch (error) {
        console.error("Erro ao carregar a imagem de fundo:", error);
      }
    };

    // Chama a função apenas uma vez quando o componente é montado
    checkIsFirstAccess();

    // Define a função dentro do escopo do useEffect
    const checkIsPaymentMatricula = async () => {
      try {
        const student: StudentData =
          await getStudentFullDataFirestoreByIdOrEmail("id", uid);

        if (student) {
          setStudentFullData(student);

          if (student.bloqueado) {
            handleLogout();
          }
        }
      } catch (error) {
        console.error("Erro ao carregar a imagem de fundo:", error);
      }
    };

    // Chama a função apenas uma vez quando o componente é montado
    checkIsPaymentMatricula();

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // Carrega a imagem de fundo ao montar o componente
    const loadBackgroundImage = async () => {
      try {
        // const imageUrl = await getFileURL("login-page/background");
        let imageUrl = await getFileURL("students-page/background");
        setImgUrl(imageUrl);

        document.body.style.backgroundImage = `url(${imageUrl})`;
      } catch (error) {
        console.error("Erro ao carregar a imagem de fundo:", error);
      }
    };

    loadBackgroundImage();
  }, []);

  const handleLogout = async () => {
    try {
      await signOut();
      // Redirecione para a página de login após o logout
      //navigate("/");
    } catch (error: any) {
      console.error("Erro ao fazer logout:", error.message);
    }
  };

  useEffect(() => {
    const cursosData = async () => {
      try {
        let cursos = await getCoursesFirestore();
        setOpcoesTurmaData(cursos);
        localStorage.setItem("courses", JSON.stringify(cursos));
      } catch (error) {
        console.error(
          "Erro ao carregar os cursos cadastrados pelo professor:",
          error
        );
      }
    };

    cursosData();
  }, []);

  

  return (
    <div className="students-page">
      <StudentDrawer
        isFirstPage={true}
        nome={studentFullData.name}
        user={userFirstData}
        imgUrl={imgUrl}
        role={"student"}
        curso={opcoesTurmaData}
        studentData={studentFullData}
        isSignup={signup}
      />
    </div>
  );
};

export default Students;
