import React from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import '../styles/AlertModal.css';
import { AlertModalProps } from '../interfaces/PropsModels';

const AlertModal = (props: AlertModalProps) => {
  return (
    <div className="alert-modal-overlay">
      <div className="alert-modal">
        <div className="alert-icon">
          <FaExclamationTriangle />
        </div>
        <div className="alert-message">{props.message}</div>
        <button className="alert-button" onClick={props.onClose}>
          OK, entendi!
        </button>
      </div>
    </div>
  );
};

export default AlertModal;
