import * as React from "react";
import {
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridToolbarExport,
  GridPrintExportOptions,
} from "@mui/x-data-grid";
import "../styles/TableDataGrid.css";

const localizedTextsMap = {
  columnMenuUnsort: "não classificado",
  columnMenuSortAsc: "Classificar por ordem crescente",
  columnMenuSortDesc: "Classificar por ordem decrescente",
  columnMenuFilter: "Filtro",
  columnMenuHideColumn: "Ocultar",
  columnMenuShowColumns: "Mostrar colunas",
  noRowsLabel: "Sem matriculados nessa turma!",
  noResultsOverlayLabel: "Sem dados encontrados! Erro na api!",
  columnHeaderFiltersLabel: "Mostrar filtros",
  columnHeaderSortIconLabel: "Ordenação",
  // Export selector toolbar button text
  toolbarExport: "Exportar",
  toolbarExportLabel: "Exportar",
  toolbarExportCSV: "Baixar em Excel",
  toolbarExportPrint: "Imprimir/Baixar em PDF",
  toolbarExportExcel: "Baixar como Excel",
  //MuiTablePagination: {rowsPerPage: 'Cabrito'},
};

interface TableProps {
  rows: any;
}

export default function TabelaAtendimentos(props: Readonly<TableProps>) {
  let i = 1;

  const updatedRows = props.rows.map((item: any) => {
    return { ...item, order: i++ };
  });
  
  // Agora você pode atribuir updatedRows ao seu componente ou usá-lo diretamente
  props = {
    ...props,
    rows: updatedRows
  };

  const columns: GridColDef[] = [
    { field: "order", headerName: "Nº", width: 50 },
    { field: "createdAt", headerName: "Protocolo", width: 200 },
    { field: "scheduleType", headerName: "Tipo de Atendimento", width: 250 },
    { field: "cpf", headerName: "CPF", width: 150 },
    { field: "nome", headerName: "Nome", width: 200 },
    { field: "telefone", headerName: "Telefone", width: 150 },
    { field: "corretor", headerName: "Corretor", width: 150 },
    { field: "dataAtendimento", headerName: "Data do Atendimento", width: 350 },
    { field: "id", headerName: "id", width: 100 },
  ];

  const printOptions: GridPrintExportOptions = {
    hideFooter: true, 
    hideToolbar: true,
    includeCheckboxes: true,
    copyStyles: true,
    bodyClassName: 'custom-print-body',
    pageStyle: `
      @media print {
        @page {
          size: landscape; /* Define a orientação como paisagem */
          margin: 10mm; /* Adiciona uma margem ao redor da página */
        }
        body {
          transform: scale(0.95); /* Aplica a escala de 75% */
          transform-origin: top left;
          padding: 10mm; /* Adiciona padding ao corpo para espaçamento interno */
        }
        .MuiDataGrid-root {
          width: 100% !important; /* Calcula a largura para considerar as margens */
          max-width: 100vw; /* Considera as margens na largura máxima */
          height: auto !important; /* Ajusta a altura automaticamente */
          overflow: visible !important; /* Evita que o conteúdo seja cortado */
          page-break-inside: avoid; /* Evita quebras de página dentro do DataGrid */
          margin: auto; /* Centraliza a tabela */
          display: flex; /* Usa flexbox para garantir ajuste automático */
          flex-wrap: wrap; /* Permite que colunas se ajustem */
        }
        .MuiDataGrid-cell, .MuiDataGrid-colCell {
          white-space: nowrap; /* Impede quebra de linha dentro das células */
          overflow: hidden; /* Esconde o overflow para evitar conteúdo fora dos limites */
          text-overflow: ellipsis; /* Adiciona reticências no overflow */
        }
        .MuiDataGrid-colCell {
          max-width: none; /* Remove largura máxima das colunas */
          flex: 1; /* Permite que as colunas se ajustem proporcionalmente */
        }
        .MuiDataGrid-row {
          page-break-inside: avoid; /* Evita quebras de página no meio de uma linha */
        }
      }
    `,
  };
  function CustomToolbar() { 
    return (
      <GridToolbarContainer>
        <GridToolbarExport
          csvOptions={{
            delimiter: ";",
            utf8WithBom: true,
          }}
          printOptions={printOptions}
        />
      </GridToolbarContainer>
    );
  };

  return (
    <div className="bg-white rounded table-data-grid">
      <DataGrid
        autoHeight={true}
        rows={props.rows}
        columns={columns}
        //pageSize={25}
        //rowsPerPageOptions={[10, 15, 20, 25]}
        checkboxSelection={true}
        localeText={localizedTextsMap}
        slots={{
          toolbar: CustomToolbar,
        }}
      />
    </div>
  );
}
