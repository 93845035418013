import React from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import '../styles/AlertModalConfirmations.css';
import { AlertModalConfirmationsProps } from '../interfaces/PropsModels';

const AlertModalConfirmations = (props: AlertModalConfirmationsProps) => {
  return (
    <div className="alert-modal-confirm-overlay">
      <div className="alert-modal-confirm">
        <div className="alert-confirm-icon">
          <FaExclamationTriangle />
        </div>
        {/* <div className="alert-confirm-message">{props.message}</div> */}
        <div className="alert-confirm-message" dangerouslySetInnerHTML={{ __html: props.message }} />
        <button className="alert-confirm-button" onClick={props.onConfirm}>
          OK, pode remover!
        </button>
        <button className="alert-confirm-button" onClick={props.onClose}>
          Cancelar
        </button>
      </div>
    </div>
  );
};

export default AlertModalConfirmations;
