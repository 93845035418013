import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { auth } from "../database/firebaseConfig";
import { getFileURL } from "../database/storage";
// import CardIcons from "../components/CardIcons";
// import { FaUserPlus } from "react-icons/fa6";
// import { FaUserPen } from "react-icons/fa6";
import loading from "../images/home/background-default.gif";
// import { MdCreateNewFolder } from "react-icons/md";
import { UserData } from "../interfaces/Students";
// import { GiPapers, GiPayMoney } from "react-icons/gi";
// import { PiStudentFill } from "react-icons/pi";
// import { TfiWrite } from "react-icons/tfi";
// import { GrScheduleNew } from "react-icons/gr";
import "../styles/AdmsPage.css";
// import AdmHeader from "../components/AdmHeader";
// import { BsListColumnsReverse } from "react-icons/bs";
// import { LuPackagePlus } from "react-icons/lu";
import { signOut } from "../services/SingOut";
import { TurmaOptionData } from "../interfaces/Turmas";
import { getCoursesFirestore } from "../database/firestore";
import AdmDrawer from "../components/MenuDrawer";


const Adms: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const uid = state?.uid;
  const email = state?.email;
  const role = state?.role ? state?.role : "student";

  const [imgUrl, setImgUrl] = useState(loading);
  const userFirstData: UserData = {
    uid: uid,
    id: uid,
    isFirstAccess: false,
    email: email,
    role: role,
  };

  const [opcoesTurmaData, setOpcoesTurmaData] = useState<TurmaOptionData[]>([]);

  // Check if the user is already signed in
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        navigate("/");
      }
    });

    // Cleanup the subscription when the component unmounts
    return () => unsubscribe();
  }, [navigate]);

  //check is first access of the user
  useEffect(() => {
    if (role === "student") {
      handleLogout();
    }
  }, [role]);

  useEffect(() => {
    // Carrega a imagem de fundo ao montar o componente
    const loadBackgroundImage = async () => {
      try {
        // const imageUrl = await getFileURL("login-page/background");
        let imageUrl = await getFileURL("adms-page/background");
        document.body.style.backgroundImage = `url(${imageUrl})`;
        setImgUrl(imageUrl);
      } catch (error) {
        console.error("Erro ao carregar a imagem de fundo:", error);
      }
    };

    loadBackgroundImage();
  }, []);

  const handleLogout = async () => {
    try {
      await signOut();
      // Redirecione para a página de login após o logout
      //navigate("/");
    } catch (error: any) {
      console.error("Erro ao fazer logout:", error.message);
    }
  };

  useEffect(() => {
    const cursosData = async () => {
      try {
        let cursos = await getCoursesFirestore();
        setOpcoesTurmaData(cursos);
        //localStorage.setItem("courses", JSON.stringify(cursos));
      } catch (error) {
        console.error(
          "Erro ao carregar os cursos cadastrados pelo professor:",
          error
        );
      }
    };

    cursosData();
  }, []);

  return (
    <div className="adms-page">
      <AdmDrawer 
        isFirstPage={true} 
        nome={`${role}`} 
        user={userFirstData}
        imgUrl={imgUrl}
        role={role}
        curso={opcoesTurmaData}
      />
      {/* <AdmHeader isFirstPage={true} nome={`Bem-vindo ${role}`} /> */}

      {/* <div className="adms-page-container">
        {role !== "corretor" && (
          <>
            <CardIcons
              text="RELATÓRIOS DOS ALUNOS"
              url="/app/adms/logged/reports"
              color="green"
              user={userFirstData}
              imgUrl={imgUrl}
              iconCode={BsListColumnsReverse}
              role={role}
              curso={opcoesTurmaData}
            />

            <CardIcons
              text="CRIAR TURMAS"
              url="/app/adms/logged/adm/new/courses"
              color="purple"
              user={userFirstData}
              imgUrl={imgUrl}
              iconCode={MdCreateNewFolder}
              role={role}
              curso={opcoesTurmaData}
            />

            <CardIcons
              text="CRIAR ATENDIMENTOS"
              url="/app/adms/logged/adm/new/schedules"
              color="firebrick"
              imgUrl={imgUrl}
              user={userFirstData}
              iconCode={GrScheduleNew}
              role={role}
              curso={opcoesTurmaData}
            />

            <CardIcons
              text="CRIAR / VISUALIZAR PACOTES"
              url="/app/adms/logged/adm/new/packages"
              color="darkgreen"
              user={userFirstData}
              imgUrl={imgUrl}
              iconCode={LuPackagePlus}
              role={role}
              curso={opcoesTurmaData}
            />

            <CardIcons
              text="CADASTRAR USUÁRIOS"
              url="/app/adms/logged/adm/new/users"
              color="brown"
              user={userFirstData}
              imgUrl={imgUrl}
              iconCode={FaUserPlus}
              role={role}
              curso={opcoesTurmaData}
            />

            <CardIcons
              text="CONFIRMAR PAGAMENTOS"
              url="/app/adms/logged/student/payments"
              color="yellowgreen"
              user={userFirstData}
              imgUrl={imgUrl}
              iconCode={GiPayMoney}
              role={role}
              curso={opcoesTurmaData}
            />

            <CardIcons
              text="EDITAR OU REMOVER"
              url="/app/adms/logged/update/user/edit"
              color="blue"
              imgUrl={imgUrl}
              user={userFirstData}
              iconCode={FaUserPen}
              role={role}
              curso={opcoesTurmaData}
            />

            <CardIcons
              text="VISUALIZAR ATENDIMENTOS"
              url="/app/adms/logged/adm/view/schedules"
              color="orange"
              imgUrl={imgUrl}
              user={userFirstData}
              iconCode={GrScheduleNew}
              role={role}
              curso={opcoesTurmaData}
            />

            <CardIcons
              text="ENTREGA DE REDAÇÕES"
              url="https://nucleo-de-redacao.ecorrecao.com.br/student/login"
              color="orange"
              imgUrl={imgUrl}
              user={userFirstData}
              iconCode={TfiWrite}
              role={role}
              curso={opcoesTurmaData}
            />

            <CardIcons
              text="PLATAFORMA DE MATERIAIS"
              url="https://aluno.eliasancelmo.com.br/"
              color="purple"
              imgUrl={imgUrl}
              user={userFirstData}
              iconCode={PiStudentFill}
              role={role}
              curso={opcoesTurmaData}
            />

            <CardIcons
              text="PACOTES DE REDAÇÃO"
              url=""
              color="green"
              imgUrl={imgUrl}
              user={userFirstData}
              iconCode={GiPapers}
              role={role}
              curso={opcoesTurmaData}
            />

            <CardIcons
              text="PERSONALIZAR PLATAFORMA"
              url="/app/adms/logged/personalizate"
              color="magenta"
              imgUrl={imgUrl}
              user={userFirstData}
              iconCode={GrScheduleNew}
              role={role}
              curso={opcoesTurmaData}
            />

            <CardIcons
              text="SIMULADOS"
              url="https://app.minhaprova.com.br"
              color="Goldenrod"
              imgUrl={imgUrl}
              user={userFirstData}
              iconCode={PiStudentFill}
              role={role}
              curso={opcoesTurmaData}
            />
          </>
        )}

        {role === "corretor" && (
          <>
            <CardIcons
              text="CRIAR ATENDIMENTOS"
              url="/app/adms/logged/adm/new/schedules"
              color="firebrick"
              imgUrl={imgUrl}
              user={userFirstData}
              iconCode={GrScheduleNew}
              role={role}
              curso={opcoesTurmaData}
            />

            <CardIcons
              text="VISUALIZAR ATENDIMENTOS"
              url="/app/adms/logged/adm/view/schedules"
              color="orange"
              imgUrl={imgUrl}
              user={userFirstData}
              iconCode={GrScheduleNew}
              role={role}
              curso={opcoesTurmaData}
            />
          </>
        )}
      </div> */}
    </div>
  );
};

export default Adms;
