import React from "react";
import "../styles/StudentsPage.css";
import MonthlyPaymentStatus from "../components/MonthlyPaymentStatus";

const StudentPayment = (props: {state: any}) => {
  const { state } = props;
  const user = state?.userFullData;


  return (
    <div className="students-page-payment">
      <div className="payments-content">
        <div>
          <MonthlyPaymentStatus studentData={user} state={state} />
        </div>
      </div>
    </div>
  );
};

export default StudentPayment;
