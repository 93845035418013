import React, { FormEvent, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ModalScheduleProps } from "../interfaces/Adms";
import { ScheduleData } from "../interfaces/Turmas";
import { updateSchedulesFirestore } from "../database/firestore";
import "../styles/AdmsViewSchedules.css";

export default function ModalEdit(props: ModalScheduleProps) {

  let calendar = new Date().toISOString().split("T")[0];

  const [show, setShow] = useState(props.open);

  const scheduleSelected: ScheduleData = props.handleScheduleSelected(
    props.idSchedule
  );

  const [corretor, setCorretor] = useState(scheduleSelected.corretor);
  const [scheduleDate, setScheduleDate] = useState(
    scheduleSelected.dataDoAgendamento
  );
  const [startTime, setStartTime] = useState(scheduleSelected.startTime);
  const [stopTime, setStopTime] = useState(scheduleSelected.stopTime);
  const [qtdVagas, setQtdVagas] = useState(scheduleSelected.qtdVagas);
  const [cidade, setCidade] = useState(scheduleSelected.cidade);
  const [turma, setTurma] = useState(scheduleSelected.turma);
  const [disabledText, setDisabledText] = useState(scheduleSelected.disabled ? 'ENCERRADO' : 'ATIVO');
  const [disabled, setDisabled] = useState(scheduleSelected.disabled);

  const handleClose = () => {
    props.close();
    setShow(false);
  };

  const handleSubscribe = async (event: FormEvent) => {
    event.preventDefault();

    const schedule: ScheduleData = {
      id: scheduleSelected.id,
      corretor,
      dataDoAgendamento: scheduleDate,
      disabled,
      startTime,
      stopTime,
      qtdVagas,
      cidade,
      turma,
      alunos: scheduleSelected.alunos,
    };

    try {
      await updateSchedulesFirestore(schedule);

      //limpar os inputs
      setCidade("");
      setCorretor("");
      setScheduleDate("");
      setStartTime("");
      setStopTime("");
      setQtdVagas(0);
      setTurma("");
    } catch (error: any) {
      console.error(error.message);
    }

    handleClose();

    //navigate("/schedule");
    //window.location.reload();
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        className="modal"
      >
        <Modal.Header className="modal-header">
          <Modal.Title className="modal-header-title">
            <b>Edite as informações do atendimento selecionado!</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
          <div className="modal-body-container">
            <form
              onSubmit={handleSubscribe}
              className="modal-form was-validated"
            >
              <div className="div-form-group modal-form-group">
                <label className="div-input-label" htmlFor="turma">
                  Corretor:
                </label>
                <select
                  className="div-input-field"
                  id="turma"
                  value={corretor}
                  onChange={(event) => setCorretor(event.target.value)}
                  required
                >
                  <option value="">ESCOLHA UMA OPÇÃO</option>
                  <option value="Elias Ancelmo">Elias Ancelmo</option>
                  <option value="João Prazeres">João Prazeres</option>
                  <option value="Jamilly Oliveira">Jamilly Oliveira</option>
                </select>
              </div>

              <div className="div-form-group modal-form-group">
                <label className="div-input-label" htmlFor="date">
                  Data do atendimento:
                </label>
                <input
                  className="div-input-field"
                  type="date"
                  id="date"
                  value={scheduleDate}
                  min={calendar}
                  onChange={(event) => setScheduleDate(event.target.value)}
                  required
                />
              </div>

              <div className="div-form-group modal-form-group">
                <label className="div-input-label" htmlFor="timeIn">
                  Início do atendimento:
                </label>
                <input
                  className="div-input-field"
                  type="time"
                  id="timeIn"
                  value={startTime}
                  onChange={(event) => setStartTime(event.target.value)}
                  required
                />
              </div>

              <div className="div-form-group modal-form-group">
                <label className="div-input-label" htmlFor="timeOut">
                  Fim do atendimento:
                </label>
                <input
                  className="div-input-field"
                  type="time"
                  id="timeOut"
                  value={stopTime}
                  onChange={(event) => setStopTime(event.target.value)}
                  required
                />
              </div>

              <div className="div-form-group modal-form-group">
                <label className="div-input-label" htmlFor="qtdVagas">
                  Quantidade de vagas:
                </label>
                <input
                  className="div-input-field"
                  type="number"
                  id="qtdVagas"
                  min={1}
                  value={qtdVagas}
                  onChange={(event) =>
                    setQtdVagas(parseInt(event.target.value))
                  }
                  required
                />
              </div>

              <div className="div-form-group modal-form-group">
                <label className="div-input-label" htmlFor="cidade">
                  Cidade do atendimento:
                </label>
                <select
                  className="div-input-field"
                  id="cidade"
                  value={cidade}
                  onChange={(event) => {
                    setCidade(event.target.value);
                  }}
                  required
                >
                  <option value="">ESCOLHA UMA OPÇÃO</option>
                  <option value="Limoeiro">LIMOEIRO</option>
                  <option value="Surubim">SURUBIM</option>
                  <option value="Vitória">VITÓRIA DE SANTO ANTÃO</option>
                  <option value="Online">ONLINE</option>
                </select>
              </div>

              <div className="div-form-group modal-form-group">
                <label className="div-input-label" htmlFor="turma">
                  Turma do atendimento:
                </label>
                <select
                  className="div-input-field"
                  id="turma"
                  value={turma}
                  onChange={(event) => {
                    setTurma(event.target.value);
                  }}
                  required
                >
                  <option value="">ESCOLHA UMA OPÇÃO</option>
                  <option value="Turma 1">TURMA 1</option>
                  <option value="Turma 2">TURMA 2</option>
                  <option value="Turma 3">TURMA 3</option>
                  <option value="Turma Online">TURMA ONLINE</option>
                </select>
              </div>

              <div
                style={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                <label htmlFor="onlineCheckbox">
                  <input
                    type="checkbox"
                    id="onlineCheckbox"
                    value="ONLINE"
                    checked={disabled}
                    onChange={(event) => {
                      setDisabled(event.target.checked);
                      setDisabledText(event.target.checked ? 'ENCERRADO' : 'ATIVO')
                    }}
                    style={{ marginRight: "10px" }}
                  />
                  {disabledText + ' [marque esta opção se deseja encerrar este atendimento]'}
                </label>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer className="flex items-center justify-center">
          <Button
            //disabled={loading}
            variant="primary"
            onClick={handleSubscribe}
            className="bg-gray-900 uppercase h-10 rounded font-bold text-[0.775rem] lg:text-xs tracking-[0.2rem] hover:bg-gray-500 transition-colors disabled:opacity-50"
          >
            ATUALIZAR
          </Button>
          <Button
            variant="secondary"
            onClick={() => props.cancel()}
            className="bg-gray-900 uppercase h-10 rounded font-bold text-[0.775rem] lg:text-xs tracking-[0.2rem] hover:bg-gray-500 transition-colors disabled:opacity-50"
          >
            CANCELAR
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
