import React, { FormEvent, useEffect, useState } from "react";
import { ScheduleData } from "../interfaces/Turmas";
import { Aluno, StudentData } from "../interfaces/Students";
import {
  getSchedulesFirestore,
  updateSchedulesBackupFirestore,
  updateSchedulesStudentsFirestore,
} from "../database/firestore";
import AlertModal from "../components/AlertModal";
import "../styles/StudentsPage.css";
import "bootstrap/dist/css/bootstrap.min.css";

const StudentAgendamentos = (props: { state: any }) => {
  const { state } = props;
  const user: StudentData = state?.userFullData;

  const [idSchedule, setIdSchedule] = useState("");
  const [scheduleType, setScheduleType] = useState("");
  const [isSchedule, setIsSchedule] = useState(false);
  const [schedules, setSchedules] = useState<ScheduleData[]>([]);

  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const loadSchedules = async () => {
    try {
      let atendimentos = await getSchedulesFirestore();
      setSchedules(atendimentos);
    } catch (error) {
      console.error(`Erro ao buscar atendimentos: ${error}`);
    }
  };

  useEffect(() => {
    loadSchedules();
  }, []);

  const scheduleSelected = schedules.find((item) => item.id === idSchedule);

  const vagas = scheduleSelected?.qtdVagas ?? 0;

  const getBrazilDate = (): string => {
    // Cria um objeto Date com a data e hora atuais em UTC
    const currentUTCDate = new Date();

    // Obtém o deslocamento de tempo do Brasil em minutos (-3 horas * 60 minutos = -180 minutos)
    const offset = -3 * 60;

    // Ajusta a data e hora para o fuso horário do Brasil
    const brazilDate = new Date(currentUTCDate.getTime() + (offset * 60 * 1000));

    // Converte a data ajustada para uma string no formato ISO
    const brazilDateISOString = brazilDate.toISOString();

    return brazilDateISOString;
  }

  /**
 * Generates a unique ID (placeholder function).
 *
 * @return {String} A unique ID.
 */
  function generateUniqueId() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const length = 9; // Define the length of the unique ID
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }

  async function handleSubscribe(event: FormEvent) {
    event.preventDefault();

    if (idSchedule !== "") {
      let qtdVagas = vagas - 1;

      let alunosArray: Array<Aluno> = scheduleSelected?.alunos ?? [];

      let aluno: Aluno = {
        cpf: user.cpf,
        nome: user.name,
        id: generateUniqueId(),//alunosArray.length + 1,
        telefone: user.celularAluno,
        corretor: scheduleSelected?.corretor ?? "",
        dataAtendimento:
          formatDate(scheduleSelected?.dataDoAgendamento ?? "") +
          " " +
          scheduleSelected?.startTime +
          " - " +
          scheduleSelected?.stopTime +
          " [" +
          scheduleSelected?.cidade +
          " - " +
          scheduleSelected?.turma +
          "]",
        scheduleType,
        createdAt: getBrazilDate(),
      };

      // console.log(alunosArray);

      if (alunosArray.find((item) => item.cpf === aluno.cpf)) {
        setError("Você já efetuou seu agendamento anteriormente!");
        setShowModal(true);
      } else {
        alunosArray.push(aluno);

        const schedule: ScheduleData = {
          id: scheduleSelected?.id || "",
          corretor: scheduleSelected?.corretor || "",
          dataDoAgendamento: scheduleSelected?.dataDoAgendamento || "",
          disabled: scheduleSelected?.disabled || false,
          startTime: scheduleSelected?.startTime || "",
          stopTime: scheduleSelected?.stopTime || "",
          qtdVagas,
          cidade: scheduleSelected?.cidade || "",
          turma: scheduleSelected?.turma || "",
          alunos: alunosArray,
        };

        // const updateData = {
        //   alunos: alunosArray,
        //   qtdVagas,
        // };

        try {
          // console.log(updateData);
          // await updateSchedulesStudentsFirestore(scheduleSelected?.id || "", updateData);
          await updateSchedulesStudentsFirestore(scheduleSelected?.id || "", aluno, qtdVagas);
          await updateSchedulesBackupFirestore(schedule);
          
          setSuccessMessage("Agendamento efetuado com sucesso!");
          setShowModal(true);
        } catch (error: any) {
          console.error(error.message);
        }
      }
    } else {
      setError(
        "Nosso sistema não encontrou este atendimento tente selecioná-lo novamente por favor!"
      );
      setShowModal(true);
    }
  }

  function getSchedules() {

    if (!schedules || schedules.length === 0) {
      return <option value="LOADING">Nenhum atendimento disponível...</option>;
    }

    const activeSchedules = schedules.filter(
      (item) => item.disabled === false && item.qtdVagas !== 0
    );

    if (!activeSchedules || activeSchedules.length === 0) {
      return <option value="LOADING">Nenhum atendimento disponível...</option>;
    }

    const sortedSchedules = [...activeSchedules].sort((a, b) => {
      let dateA = a.dataDoAgendamento
        ? new Date(a.dataDoAgendamento).getTime()
        : new Date().getTime();
      let dateB = b.dataDoAgendamento
        ? new Date(b.dataDoAgendamento).getTime()
        : new Date().getTime();
      return dateA - dateB;
    });

    const options = [
      <option key="default" value="" hidden disabled>
        SELECIONE SEU ATENDIMENTO
      </option>,
    ];
    sortedSchedules.forEach((item, index) => {
      options.push(
        <option
          key={index}
          value={item.id}
          // disabled={}
        >
          {item.corretor +
            " - " +
            formatDate(`${item.dataDoAgendamento}`) +
            " " +
            item.startTime +
            "-" +
            item.stopTime +
            " [" +
            item.cidade +
            "-" +
            item.turma +
            "]"}
        </option>
      );
    });
    return options;
  }

  function formatDate(date: string) {
    const parts = date.split("-");
    const day = parts[2];
    const month = parts[1];
    const year = parts[0];

    return `${day}/${month}/${year}`;
  }

  const handleCloseModal = () => {
    setShowModal(false);
    setError("");
    setSuccessMessage("");
    setIsSchedule(true);
    loadSchedules();
    // Optionally, clear the email and password fields after successful signup
  };

  return (
    <div className="students-page-payment">
      <div className="payments-content">
        <div
          style={{
            backgroundColor: "#fff",
            width: "100%",
            maxWidth: "800px",
            padding: "10px",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <strong className="lg:text-2xl mb-6 block text-center uppercase tracking-[0.2rem]">
            {user ? user.name : ""} agende seu atendimento!
          </strong>

          <form
            onSubmit={handleSubscribe}
            className="was-validated"
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <div style={{ color: "#000", display: "flex", fontWeight: "bold" }}>
              <span>Nome Completo: {user ? user.name : ""}</span>
            </div>

            <div style={{ color: "#000", display: "flex", fontWeight: "bold" }}>
              <span>Seu CPF: {user ? user.cpf : ""}</span>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <label htmlFor="schedule" style={{ fontWeight: "bold" }}>
                Atendimentos disponíveis:{" "}
              </label>
              <select
                style={{ width: "100%", display: "flex", padding: "0 10px" }}
                id="schedule"
                value={idSchedule}
                onChange={(event) => {
                  setIdSchedule(event.target.value);
                }}
                required
                disabled={isSchedule}
              >
                {getSchedules()}
              </select>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <div
                style={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                <label htmlFor="onlineCheckbox">
                  <input
                    type="checkbox"
                    id="onlineCheckbox"
                    value="ONLINE"
                    checked={scheduleType === "COMENTÁRIOS AO VIVO"}
                    onChange={(event) => {
                      setScheduleType(
                        event.target.checked ? "COMENTÁRIOS AO VIVO" : ""
                      );
                    }}
                    style={{ marginRight: "10px" }}
                    required={scheduleType === ""}
                  />
                  CORREÇÃO / ATENDIMENTO AO VIVO
                </label>

                <label htmlFor="correcaoCheckbox">
                  <input
                    type="checkbox"
                    id="correcaoCheckbox"
                    value="CORREÇÃO"
                    checked={scheduleType === "COMENTÁRIOS ESCRITOS"}
                    onChange={(event) => {
                      setScheduleType(
                        event.target.checked ? "COMENTÁRIOS ESCRITOS" : ""
                      );
                    }}
                    style={{ marginRight: "10px" }}
                    required={scheduleType === ""}
                  />
                  CORREÇÃO / ATENDIMENTO COM COMENTÁRIOS ESCRITOS
                </label>
              </div>
            </div>

            <button
              type="submit"
              disabled={isSchedule}
              className="btn btn-primary w-100"
            >
              {isSchedule ? 'AGENDAMENTO CONCLUÍDO' : 'AGENDAR ATENDIMENTO'}
            </button>
          </form>

          {isSchedule && (
            <div className="card mt-3">
              <div className="card-body text-black">
                <h5 className="card-title">
                  Corretor: {scheduleSelected?.corretor}
                </h5>
                <h6 className="card-subtitle mb-2 text-muted">
                  {formatDate(scheduleSelected?.dataDoAgendamento ?? "")}
                </h6>
                <p className="card-text">
                  {`Horário: ${scheduleSelected?.startTime} - ${
                    scheduleSelected?.stopTime || "Indisponível"
                  }`}
                </p>
                <p className="card-text">
                  Agendamento efetuado com sucesso e gerou o seguinte protocolo: {' '} <strong>{getBrazilDate()}</strong>
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      {showModal && successMessage && (
        <AlertModal message={successMessage} onClose={handleCloseModal} />
      )}

      {showModal && error && (
        <AlertModal message={error} onClose={handleCloseModal} />
      )}
    </div>
  );
};

export default StudentAgendamentos;
