import React, { useEffect, useState } from "react";
import AlertModal from "../components/AlertModal";
import "../styles/AdmsViewSchedules.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { ScheduleData } from "../interfaces/Turmas";
import {
  getSchedulesFirestore,
  removeSchedulesFirestore,
} from "../database/firestore";
import TabelaAtendimentos from "../components/TabelaAtendimentos";
import { FaEdit, FaTrash, FaUserAlt } from "react-icons/fa";
import ModalEdit from "../components/ModalEdit";
import { Aluno } from "../interfaces/Students";

const AdmsViewSchedules = (props: {state: any}) => {
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isTotalSchedules, setIsTotalSchedules] = useState(true);
  const [showTable, setShowTable] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [idScheduleSelected, setIdScheduleSelected] = useState("");
  const [schedules, setSchedules] = useState<ScheduleData[]>([]);
  const [linhas, setLinhas] = useState<any>([]);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  // Separar os schedules ativos e inativos
  const activeSchedules = schedules.filter(schedule => !schedule.disabled);
  const inactiveSchedules = schedules.filter(schedule => schedule.disabled);

  useEffect(() => {
    loadSchedules();
  }, []);

  const loadSchedules = async () => {
    try {
      let atendimentos = await getSchedulesFirestore();
      setSchedules(atendimentos);
    } catch (error) {
      setError(`Erro ao buscar atendimentos: ${error}`);
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setError("");
    setSuccessMessage("");
    setShowModalEdit(false);
    loadSchedules();
  };

  const handleCancelCloseModal = () => {
    setShowModal(false);
    setError("");
    setSuccessMessage("");
    setShowModalEdit(false);
  };

  function formatDate(date: string) {
    const parts = date.split("-");
    const day = parts[2];
    const month = parts[1];
    const year = parts[0];
    return `${day}/${month}/${year}`;
  }

  function scheduleList(schedules: ScheduleData[]) {
    const sortedSchedules = [...schedules].sort((a, b) => {
      let dateA = a.dataDoAgendamento
        ? new Date(a.dataDoAgendamento).getTime()
        : new Date().getTime();
      let dateB = b.dataDoAgendamento
        ? new Date(b.dataDoAgendamento).getTime()
        : new Date().getTime();
      return dateA - dateB;
    });
    return (
      <div className="container-schedules">
        <div className="row">
          {sortedSchedules.map((schedule, index) => (
            <div className="col-sm-6 mb-3" key={index}>
              {scheduleCard(schedule)}
            </div>
          ))}
        </div>
      </div>
    );
  }

  function handleClick(id: string) {
    const scheduleSelected = schedules.find((item) => item.id === id);
    let alunosArray: Array<Aluno> = scheduleSelected?.alunos ?? [];
    if (alunosArray.length > 0) {
      setLinhas(alunosArray);
      setShowTable(true);
    } else {
      setError("Nenhum aluno agendou para este atendimento");
      setShowModal(true);
    }
  }

  const handleVoltar = () => {
    setShowTable(false);
  };

  const handleEdit = (id: string) => {
    setIdScheduleSelected(id);
    setShowModalEdit(true);
  };

  const handleScheduleSelected = (id: string): ScheduleData | {} => {
    const scheduleSelected = schedules.find((item) => item.id === id);
    return scheduleSelected || {};
  };

  const handleDelete = async (id: string) => {
    try {
      await removeSchedulesFirestore(id);
      setSuccessMessage("Atendimento removido com sucesso!");
      setShowModal(true);
    } catch (error) {
      setError("Error ao remover atendimento: " + error);
      setShowModal(true);
    }
  };

  function scheduleCard(schedule: ScheduleData) {
    return (
      <div
        className={schedule.disabled ? 'card card-disabled' : 'card'}
      >
        <div className="card-body">
          <h4 className="card-subtitle mb-2 bold">
            {formatDate(schedule.dataDoAgendamento)}
          </h4>
          <h5 className="card-title mb-3">Corretor: {schedule.corretor}</h5>
          <p className="card-text">
            <span style={{ fontWeight: "bold", fontStyle: "normal" }}>
              Vagas disponíveis:
            </span>
            {` ${schedule.qtdVagas}`}
          </p>
          <p className="card-text">
            <span style={{ fontWeight: "bold", fontStyle: "normal" }}>
              Horário:
            </span>
            {` ${schedule.startTime} - ${schedule.stopTime || "Indisponível"}`}
          </p>
          <p className="card-text">
            <span style={{ fontWeight: "bold", fontStyle: "normal" }}>
              Cidade:
            </span>
            {` ${schedule.cidade}`}
          </p>
          <p className="card-text">
            <span style={{ fontWeight: "bold", fontStyle: "normal" }}>
              Turma:
            </span>
            {` ${schedule.turma}`}
          </p>
          <p className="card-text" style={{ textAlign: "center" }}>
            <span style={{ fontWeight: "bold", fontStyle: "normal" }}>
              {`${schedule.disabled ? 'ENCERRADO' : 'ATIVO'}`}
            </span>
          </p>
        </div>
        <div className="card-buttons-container">
          <button
            className="card-button"
            onClick={() => handleClick(schedule.id)}
          >
            <FaUserAlt /> <span className="ml-2">Alunos</span>
          </button>
          <button
            className="card-button card-button-edit"
            onClick={() => handleEdit(schedule.id)}
          >
            <FaEdit /> <span className="ml-2">Editar</span>
          </button>
          <button
            className="card-button card-button-delete"
            onClick={() => handleDelete(schedule.id)}
          >
            <FaTrash /> <span className="ml-2">Apagar</span>
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="adms-page-create">
      <div className="card-container-schedules">
        <div
          className="w-100"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <strong
            style={{
              display: "flex",
              textAlign: "center",
              width: "100%",
              color: "#fff",
              textTransform: "uppercase",
              justifyContent: "center",
              fontSize: "24px",
              marginBottom: "20px",
            }}
          >
            {schedules.length ? 'Atendimentos disponíveis' : 'Nenhum atendimento encontrado'}
          </strong>
          
          {/* Renderizar schedules ativos */}
          <div className="w-100 d-flex card-total-schedules">
            {isTotalSchedules && !showTable && (
              <div
                id="totalResults"
                className="w-100 d-flex total-results-schedules"
              >
                {scheduleList(activeSchedules)}
              </div>
            )}
          </div>

          {/* Botão para abrir/fechar acordeão */}
          <button
            onClick={() => setIsAccordionOpen(!isAccordionOpen)}
            className="mb-3 p-2 btn btn-primary w-100"
            style={{
              maxWidth: "200px",
              alignSelf: "center",
              textAlign: "center",
            }}
          >
            {isAccordionOpen ? 'Fechar Inativos' : 'Mostrar Inativos'}
          </button>

          {/* Acordeão para schedules inativos */}
          {isAccordionOpen && (
            <div className="w-100 d-flex card-total-schedules">
              {scheduleList(inactiveSchedules)}
            </div>
          )}

          {showTable && (
            <button
              onClick={handleVoltar}
              className="mb-3 p-2 btn btn-danger w-100"
              style={{
                maxWidth: "200px",
                alignSelf: "center",
                textAlign: "center",
              }}
            >
              FECHAR LISTA
            </button>
          )}

          {showTable && <TabelaAtendimentos rows={linhas} />}

          {showModalEdit && (
            <ModalEdit
              open={showModalEdit}
              handleScheduleSelected={handleScheduleSelected}
              idSchedule={idScheduleSelected}
              close={handleCloseModal}
              cancel={handleCancelCloseModal}
            />
          )}
        </div>
      </div>
      {showModal && successMessage && (
        <AlertModal message={successMessage} onClose={handleCloseModal} />
      )}

      {showModal && error && (
        <AlertModal message={error} onClose={handleCloseModal} />
      )}
    </div>
  );
};

export default AdmsViewSchedules;