export const cursosPernambuco = [
  "Administração",
  "Agroecologia",
  "Análise e Desenvolvimento de Sistemas",
  "Arqueologia",
  "Arquitetura e Urbanismo",
  "Biologia",
  "Biomedicina",
  "Biotecnologia",
  "Ciências Ambientais",
  "Ciências Biológicas",
  "Ciências Contábeis",
  "Ciências da Computação",
  "Ciências Econômicas",
  "Ciências Sociais",
  "Comércio Exterior",
  "Comunicação Institucional",
  "Comunicação Social",
  "Dança",
  "Design de Games",
  "Design de Interiores",
  "Design de Moda",
  "Design de Produto",
  "Design Gráfico",
  "Direito",
  "Ecologia",
  "Educação Física",
  "Empreendedorismo",
  "Enfermagem",
  "Engenharia Aeroespacial",
  "Engenharia Biomédica",
  "Engenharia Civil",
  "Engenharia Controle e Automação",
  "Engenharia de Alimentos",
  "Engenharia de Minas",
  "Engenharia de Petróleo",
  "Engenharia de Produção",
  "Engenharia de Software",
  "Engenharia de Telecomunicações",
  "Engenharia Elétrica",
  "Engenharia Geológica",
  "Engenharia Industrial",
  "Engenharia Mecânica",
  "Engenharia Mecatrônica",
  "Engenharia Naval",
  "Engenharia Química",
  "Estatística",
  "Estética",
  "Estética e Cosmética",
  "Eventos",
  "Farmácia",
  "Farmácia Industrial",
  "Física",
  "Física Médica",
  "Fisioterapia",
  "Filosofia",
  "Fonoaudiologia",
  "Fotografia",
  "Gastronomia",
  "Gestão Ambiental",
  "Gestão Comercial",
  "Gestão da Informação",
  "Gestão de Tecnologia da Informação",
  "Gestão Empresarial",
  "Gestão Financeira",
  "Gestão Hospitalar",
  "Gestão Pública",
  "História",
  "História da Arte",
  "Jogos Digitais",
  "Letras",
  "Linguística",
  "Logística",
  "Marketing",
  "Marketing Digital",
  "Matemática",
  "Matemática Aplicada",
  "Matemática Computacional",
  "Medicina",
  "Medicina Veterinária",
  "Mídia Digital",
  "Negócios Imobiliários",
  "Negócios Internacionais",
  "Nutrição",
  "Oceanografia",
  "Oceanologia",
  "Odontologia",
  "Pedagogia",
  "Pedagogia Empresarial",
  "Processamento de Dados",
  "Processos Gerenciais",
  "Produção Audiovisual",
  "Produção Cervejeira",
  "Produção Cultural",
  "Produção Multimídia",
  "Psicologia",
  "Publicidade e Propaganda",
  "Química",
  "Radiologia",
  "Radiologia Industrial",
  "Redes de Computadores",
  "Relações Internacionais",
  "Relações Públicas",
  "Reabilitação Urbana",
  "Saneamento Ambiental",
  "Segurança da Informação",
  "Segurança no Trabalho",
  "Segurança Pública",
  "Serviço Social",
  "Serviços Jurídicos",
  "Sistemas de Informação",
  "Sistemas para Internet",
  "Sociologia",
  "Sociologia Jurídica",
  "Telemática",
  "Teologia",
  "Terapia Holística",
  "Terapia Intensiva",
  "Terapia Manual",
  "Terapia Ocupacional",
  "Terapia Sexual",
  "Tradução e Interpretação",
  "Turismo",
  "Zoologia",
];
