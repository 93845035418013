import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import loading from "../images/home/background-default.gif";
import { StudentData, studentEmpty } from "../interfaces/Students";
import {
  getStudentFirestoreByCpfOrEmail,
  removeStudentFirestore,
  updateFullStudentDataFirestore,
} from "../database/firestore";
import AlertModal from "../components/AlertModal";
import "../styles/AdmsPage.css";
import "../styles/AdmUpdateStudentData.css";
import AlertModalConfirmations from "../components/AlertModalConfirmations";
import { updateIsFirstAccess } from "../services/SingUp";
import { TurmaOptionData } from "../interfaces/Turmas";

const AdmUpdateStudentData = (props: { state: any }) => {
  const { state } = props;
  const cursos = state.cursos;

  const [searchType, setSearchType] = useState<"cpf" | "email">("cpf");
  const [searchValue, setSearchValue] = useState("");
  const [studentData, setStudentData] = useState<StudentData | any>(null);
  const [showModal, setShowModal] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState<StudentData>(studentEmpty);

  const handleCloseModal = () => {
    setShowModal(false);
    setShowModalConfirm(false);
    setShowMessageModal("");
  };

  const handleSearch = async () => {
    try {
      const result = await getStudentFirestoreByCpfOrEmail(
        searchType,
        searchValue
      );
      setStudentData(result);
    } catch (error) {
      console.error("Erro ao buscar estudante:", error);
      setShowMessageModal(`Erro ao buscar estudante: ${error}`);
      setShowModal(true);

      setStudentData(null); // Limpar os dados do aluno se não for encontrado
    }
  };

  const handleClear = () => {
    setSearchType("cpf");
    setSearchValue("");
    setStudentData(null);
  };

  const dateBuilder = (dateStr: string): string => {
    const dataMatriculado = dateStr.split("-");
    return `${dataMatriculado[2]}/${dataMatriculado[1]}/${dataMatriculado[0]}`;
  };

  const handleEdit = (student: StudentData) => {
    setIsEditing(true);
    setEditedData(student);
  };

  const handleIsFirstAccessUpdate = async (studentId: string) => {
    try {
      await updateIsFirstAccess(studentId, true);
    } catch (error: any) {
      console.error(error.message);
    }
  };

  const handleRemove = async () => {
    const studentId = studentData.id;
    try {
      // Seta isFirstAccess to false
      await handleIsFirstAccessUpdate(studentId);

      // Chame a função para remover aluno no Firestore
      await removeStudentFirestore(studentId);

      handleCloseModal();

      setShowMessageModal("Aluno removido com sucesso!");
      setShowModal(true);
      setStudentData(null);
    } catch (error) {
      console.error("Erro ao remover aluno:", error);
      setShowMessageModal(`Erro ao remover aluno: ${error}`);
      setShowModal(true);
    }
  };

  const handleRemoveConfirm = () => {
    setShowMessageModal(
      `Tem certeza que deseja remover os dados de <strong>${studentData.name.toUpperCase()}</strong>? Caso ele volte para o curso será necessário efetuar todo o processo de matrícula novamente.`
    );
    setShowModalConfirm(true);
  };

  const handleUpdate = async () => {
    try {
      // Chame a função para atualizar aluno no Firestore
      // (implementação abaixo)
      await updateFullStudentDataFirestore(editedData);
      setShowMessageModal("Aluno atualizado com sucesso!");
      setShowModal(true);
      setIsEditing(false);
      setStudentData(editedData);
    } catch (error) {
      console.error("Erro ao atualizar aluno:", error);
      setShowMessageModal(`Erro ao atualizar aluno: ${error}`);
      setShowModal(true);
    }
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedData(studentEmpty);
  };

  return (
    <div className="adms-page">
      <div className="card-container">
        <select
          value={searchType}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            setSearchType(e.target.value as "cpf" | "email")
          }
          className="adm-input-field adm-input-field-selected"
        >
          <option value="cpf">CPF</option>
          <option value="email">Email</option>
        </select>

        {searchType === "cpf" ? (
          <input
            type="text"
            value={searchValue}
            onChange={(e) =>
              setSearchValue(e.target.value.replace(/\D/g, "").slice(0, 11))
            }
            placeholder="Apenas números"
            className="adm-input-field"
            onKeyDown={handleSearch}
          />
        ) : (
          <input
            type="email"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="Digite o email"
            className="adm-input-field"
          />
        )}

        <button onClick={handleSearch} className="btn btn-primary adm-button">
          Buscar
        </button>
        <button onClick={handleClear} className="btn btn-secondary adm-button">
          Limpar
        </button>
        {isEditing ? (
          <>
            <button
              onClick={handleUpdate}
              className="btn btn-primary adm-button"
            >
              Atualizar Dados
            </button>
            <button
              onClick={handleCancelEdit}
              className="btn btn-secondary adm-button"
            >
              Cancelar Edição
            </button>
          </>
        ) : (
          <>
            <button
              onClick={() => handleEdit(studentData)}
              className="btn btn-secondary adm-button"
            >
              Editar Aluno
            </button>
            <button
              onClick={() => handleRemoveConfirm()}
              className="btn btn-danger adm-button"
            >
              Remover Aluno
            </button>
          </>
        )}

        {studentData && (
          <div className="result-card">
            <h2
              style={{
                width: "100%",
                textAlign: "left",
                margin: "10px 0",
                fontWeight: "bold",
              }}
            >
              Dados do Aluno
            </h2>
            <div className="table-container">
              <table>
                <tbody>
                  {isEditing ? (
                    <>
                      <tr>
                        <td>Nome:</td>
                        <td>
                          <input
                            className="adm-input-field"
                            type="text"
                            value={editedData.name}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                name: e.target.value,
                              })
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>E-mail:</td>
                        <td>
                          <input
                            className="adm-input-field"
                            type="email"
                            value={editedData.email}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                email: e.target.value,
                              })
                            }
                            disabled
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>CPF:</td>
                        <td>
                          <input
                            className="adm-input-field"
                            type="text"
                            value={editedData.cpf}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                cpf: e.target.value,
                              })
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>RG:</td>
                        <td>
                          <input
                            className="adm-input-field"
                            type="text"
                            value={editedData.rg}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                rg: e.target.value,
                              })
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Data de Nascimento:</td>
                        <td>
                          <input
                            className="adm-input-field"
                            type="text"
                            value={editedData.birthday}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                birthday: e.target.value,
                              })
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Telefone do Aluno:</td>
                        <td>
                          <input
                            className="adm-input-field"
                            type="text"
                            value={editedData.celularAluno}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                celularAluno: e.target.value,
                              })
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Endereço:</td>
                        <td>
                          <input
                            className="adm-input-field"
                            type="text"
                            value={editedData.address}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                address: e.target.value,
                              })
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>CEP:</td>
                        <td>
                          <input
                            className="adm-input-field"
                            type="text"
                            value={editedData.cep}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                cep: e.target.value,
                              })
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Cidade:</td>
                        <td>
                          <input
                            className="adm-input-field"
                            type="text"
                            value={editedData.cidade}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                cidade: e.target.value,
                              })
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Cidade do Curso:</td>
                        <td>
                          <input
                            className="adm-input-field"
                            type="text"
                            value={editedData.cidadeDoCurso}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                cidadeDoCurso: e.target.value,
                              })
                            }
                            disabled
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Escola:</td>
                        <td>
                          <input
                            className="adm-input-field"
                            type="text"
                            value={editedData.escola}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                escola: e.target.value,
                              })
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Turma da Escola:</td>
                        <td>
                          <input
                            className="adm-input-field"
                            type="text"
                            value={editedData.turmaDaEscola}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                turmaDaEscola: e.target.value,
                              })
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Curso na Universidade:</td>
                        <td>
                          <input
                            className="adm-input-field"
                            type="text"
                            value={editedData.cursoUniversidade}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                cursoUniversidade: e.target.value,
                              })
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>ENEM:</td>
                        <td>
                          <input
                            className="adm-input-field"
                            type="text"
                            value={editedData.enem}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                enem: e.target.value,
                              })
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Nome do Responsável:</td>
                        <td>
                          <input
                            className="adm-input-field"
                            type="text"
                            value={editedData.responsavel}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                responsavel: e.target.value,
                              })
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Telefone do Responsável:</td>
                        <td>
                          <input
                            className="adm-input-field"
                            type="text"
                            value={editedData.celularResponsavel}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                celularResponsavel: e.target.value,
                              })
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>CPF do Responsável:</td>
                        <td>
                          <input
                            className="adm-input-field"
                            type="text"
                            value={editedData.cpfResponsavel}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                cpfResponsavel: e.target.value,
                              })
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>RG do Responsável:</td>
                        <td>
                          <input
                            className="adm-input-field"
                            type="text"
                            value={editedData.rgResponsavel}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                rgResponsavel: e.target.value,
                              })
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Código do Curso:</td>
                        <td>
                          {/* {studentData.cursoCode} */}
                          <input
                            className="adm-input-field"
                            type="text"
                            value={editedData.cursoCode}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                cursoCode: e.target.value,
                              })
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Curso Matriculado:</td>
                        <td>
                          {/* {studentData.cursoMatriculado} */}

                          <select
                            className="adm-input-field"
                            id="turma"
                            onChange={(e) => {
                              const selectedCurso = cursos.find(
                                (curso: TurmaOptionData) =>
                                  curso.nomeDoCurso === e.target.value
                              );
                              if (selectedCurso) {
                                setEditedData({
                                  ...editedData,
                                  cursoMatriculado: selectedCurso.nomeDoCurso,
                                  cursoCode: selectedCurso.courseCode,
                                  valorDoCurso: selectedCurso.pay.value,
                                  valorDoCursoFormatted: selectedCurso.pay.formattedValue,
                                });
                              }
                            }}
                            value={editedData.cursoMatriculado}
                          >
                            <option value="" disabled hidden>
                              Escolha a nova turma do estudante
                            </option>
                            {cursos
                              .filter((opcao: TurmaOptionData) =>
                                ["LT1NR", "LT2NR", "VT1NR", "ONLT1NR"].includes(
                                  opcao.courseCode
                                )
                              )
                              .map((opcao: TurmaOptionData) => (
                                <option
                                  key={opcao.courseCode}
                                  value={opcao.nomeDoCurso}
                                >
                                  {opcao.nomeDoCurso}
                                </option>
                              ))}
                          </select>
                        </td>
                      </tr>
                      <tr>
                        <td>Valor do Curso:</td>
                        <td>
                          {/* {studentData.valorDoCurso} */}
                          <input
                            className="adm-input-field"
                            type="number"
                            value={editedData.valorDoCurso}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                valorDoCurso: parseInt(e.target.value),
                              })
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Valor do Curso Formatado:</td>
                        <td>
                          {/* {studentData.valorDoCursoFormatted} */}
                          <input
                            className="adm-input-field"
                            type="text"
                            value={editedData.valorDoCursoFormatted}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                valorDoCursoFormatted: e.target.value,
                              })
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Data da Matrícula:</td>
                        <td>{dateBuilder(studentData.created)}</td>
                      </tr>
                      <tr>
                        <td>Pacote Comprado:</td>
                        <td>{studentData.pacoteComprado}</td>
                      </tr>
                      <tr>
                        <td>Código do Pacote:</td>
                        <td>{studentData.pacoteCode}</td>
                      </tr>
                      <tr>
                        <td>Quantidade de Pacotes:</td>
                        <td>{studentData.qtdPacotes}</td>
                      </tr>
                      <tr>
                        <td>Pagamento da Matrícula:</td>
                        <td>
                          {studentData.pagamentoMatricula ? "Sim" : "Não"}
                        </td>
                      </tr>
                      <tr>
                        <td>Pagamento dos Pacotes:</td>
                        <td>{studentData.pagamentoPacotes ? "Sim" : "Não"}</td>
                      </tr>
                      <tr>
                        <td>Aluno Bloqueado:</td>
                        <td>
                          <select
                            value={editedData.bloqueado ? "Sim" : "Não"}
                            onChange={(e) =>
                              setEditedData({
                                ...editedData,
                                bloqueado:
                                  e.target.value === "Sim" ? true : false,
                              })
                            }
                            className="adm-input-field adm-input-field-selected"
                          >
                            <option value="Sim">SIM</option>
                            <option value="Não">NÃO</option>
                          </select>
                        </td>
                      </tr>
                    </>
                  ) : (
                    <>
                      <tr>
                        <td>Nome:</td>
                        <td>{studentData.name}</td>
                      </tr>
                      <tr>
                        <td>E-mail:</td>
                        <td>{studentData.email}</td>
                      </tr>
                      <tr>
                        <td>CPF:</td>
                        <td>{studentData.cpf}</td>
                      </tr>
                      <tr>
                        <td>RG:</td>
                        <td>{studentData.rg}</td>
                      </tr>
                      <tr>
                        <td>Data de Nascimento:</td>
                        <td>{dateBuilder(studentData.birthday)}</td>
                      </tr>
                      <tr>
                        <td>Telefone do Aluno:</td>
                        <td>{studentData.celularAluno}</td>
                      </tr>
                      <tr>
                        <td>Endereço:</td>
                        <td>{studentData.address}</td>
                      </tr>
                      <tr>
                        <td>CEP:</td>
                        <td>{studentData.cep}</td>
                      </tr>
                      <tr>
                        <td>Cidade:</td>
                        <td>{studentData.cidade}</td>
                      </tr>
                      <tr>
                        <td>Cidade do Curso:</td>
                        <td>{studentData.cidadeDoCurso}</td>
                      </tr>
                      <tr>
                        <td>Escola:</td>
                        <td>{studentData.escola}</td>
                      </tr>
                      <tr>
                        <td>Turma da Escola:</td>
                        <td>{studentData.turmaDaEscola}</td>
                      </tr>
                      <tr>
                        <td>Curso na Universidade:</td>
                        <td>{studentData.cursoUniversidade}</td>
                      </tr>
                      <tr>
                        <td>ENEM:</td>
                        <td>{studentData.enem}</td>
                      </tr>
                      <tr>
                        <td>Nome do Responsável:</td>
                        <td>{studentData.responsavel}</td>
                      </tr>
                      <tr>
                        <td>Telefone do Responsável:</td>
                        <td>{studentData.celularResponsavel}</td>
                      </tr>
                      <tr>
                        <td>CPF do Responsável:</td>
                        <td>{studentData.cpfResponsavel}</td>
                      </tr>
                      <tr>
                        <td>RG do Responsável:</td>
                        <td>{studentData.rgResponsavel}</td>
                      </tr>
                      <tr>
                        <td>Código do Curso:</td>
                        <td>{studentData.cursoCode}</td>
                      </tr>
                      <tr>
                        <td>Curso Matriculado:</td>
                        <td>{studentData.cursoMatriculado}</td>
                      </tr>
                      <tr>
                        <td>Valor do Curso:</td>
                        <td>{studentData.valorDoCurso}</td>
                      </tr>
                      <tr>
                        <td>Valor do Curso Formatado:</td>
                        <td>{studentData.valorDoCursoFormatted}</td>
                      </tr>
                      <tr>
                        <td>Data da Matrícula:</td>
                        <td>{dateBuilder(studentData.created)}</td>
                      </tr>
                      <tr>
                        <td>Pacote Comprado:</td>
                        <td>{studentData.pacoteComprado}</td>
                      </tr>
                      <tr>
                        <td>Código do Pacote:</td>
                        <td>{studentData.pacoteCode}</td>
                      </tr>
                      <tr>
                        <td>Quantidade de Pacotes:</td>
                        <td>{studentData.qtdPacotes}</td>
                      </tr>
                      <tr>
                        <td>Pagamento da Matrícula:</td>
                        <td>
                          {studentData.pagamentoMatricula ? "Sim" : "Não"}
                        </td>
                      </tr>
                      <tr>
                        <td>Pagamento dos Pacotes:</td>
                        <td>{studentData.pagamentoPacotes ? "Sim" : "Não"}</td>
                      </tr>
                      <tr>
                        <td>Aluno Bloqueado</td>
                        <td>{studentData.bloqueado ? "Sim" : "Não"}</td>
                      </tr>
                    </>
                  )}
                  <tr>
                    <td colSpan={2}>
                      {isEditing ? (
                        <>
                          <button
                            onClick={handleUpdate}
                            className="btn btn-primary adm-button"
                          >
                            Atualizar Dados
                          </button>
                          <button
                            onClick={handleCancelEdit}
                            className="btn btn-secondary adm-button"
                          >
                            Cancelar Edição
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            onClick={() => handleEdit(studentData)}
                            className="btn btn-secondary adm-button"
                          >
                            Editar Aluno
                          </button>
                          <button
                            onClick={() => handleRemoveConfirm()}
                            className="btn btn-danger adm-button"
                          >
                            Remover Aluno
                          </button>
                        </>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
      {showModal && showMessageModal && (
        <AlertModal message={showMessageModal} onClose={handleCloseModal} />
      )}

      {showModalConfirm && showMessageModal && (
        <AlertModalConfirmations
          message={showMessageModal}
          onClose={handleCloseModal}
          onConfirm={handleRemove}
        />
      )}
    </div>
  );
};

export default AdmUpdateStudentData;
