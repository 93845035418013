import React from "react";
import "../styles/StudentSignupPage.css";
import StudentForm from "../components/StudentForm";
import "../styles/StudentsPage.css";

const StudentSignup = (props: {state: any}) => {
  return (
    <div className="students-page" >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          overflowX: "hidden",
        }}
      >
        <StudentForm state={props.state}/>
      </div>
    </div>
  );
};

export default StudentSignup;
