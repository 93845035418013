import Alert from 'react-bootstrap/Alert';
import { AlertProps } from '../interfaces/PropsModels';

//tyoes of the alerts
// 'primary',
//         'secondary',
//         'success',
//         'danger',
//         'warning',
//         'info',
//         'light',
//         'dark',

function Alerts(props: AlertProps) {
  return (
    <>
      {[props.type].map((variant) => (
        <Alert key={variant} variant={variant} show={props.show} className='text-sm text-center p-0'>
          {/* This is a {variant} alert with{' '}
          <Alert.Link href="#">an example link</Alert.Link>. Give it a click if
          you like. */}
          {props.content}
        </Alert>
      ))}
    </>
  );
}

export default Alerts;