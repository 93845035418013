import React, { useState } from "react";
import AlertModal from "../components/AlertModal";
import "../styles/AdmsCreateNewUsers.css";
import "../styles/AdmsCreateCourses.css";
import { TurmaOptionData } from "../interfaces/Turmas";
import { v4 as uuidv4 } from "uuid"; // Importa a função v4 da biblioteca uuid
import { addCoursesFirestore } from "../database/firestore";

// Defina o tipo das opções válidas
type TipoDoCurso =
  | ""
  | "NR"
  | "CP"
  | "RHA"
  | "HA"
  | "RM"
  | "MA"
  | "RFQB"
  | "RF"
  | "RQ"
  | "RB"
  | "CPT";

const AdmsCreateCourses = (props: {state: any}) => {
  // const { state } = props;
  // const role = state?.role;
  // const imgUrl = state?.imgUrl ? state?.imgUrl : loading;

  const [turma, setTurma] = useState(0);
  const [tipoDoCurso, setTipoDoCurso] = useState<TipoDoCurso>("");
  const [horaTermino, setHoraTermino] = useState("");
  const [horaInicio, setHoraInicio] = useState("");
  const [diaDaSemana, setDiaDaSemana] = useState("");
  const [horarioDoCurso, setHorarioDoCurso] = useState("");
  const [codigoDoCurso, setCodigoDoCurso] = useState("");
  const [nomeDoCurso, setNomeDoCurso] = useState("");
  const [idCreditCard, setIdCreditaCard] = useState("");
  const [cidade, setCidade] = useState("");
  const [modalidade, setModalidade] = useState("");
  const [valorDoCurso, setValorDoCurso] = useState(0.0);
  const [valorDoCursoFormatted, setValorDoCursoFormatted] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleCloseModal = () => {
    setShowModal(false);
    setError("");
    setSuccessMessage("");
    // Optionally, clear the email and password fields after successful signup
    setNomeDoCurso("");
    setValorDoCurso(0.0);
    setValorDoCursoFormatted("");
    setCodigoDoCurso("");
    setHoraInicio("");
    setHoraTermino("");
    setDiaDaSemana("");
    setIdCreditaCard("");
  };

  const handleCreateCourse = async () => {
    const course: TurmaOptionData = {
      id: uuidv4(),
      courseCode: codigoDoCurso,
      nomeDoCurso: nomeDoCurso,
      disabled: false,
      pay: {
        formattedValue: valorDoCursoFormatted,
        value: valorDoCurso,
        creditCardId: idCreditCard,
      },
    };
    try {
      await addCoursesFirestore(course);
      // SignUp successful, update the state with success message
      setSuccessMessage("Cadastro do curso realizado com sucesso!");
      setShowModal(true);
    } catch (error: any) {
      setError(error.message);
      setShowModal(true);
    }
  };

  const horas = Array.from({ length: 24 * 4 }, (_, index) => {
    const hora = Math.floor(index / 4)
      .toString()
      .padStart(2, "0");
    const minuto = (index % 4) * 15;
    return `${hora}h:${minuto === 0 ? "00" : minuto}`;
  });

  const types = {
    "": "empty",
    NR: "Núcleo de Redação",
    CP: "Carreiras Policiais",
    RHA: "Combo Redação, Humanas e Atualizades",
    HA: "Combo Humanas e Atualidades",
    RM: "Combo Redação e Matemática",
    MA: "Combo Matemática",
    RFQB: "Combo Redação, Física, Química, Biologia",
    RF: "Combo Redação e Física",
    RQ: "Combo Redação e Química",
    RB: "Combo Redação e Biologia",
    CPT: "Curso Completo com todas as disciplinas",
  };

  const handleChangeTipoDoCurso = (e: React.ChangeEvent<HTMLSelectElement>) => {
    // Certifique-se de que o valor é uma das opções válidas
    const valorSelecionado: string = e.target.value;
    if (
      [
        "NR",
        "CP",
        "RHA",
        "HA",
        "RM",
        "MA",
        "RFQB",
        "RF",
        "RQ",
        "RB",
        "CPT",
      ].includes(valorSelecionado)
    ) {
      setTipoDoCurso(valorSelecionado as TipoDoCurso);
    } else {
      // Trate o caso em que o valor não é válido (opcional)
    }
  };

  return (
    <div className="adms-page-create">
      <div className="card-container-users">
        <form className="form-signup new-courses was-validated">
          <div className="input-group mb-3">
            <select
              value={cidade}
              onChange={(e) => setCidade(e.target.value)}
              className="form-control"
              required
            >
              <option value="" hidden disabled>
                Escolha a cidade do curso
              </option>
              <option value="LT">Limoeiro</option>
              <option value="ST">Surubim</option>
              <option value="VT">Vitória de Santo Antão</option>
            </select>
          </div>
          <div className="input-group mb-3">
            <select
              value={tipoDoCurso}
              onChange={(e) => handleChangeTipoDoCurso(e)}
              className="form-control"
              required
            >
              <option value="" hidden disabled>
                Escolha o tipo que representa seu curso
              </option>
              <option value="NR">Núcleo de Redação</option>
              <option value="CP">Carreiras Policiais</option>
              <option value="RHA">Combo Redação, Humanas e Atualizades</option>
              <option value="HA">Combo Humanas e Atualidades</option>
              <option value="RM">Combo Redação e Matemática</option>
              <option value="MA">Combo Matemática</option>
              <option value="RFQB">
                Combo Redação, Física, Química, Biologia
              </option>
              <option value="RF">Combo Redação e Física</option>
              <option value="RQ">Combo Redação e Química</option>
              <option value="RB">Combo Redação e Biologia</option>
              <option value="CPT">
                Curso Completo com todas as disciplinas
              </option>
            </select>
          </div>
          <div className="input-group mb-3">
            <label style={{ width: "100%", fontWeight: "bold" }}>
              Número da Turma:{" "}
              <p style={{ color: "#969595a0", fontWeight: "normal" }}>
                [Ex.: Turma 1...]
              </p>
              <input
                type="number"
                className="form-control"
                value={turma}
                onChange={(e) => setTurma(parseInt(e.target.value))}
                required
                title="Insira número da turma"
                min={1}
                max={5}
              />
            </label>
          </div>
          <div className="input-group mb-3">
            <label style={{ width: "100%", fontWeight: "bold" }}>
              Horário do curso:
              <p style={{ color: "#969595a0", fontWeight: "normal" }}>
                Ex.: Quarta-feira, das 18h30 às 20h30
              </p>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label>Dia da semana:</label>
                <select
                  className="form-control"
                  value={diaDaSemana}
                  onChange={(e) => {
                    setDiaDaSemana(e.target.value);
                    const cursoTime = `${e.target.value}, das ${horaInicio} às ${horaTermino}`;
                    setHorarioDoCurso(cursoTime);
                  }}
                  required
                >
                  <option value="" hidden disabled>
                    Escolha o dia da semana do curso
                  </option>
                  <option value="Segunda-feira">Segunda-feira</option>
                  <option value="Terça-feira">Terça-feira</option>
                  <option value="Quarta-feira">Quarta-feira</option>
                  <option value="Quinta-feira">Quinta-feira</option>
                  <option value="Sexta-feira">Sexta-feira</option>
                  <option value="Sábado">Sábado</option>
                  <option value="Domingo">Domingo</option>
                </select>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label>Hora de início:</label>
                <select
                  className="form-control"
                  value={horaInicio}
                  onChange={(e) => {
                    setHoraInicio(e.target.value);
                    const cursoTime = `${diaDaSemana}, das ${e.target.value} às ${horaTermino}`;
                    setHorarioDoCurso(cursoTime);
                  }}
                  required
                >
                  <option value="" hidden disabled>
                    Escolha a hora de início do curso
                  </option>
                  {horas.map((hora) => (
                    <option key={hora} value={hora}>
                      {hora}
                    </option>
                  ))}
                </select>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label>Hora de término:</label>
                <select
                  className="form-control"
                  value={horaTermino}
                  onChange={(e) => {
                    setHoraTermino(e.target.value);
                    const cursoTime = `${diaDaSemana}, das ${horaInicio} às ${e.target.value}`;
                    setHorarioDoCurso(cursoTime);
                  }}
                  required
                >
                  <option value="" hidden disabled>
                    Escolha a hora de término do curso
                  </option>
                  {horas.map((hora) => (
                    <option key={hora} value={hora}>
                      {hora}
                    </option>
                  ))}
                </select>
              </div>
            </label>
          </div>

          <div className="input-group mb-3">
            <select
              value={modalidade}
              onChange={(e) => {
                setModalidade(e.target.value);
                let city = "";
                if (cidade === "LT") {
                  city = "Limoeiro";
                } else if (cidade === "ST") {
                  city = "Surubim";
                } else if (cidade === "VT") {
                  city = "Vitória de Santo Antão";
                }
                const cursoName = `${types[tipoDoCurso]} - ${
                  e.target.value
                } - Turma ${turma} - ${city.toUpperCase()} - ${horarioDoCurso}`;
                const code =
                  e.target.value === "Online"
                    ? `ON${cidade}${turma}${tipoDoCurso}`
                    : `${cidade}${turma}${tipoDoCurso}`;
                setCodigoDoCurso(code);
                setNomeDoCurso(cursoName);
              }}
              className="form-control"
              required
            >
              <option value="" hidden disabled>
                Escolha a modalidade do curso
              </option>
              <option value="Online">Online</option>
              <option value="Presencial">Presencial</option>
            </select>
          </div>
          <div className="input-group mb-3">
            <p>
              Código do Curso:{" "}
              <span style={{ color: "blue", fontWeight: "bold" }}>
                {codigoDoCurso}
              </span>
            </p>
          </div>
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="R$ 0,00 - Valor do Curso"
              value={valorDoCursoFormatted}
              onChange={(e) => {
                const numericValue = e.target.value.replace(/[^\d.,]/g, ""); // Remove caracteres não numéricos, exceto ponto e vírgula

                var valor = numericValue;

                valor = valor + "";
                valor = parseInt(valor.replace(/[\D]+/g, "")).toString();
                valor = valor + "";
                valor = valor.replace(/([0-9]{2})$/g, ",$1");

                if (valor.length > 6) {
                  valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
                }

                e.target.value = `R$ ${valor}`;
                if (valor === "NaN") e.target.value = "";

                setValorDoCursoFormatted(e.target.value);

                let value = parseFloat(valor.replace(",", "."));

                setValorDoCurso(value);
              }}
              required
              autoComplete="off"
            />
          </div>
          <div className="input-group mb-3">
            <label style={{ width: "100%", fontWeight: "bold" }}>
              Pagamento com cartão:
              <input
                type="text"
                className="form-control"
                placeholder="Cole o link gerado por sua máquina de cartão"
                value={idCreditCard}
                onChange={(e) => setIdCreditaCard(e.target.value)}
                required
                title="Insira o link da sua máquina de cartão"
              />
            </label>
          </div>

          <div className="buttons-container">
            <button
              className="btn btn-primary adm-button"
              type="button"
              onClick={handleCreateCourse}
            >
              CONCLUIR CADASTRO DO CURSO
            </button>
          </div>
        </form>

        {showModal && successMessage && (
          <AlertModal message={successMessage} onClose={handleCloseModal} />
        )}

        {showModal && error && (
          <AlertModal message={error} onClose={handleCloseModal} />
        )}
      </div>
    </div>
  );
};

export default AdmsCreateCourses;
