import "../styles/AdmsCreateNewUsers.css";
import ADMImagesStorage from "../components/ADMImagesStorage";

const AdmsPersonalizates = (props: {state: any}) => {
  return (
    <div className="adms-page-create">
      <div className="card-container-users">       
        <ADMImagesStorage />
      </div>
    </div>
  );
};

export default AdmsPersonalizates;
