import React, { useEffect, useState } from "react";
// import { v4 as uuidv4 } from "uuid"; // Importa a função v4 da biblioteca uuid
import {
  addStudentFirestore,
  getCoursesFirestore,
  getStudentsFirestore,
} from "../database/firestore";
import { useLocation, useNavigate } from "react-router-dom";
import { TurmaOptionData } from "../interfaces/Turmas";
import {
  getCourse,
  getCourseFormattedValue,
  getCourseValue,
} from "../database/OptionsData";
import { StudentData, studentEmpty } from "../interfaces/Students";

// Styles
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/StudentForm.css";
import { updateIsFirstAccess } from "../services/SingUp";
import AlertModal from "./AlertModal";
import { cursosPernambuco } from "../database/CursosDePernambuco";
import StudentFirstPayments from "../pages/StudentFirstPayments";

const StudentForm = (props: {state: any}) => {
  const navigate = useNavigate();
  const { state } = props;
  const user = state?.user
    ? state.user
    : { email: state?.email, uid: state?.uid, role: state?.role };

  const [name, setName] = useState("");
  const [birthday, setBirthday] = useState("");
  const [email, setEmail] = useState(user.email);
  const [turma, setTurma] = useState("");
  const [course, setCourse] = useState("");
  const [enem, setEnem] = useState("");
  const [responsavel, setResponsavel] = useState("");
  const [rg, setRG] = useState("");
  const [rgResp, setRGResp] = useState("");
  const [address, setAddress] = useState("");
  const [cidadeDoCurso, setCidadeDoCurso] = useState("");
  const [cidade, setCidade] = useState(cidadeDoCurso);
  const [celular, setCelular] = useState("");
  const [phoneResp, setPhoneResp] = useState("");
  const [cep, setCep] = useState("");
  const [school, setSchool] = useState("");
  const [cpf, setCPF] = useState("");
  const [cpfResp, setCPFResp] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenCity, setIsOpenCity] = useState(false);
  const [isOpenCourse, setIsOpenCourse] = useState(false);
  const [isOpenEnem, setIsOpenEnem] = useState(false);
  const [isOpenUniversidade, setIsOpenUniversidade] = useState(false);
  const [universidade, setUniversidade] = useState("");
  const [matriculasOpen, setMatriculasOpen] = useState(true);
  const [paymentOpen, setPaymentOpen] = useState(false);
  const [student, setStudent] = useState<StudentData>(studentEmpty);

  const [showModal, setShowModal] = useState(false);
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [textModalMessage, setTextModalMessage] = useState("");
  const [contactWithId, setContactWithId] = useState<StudentData>(studentEmpty);
  const [opcoesTurmaData, setOpcoesTurmaData] = useState<TurmaOptionData[]>([]); //obterOpcoesTurma(cidadeDoCurso);
  
  const handleIsFirstAccessUpdate = async () => {
    try {
      await updateIsFirstAccess(user.uid, false);
    } catch (error: any) {
      console.error(error.message);
    }
  };

  const todayDate = new Date().toISOString().split("T")[0];

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      // Verifica se o CPF já existe
      const cpfExists = await checkIfCPFExists(student.cpf);

      if (cpfExists) {
        //alert("CPF já existe. Não é possível adicionar o contato.");
        setTextModalMessage(
          "CPF já existe. Não é possível adicionar o mesmo CPF para contatos diferentes. Caso perceba que esta mensagem é um erro do nosso sistema entre em contato com a secretaria do curso!"
        );
        setShowModal(true);

        return;
      }

      const newContactWithId = {
        ...student,
        id: user.uid, //uuidv4(),
        cursoMatriculado: getCourse(student.cursoCode, student.cidadeDoCurso, opcoesTurmaData),
        valorDoCurso: getCourseValue(
          student.cursoCode,
          student.cidadeDoCurso,
          1,
          opcoesTurmaData
        ),
        valorDoCursoFormatted: getCourseFormattedValue(
          student.cursoCode,
          opcoesTurmaData
        ),
        created: new Date().toISOString().split("T")[0],
        email: user.email,
        bloqueado: false,
      }; // Gera um ID único usando a função v4

      setContactWithId(newContactWithId);

      // Adiciona o novo contato ao Firestore
      await addStudentFirestore(newContactWithId);

      // Seta isFirstAccess to false
      handleIsFirstAccessUpdate();

      // Limpar os campos após a submissão
      //setStudent(studentEmpty);
      setName("");
      setBirthday("");
      setEmail("");
      setTurma("");
      setCourse("");
      setEnem("");
      setResponsavel("");
      setRG("");
      setRGResp("");
      setAddress("");
      setCelular("");
      setPhoneResp("");
      setCep("");
      setSchool("");
      setCPF("");
      setCPFResp("");

      setTextModalMessage(
        "Matrícula efetuada com successo! Você será redirecinado para página de pagamento."
      );
      setShowModalSuccess(true);

      // TODO: navigate to payment page
      //  navigate(`/app/students/signup/payment`, {
      //   state: { student: newContactWithId, imgUrl },
      // });
    } catch (error) {
      console.error("Erro ao adicionar estudante:", error);
    }
  };

  const checkIfCPFExists = async (cpf: string): Promise<boolean> => {
    try {
      // Obter a lista de contatos do banco de dados
      const studentsFirestore = await getStudentsFirestore();

      // Remove pontos do CPF
      const sanitizedCpf = cpf.replace(/[.-]/g, "");

      // Verificar se o CPF já existe na lista
      return studentsFirestore.some((contact) => {
        return contact.cpf === sanitizedCpf
      });
    } catch (error) {
      console.error("Erro ao verificar se o CPF existe:", error);
      return false;
    }
  };

  function cpfMask(value: string) {
    return value
      .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{3})(\d)/, "$1.$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{2})\d+?$/, "$1"); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
  }

  function phoneMask(valor: string) {
    return valor
      .replace(/\D/g, "")
      .replace(/^(\d{2})(\d)/g, "($1) $2")
      .replace(/(\d)(\d{4})$/, "$1-$2");
  }

  function _cpf(cpf: string) {
    cpf = cpf.replace(/[^\d]+/g, "");

    if (cpf === "") return false;

    if (
      cpf.length !== 11 ||
      cpf === "00000000000" ||
      cpf === "11111111111" ||
      cpf === "22222222222" ||
      cpf === "33333333333" ||
      cpf === "44444444444" ||
      cpf === "55555555555" ||
      cpf === "66666666666" ||
      cpf === "77777777777" ||
      cpf === "88888888888" ||
      cpf === "99999999999"
    )
      return false;
    let add = 0;

    for (let i = 0; i < 9; i++) {
      add += parseInt(cpf.charAt(i)) * (10 - i);
    }

    let rev = 11 - (add % 11);

    if (rev === 10 || rev === 11) {
      rev = 0;
    }

    if (rev !== parseInt(cpf.charAt(9))) {
      return false;
    }

    add = 0;

    for (let i = 0; i < 10; i++) {
      add += parseInt(cpf.charAt(i)) * (11 - i);
    }

    rev = 11 - (add % 11);

    if (rev === 10 || rev === 11) {
      rev = 0;
    }

    if (rev !== parseInt(cpf.charAt(10))) {
      return false;
    }

    return true;
  }

  function validarCPF(el: any) {
    if (!_cpf(el.value)) {
      //alert("CPF inválido!" + el.value);
      setTextModalMessage(`CPF inválido! ${el.value}`);
      setShowModal(true);

      // apaga o valor
      el.value = "";
      //setCPF("");
    }
  }

  const handleFirstSignup = () => {
    setMatriculasOpen(false);
  };

  let calendar = new Date().toISOString().split("T")[0];

  // Lógica para verificar se a idade é menor que 18 anos
  const isUnder18 = () => {
    const currentYear = new Date().getFullYear();
    const birthYear = parseInt(birthday.substring(0, 4), 10);
    const age = currentYear - birthYear;

    return age < 18;
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSubscribeSuccefull = () => {
    setShowModal(false);
    setShowModalSuccess(false);
    setPaymentOpen(true);

    // TODO: navigate to payment page
    // navigate(`/app/students/signup/payment`, {
    //   state: { student: contactWithId, imgUrl, isLogged: false, typePayment: "", cursoData: opcoesTurmaData },
    // });

  };

  useEffect(() => {
    const cursosData = async () => {
      try {
        let cursos = await getCoursesFirestore();
        //console.log(cursos)
        setOpcoesTurmaData(cursos);
        //localStorage.setItem('courses', JSON.stringify(cursos));
      } catch (error) {
        console.error("Erro ao carregar os cursos cadastrados pelo professor:", error);
      }
    };

    cursosData();

  }, []);

  const handleDisabledCurso = (cursoCode: string): boolean => {
    if (cidade === "Limoeiro" && cursoCode.includes('LT')) {
      return false;
    } else if (cidade === "Vitoria" && cursoCode.includes('VT')) {
      return false;
    } else if (cidade === "Surubim" && cursoCode.includes('ST')) {
      return false;
    } else if (cidade === "Online" && cursoCode.includes('ON')) {
      return false; 
    } else {
      return true;
    }
  }

  return (
    <>
      {!paymentOpen && matriculasOpen && (
        <div className="form-container_student">
          <form
            onSubmit={handleFirstSignup}
            className="form-box-student was-validated"
          >
            <div className="input-container">
              {/* <label className="input-label" htmlFor="name">Nome completo</label> */}
              <input
                className="input-field"
                type="text"
                id="name"
                value={name}
                placeholder="Nome Completo"
                onChange={(event) => {
                  if (!isUnder18()) {
                    setName(event.target.value);
                    setStudent({
                      ...student,
                      name: event.target.value,
                      responsavel: event.target.value,
                    });
                  } else {
                    setName(event.target.value);
                    setStudent({ ...student, name: event.target.value });
                  }
                }}
                onSelect={(event) => {
                  if (!isUnder18()) {
                    setName(event.currentTarget.value);
                    setStudent({
                      ...student,
                      name: event.currentTarget.value,
                      responsavel: event.currentTarget.value,
                    });
                  } else {
                    setName(event.currentTarget.value);
                    setStudent({ ...student, name: event.currentTarget.value });
                  }
                }}
                autoComplete="off"
                required
              />
            </div>

            <div className="input-container_small">
              <label className="input-label" htmlFor="date">Data de nascimento*</label>
              <input
                className="input-field"
                type="date"
                id="date"
                name="birthday"
                placeholder="Data de nascimento"
                value={birthday}
                max={calendar}
                onChange={(event) => {
                  setBirthday(event.target.value);
                  setStudent({ ...student, birthday: event.target.value });
                }}
                onSelect={(event) => {
                  setBirthday(event.currentTarget.value);
                  setStudent({
                    ...student,
                    birthday: event.currentTarget.value,
                  });
                }}
                required
              />
            </div>

            <div className="input-container_half">
              {/* <label className="input-label" htmlFor="city">Em qual cidade deseja estudar?</label> */}
              <select
                className="input-field"
                id="city"
                value={cidadeDoCurso}
                onChange={(event) => {
                  setCidadeDoCurso(event.target.value);
                  setCidade(event.target.value);
                  if (event.target.value === "Limoeiro") {
                    setCourse("");
                  } else if (event.target.value === "Vitoria") {
                    setCourse("");
                  } else if (event.target.value === "Surubim") {
                    setCourse("");
                  } else {
                    setCourse("");
                  }
                  setStudent({
                    ...student,
                    cidade: event.target.value,
                    cidadeDoCurso: event.target.value,
                  });
                }}
                onSelect={(event) => {
                  setCidadeDoCurso(event.currentTarget.value);
                  setCidade(event.currentTarget.value);
                  setStudent({
                    ...student,
                    cidade: event.currentTarget.value,
                    cidadeDoCurso: event.currentTarget.value,
                  });
                }}
                style={{
                  backgroundColor: isOpenCity ? "#3d3d3d" : "transparent",
                }}
                onFocus={() => setIsOpenCity(true)}
                onBlur={() => setIsOpenCity(false)}
                autoComplete="off"
                required
              >
                <option value="" disabled hidden>
                  Em qual cidade deseja estudar?
                </option>
                <option value="Limoeiro">LIMOEIRO</option>
                <option value="Vitoria">VITÓRIA</option>
                <option value="Surubim">SURUBIM</option>
                <option value="Online">ONLINE</option>
              </select>
            </div>

            <div className="input-container">
              {/* <label className="input-label" htmlFor="curso">Escolha seu curso?</label> */}
              <select
                className="input-field input-select"
                id="curso"
                onChange={(event) => {
                  if (event.target.value !== "") {
                    setCourse(event.target.value);
                    setStudent({ ...student, cursoCode: event.target.value });
                  }
                }}
                onSelect={(event) => {
                  setCourse(event.currentTarget.value);
                  setStudent({
                    ...student,
                    cursoCode: event.currentTarget.value,
                  });
                }}
                value={course}
                style={{
                  backgroundColor: isOpenCourse ? "#3d3d3d" : "transparent",
                }}
                onFocus={() => setIsOpenCourse(true)}
                onBlur={() => setIsOpenCourse(false)}
                autoComplete="off"
                required
              >
                <option value="" disabled hidden>
                  Escolha seu curso?
                </option>
                {opcoesTurmaData.map((opcao: TurmaOptionData) => (
                  <option
                    key={opcao.courseCode}
                    value={opcao.courseCode}
                    disabled={handleDisabledCurso(opcao.courseCode) || opcao.disabled}
                    hidden={opcao.disabled}
                  >
                    {opcao.nomeDoCurso}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-buttons-container">
              <button
                type="submit"
                //onClick={handleFirstSignup}
                className="btn btn-success submit-button"
              >
                CONTINUAR PARA GARANTIR MINHA VAGA
              </button>

              <button
                onClick={() => navigate(-1)}
                className="btn btn-secondary submit-button-cancelar"
              >
                CANCELAR
              </button>
            </div>
          </form>
        </div>
      )}

      {!paymentOpen && !matriculasOpen && (
        <div className="form-container_student">
          <form
            onSubmit={handleSubmit}
            className="form-box-student was-validated"
          >
            <div className="input-container">
              {/* <label className="input-label">E-mail</label> */}
              <input
                className="input-field"
                type="email"
                id="email"
                placeholder="Email*"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setStudent({ ...student, email: e.target.value });
                }}
                onSelect={(event) => {
                  setEmail(event.currentTarget.value);
                  setStudent({ ...student, email: event.currentTarget.value });
                }}
                autoComplete="off"
                disabled
                required
              />
            </div>

            <div className="input-container">
              {/* <label className="input-label">Nome completo</label> */}
              <input
                className="input-field"
                type="text"
                id="name"
                placeholder="Nome completo*"
                value={name}
                onChange={(event) => {
                  if (!isUnder18()) {
                    setName(event.target.value);
                    setResponsavel(event.target.value);
                    setStudent({
                      ...student,
                      name: event.target.value,
                      responsavel: event.target.value,
                    });
                  } else {
                    setName(event.target.value);
                    setStudent({ ...student, name: event.target.value });
                  }
                }}
                onSelect={(event) => {
                  if (!isUnder18()) {
                    setName(event.currentTarget.value);
                    setResponsavel(event.currentTarget.value);
                    setStudent({
                      ...student,
                      name: event.currentTarget.value,
                      responsavel: event.currentTarget.value,
                    });
                  } else {
                    setName(event.currentTarget.value);
                    setStudent({ ...student, name: event.currentTarget.value });
                  }
                }}
                disabled
                autoComplete="off"
                required
              />
            </div>

            <div className="input-container_small">
              {/* <label>CPF</label> */}
              <input
                className="input-field"
                type="text"
                id="cpf"
                placeholder="CPF*"
                value={cpf}
                minLength={14}
                maxLength={14}
                onChange={(event) => {
                  if (!isUnder18()) {
                    setCPF(cpfMask(event.target.value));
                    setStudent({
                      ...student,
                      cpf: event.target.value,
                      cpfResponsavel: event.target.value,
                    });
                  } else {
                    setCPF(cpfMask(event.target.value));
                    setStudent({ ...student, cpf: event.target.value });
                  }
                }}
                onSelect={(event) => {
                  if (!isUnder18()) {
                    setCPF(cpfMask(event.currentTarget.value));
                    setCPFResp(cpfMask(event.currentTarget.value));
                    setStudent({
                      ...student,
                      cpf: event.currentTarget.value,
                      cpfResponsavel: event.currentTarget.value,
                    });
                  } else {
                    setCPF(cpfMask(event.currentTarget.value));
                    setStudent({ ...student, cpf: event.currentTarget.value });
                  }
                }}
                onBlur={(event) => {
                  validarCPF(event.target);
                }}
                autoComplete="off"
                required
              />
            </div>

            <div className="input-container_small">
              {/* <label>RG</label> */}
              <input
                className="input-field"
                type="text"
                id="rg"
                placeholder="RG*"
                value={rg}
                onChange={(event) => {
                  if (!isUnder18()) {
                    setRG(event.target.value.replace(/\D/g, ""));
                    setRGResp(event.target.value.replace(/\D/g, ""));
                    setStudent({
                      ...student,
                      rg: event.target.value,
                      rgResponsavel: event.target.value,
                    });
                  } else {
                    setRG(event.target.value.replace(/\D/g, ""));
                    setStudent({ ...student, rg: event.target.value });
                  }
                }}
                onSelect={(event) => {
                  if (!isUnder18()) {
                    setRG(event.currentTarget.value.replace(/\D/g, ""));
                    setRGResp(event.currentTarget.value.replace(/\D/g, ""));
                    setStudent({
                      ...student,
                      rg: event.currentTarget.value,
                      rgResponsavel: event.currentTarget.value,
                    });
                  } else {
                    setRG(event.currentTarget.value.replace(/\D/g, ""));
                    setStudent({ ...student, rg: event.currentTarget.value });
                  }
                }}
                autoComplete="off"
                required
              />
            </div>

            <div className="input-container_small">
              {/* <label>Data de nascimento</label> */}
              <input
                className="input-field"
                type="date"
                id="date"
                name="date"
                placeholder=""
                max={todayDate}
                onChange={(event) => {
                  setBirthday(event.target.value);
                  setStudent({ ...student, birthday: event.target.value });
                }}
                onSelect={(event) => {
                  setBirthday(event.currentTarget.value);
                  setStudent({
                    ...student,
                    birthday: event.currentTarget.value,
                  });
                }}
                value={birthday}
                disabled
                autoComplete="off"
                required
              />
            </div>

            <div className="input-container_small">
              {/* <label>Celular</label> */}
              <input
                className="input-field"
                type="text"
                id="celular"
                placeholder="Celular do Aluno"
                minLength={14}
                maxLength={15}
                value={celular}
                onChange={(event) => {
                  if (!isUnder18()) {
                    setCelular(phoneMask(event.target.value));
                    setPhoneResp(phoneMask(event.currentTarget.value));
                    setStudent({
                      ...student,
                      celularAluno: event.target.value,
                      celularResponsavel: event.target.value,
                    });
                  } else {
                    setCelular(phoneMask(event.currentTarget.value));
                    setStudent({
                      ...student,
                      celularAluno: event.target.value,
                    });
                  }
                }}
                onSelect={(event) => {
                  if (!isUnder18()) {
                    setCelular(phoneMask(event.currentTarget.value));
                    setPhoneResp(phoneMask(event.currentTarget.value));
                    setStudent({
                      ...student,
                      celularAluno: event.currentTarget.value,
                      celularResponsavel: event.currentTarget.value,
                    });
                  } else {
                    setCelular(phoneMask(event.currentTarget.value));
                    setStudent({
                      ...student,
                      celularAluno: event.currentTarget.value,
                    });
                  }
                }}
                autoComplete="off"
                required
              />
            </div>

            <div className="input-container_small2">
              {/* <label>Celular do responsável</label> */}
              <input
                className="input-field"
                type="text"
                id="telefone"
                placeholder="Celular do Responsável*"
                minLength={14}
                maxLength={15}
                value={isUnder18() ? phoneResp : celular}
                disabled={!isUnder18()}
                onChange={(event) => {
                  setPhoneResp(phoneMask(event.target.value));
                  setStudent({
                    ...student,
                    celularResponsavel: event.target.value,
                  });
                }}
                onSelect={(event) => {
                  setPhoneResp(phoneMask(event.currentTarget.value));
                  setStudent({
                    ...student,
                    celularResponsavel: event.currentTarget.value,
                  });
                }}
                required
              />
            </div>

            <div className="input-container_small2">
              {/* <label>CPF</label> */}
              <input
                className="input-field"
                type="text"
                id="cpf"
                placeholder="CPF do Responsável*"
                value={isUnder18() ? cpfResp : cpf}
                disabled={!isUnder18()}
                minLength={14}
                maxLength={14}
                onBlur={(event) => {
                  validarCPF(event.target);
                }}
                onChange={(event) => {
                  setCPFResp(cpfMask(event.target.value));
                  setStudent({
                    ...student,
                    cpfResponsavel: event.target.value,
                  });
                }}
                onSelect={(event) => {
                  setCPFResp(cpfMask(event.currentTarget.value));
                  setStudent({
                    ...student,
                    cpfResponsavel: event.currentTarget.value,
                  });
                }}
                autoComplete="off"
                required
              />
            </div>

            <div className="input-container_small2">
              {/* <label>RG</label> */}
              <input
                className="input-field"
                type="text"
                id="rg"
                placeholder="RG do Responsável*"
                value={isUnder18() ? rgResp : rg}
                disabled={!isUnder18()}
                onChange={(event) => {
                  setRGResp(event.target.value.replace(/\D/g, ""));
                  setStudent({ ...student, rgResponsavel: event.target.value });
                }}
                onSelect={(event) => {
                  setRGResp(event.currentTarget.value.replace(/\D/g, ""));
                  setStudent({
                    ...student,
                    rgResponsavel: event.currentTarget.value,
                  });
                }}
                autoComplete="off"
                required
              />
            </div>

            <div className="input-container">
              {/* <label htmlFor="address">Endereço completo</label> */}
              <textarea
                className="input-field"
                id="address"
                placeholder="Endereço completo*"
                value={address}
                onChange={(event) => {
                  setAddress(event.target.value);
                  setStudent({ ...student, address: event.target.value });
                }}
                onSelect={(event) => {
                  setAddress(event.currentTarget.value);
                  setStudent({
                    ...student,
                    address: event.currentTarget.value,
                  });
                }}
                autoComplete="off"
                rows={5}
                required
              />
            </div>

            <div className="input-container_small">
              {/* <label>CEP</label> */}
              <input
                className="input-field"
                type="text"
                id="cep"
                placeholder="CEP*"
                minLength={8}
                maxLength={8}
                value={cep}
                onChange={(event) => {
                  setCep(event.target.value.replace(/\D/g, ""));
                  setStudent({ ...student, cep: event.target.value });
                }}
                onSelect={(event) => {
                  setCep(event.currentTarget.value.replace(/\D/g, ""));
                  setStudent({ ...student, cep: event.currentTarget.value });
                }}
                autoComplete="off"
                required
              />
            </div>

            <div className="input-container_small">
              {/* <label>Cidade</label> */}
              <input
                className="input-field"
                type="text"
                id="cidade"
                placeholder="Cidade*"
                value={cidade}
                onChange={(event) => {
                  setCidade(event.target.value);
                  setStudent({ ...student, cidade: event.target.value });
                }}
                onSelect={(event) => {
                  setCidade(event.currentTarget.value);
                  setStudent({ ...student, cidade: event.currentTarget.value });
                }}
                autoComplete="off"
                required
              />
            </div>

            <br />

            <div className="input-container_half">
              {/* <label className="input-label">Escola em que estuda ou se formou:</label> */}
              <input
                className="input-field"
                type="text"
                id="school"
                placeholder="Nome da Escola*"
                value={school}
                onChange={(event) => {
                  setSchool(event.target.value);
                  setStudent({ ...student, escola: event.target.value });
                }}
                onSelect={(event) => {
                  setSchool(event.currentTarget.value);
                  setStudent({ ...student, escola: event.currentTarget.value });
                }}
                autoComplete="off"
              />
            </div>

            <div className="input-container_small">
              {/* <label>Série/Turma</label> */}
              <select
                className="input-field input-select"
                id="turma"
                value={turma}
                style={{
                  backgroundColor: isOpen ? "#6d6b6b" : "transparent",
                }}
                onFocus={() => setIsOpen(true)}
                onBlur={() => setIsOpen(false)}
                onChange={(event) => {
                  setTurma(event.target.value);
                  setStudent({ ...student, turmaDaEscola: event.target.value });
                }}
                onSelect={(event) => {
                  setTurma(event.currentTarget.value);
                  setStudent({
                    ...student,
                    turmaDaEscola: event.currentTarget.value,
                  });
                }}
              >
                <option value="" disabled hidden>
                  Série/Turma*
                </option>
                <option value="1º Ano">1º Ano</option>
                <option value="2º Ano">2º Ano</option>
                <option value="3º Ano">3º Ano</option>
                <option value="4º Ano">4º Ano</option>
                <option value="Ensino Médio Concluído">Ensino Médio Concluído</option>
                <option value="Outro">Outro</option>
              </select>
            </div>

            <div className="input-container_half">
              {/* <label htmlFor="curso">Curso da Universidade?*</label> */}
              <select
                id="curso"
                className="input-field input-select"
                value={universidade}
                onChange={(event) => {
                  setUniversidade(event.target.value);
                  setStudent({
                    ...student,
                    cursoUniversidade: event.target.value,
                  });
                }}
                onSelect={(event) => {
                  setUniversidade(event.currentTarget.value);
                  setStudent({
                    ...student,
                    cursoUniversidade: event.currentTarget.value,
                  });
                }}
                onFocus={() => setIsOpenUniversidade(true)}
                onBlur={() => setIsOpenUniversidade(false)}
                style={{
                  backgroundColor: isOpenUniversidade
                    ? "#6d6b6b"
                    : "transparent",
                }}
                required
              >
                <option value="" disabled>
                  Curso da Universidade?*
                </option>
                <option value="Outro">Outro</option>
                <option value="Não sei ainda">Não sei Ainda</option>
                {cursosPernambuco.map((curso) => (
                  <option key={curso} value={curso}>
                    {curso}
                  </option>
                ))}
              </select>
            </div>

            <div className="input-container_half-select">
              {/* <label>Você já fez o ENEM por experiência?</label> */}
              <select
                className="input-field input-select"
                value={enem}
                id="enem"
                onChange={(event) => {
                  setEnem(event.target.value);
                  setStudent({ ...student, enem: event.target.value });
                }}
                onSelect={(event) => {
                  setEnem(event.currentTarget.value);
                  setStudent({ ...student, enem: event.currentTarget.value });
                }}
                style={{
                  backgroundColor: isOpenEnem ? "#6d6b6b" : "transparent",
                }}
                onFocus={() => setIsOpenEnem(true)}
                onBlur={() => setIsOpenEnem(false)}
                required
              >
                <option value="" disabled hidden>
                  Já fez o ENEM antes?*
                </option>
                <option value="sim">Sim</option>
                <option value="não">Não</option>
                <option value="não lembro">Não lembro</option>
              </select>
            </div>

            <div className="input-container">
              {/* <label>Nome do Responsável</label> */}
              <input
                className="input-field"
                type="text"
                id="responsavel"
                placeholder="Nome do Responsável*"
                value={isUnder18() ? responsavel : name}
                disabled={!isUnder18()}
                onChange={(event) => {
                  setResponsavel(isUnder18() ? event.target.value : name);
                  setStudent({ ...student, responsavel: event.target.value });
                }}
                onSelect={(event) => {
                  setResponsavel(event.currentTarget.value);
                  setStudent({
                    ...student,
                    responsavel: event.currentTarget.value,
                  });
                }}
                autoComplete="off"
                required
              />
            </div>

            <div className="input-container">
              {/* <label>Curso selecionado</label> */}
              <input
                className="input-field"
                type="text"
                id="curso-selecionado"
                placeholder="Curso Selecionado"
                onChange={(event) => {
                  setStudent({
                    ...student,
                    cursoMatriculado: event.target.value,
                  });
                }}
                onSelect={(event) => {
                  setStudent({
                    ...student,
                    cursoMatriculado: event.currentTarget.value,
                  });
                }}
                value={getCourse(course, cidadeDoCurso, opcoesTurmaData)}
                disabled
                autoComplete="off"
                required
              />
            </div>
            <div className="form-buttons-container">
              <button type="submit" className="btn btn-success submit-button">
                CONCLUIR MATRÍCULA
              </button>

              <button
                onClick={() => setMatriculasOpen(true)}
                className="btn btn-secondary submit-button-cancelar"
              >
                VOLTAR
              </button>
            </div>
          </form>
        </div>
      )}

      {
        paymentOpen && <StudentFirstPayments state={state} student={contactWithId} isLogged={false} typePayment="" />
      }

      <div>
        {/* Se showModal for verdadeiro, o modal será exibido */}
        {showModal && (
          <AlertModal message={textModalMessage} onClose={handleCloseModal} />
        )}
      </div>

      <div>
        {/* Se showModal for verdadeiro, o modal será exibido */}
        {showModalSuccess && (
          <AlertModal
            message={textModalMessage}
            onClose={handleSubscribeSuccefull}
          />
        )}
      </div>
    </>
  );
};

export default StudentForm;
