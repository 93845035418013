import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { auth } from "../database/firebaseConfig";
import loading from "../images/home/background-default.gif";
import AdmHeader from "../components/AdmHeader";
import AlertModal from "../components/AlertModal";
import "../styles/AdmsCreateNewUsers.css";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import { signUpAdms } from "../services/SingUp";
import { signOut } from "../services/SingOut";

const AdmsCreateNewUsers = (props: {state: any}) => {
  const navigate = useNavigate();
  const { state } = props;
  const role = state?.role;
  const imgUrl = state?.imgUrl ? state?.imgUrl : loading;

  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [telefone, setTelefone] = useState("");
  const [cargo, setCargo] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleCloseModal = () => {
    setShowModal(false);
    setError('');
    setSuccessMessage('');
  };

  function phoneMask(valor: string) {
    return valor
      .replace(/\D/g, "")
      .replace(/^(\d{2})(\d)/g, "($1) $2")
      .replace(/(\d)(\d{4})$/, "$1-$2");
  }

  const handleSignUp = async () => {
    try {
      await signUpAdms(email, password, cargo, true, name, telefone);
      // SignUp successful, update the state with success message
      setSuccessMessage("Cadastro realizado com sucesso!");
      setShowModal(true);

      // Optionally, clear the email and password fields after successful signup
      setEmail("");
      setPassword("");
      setName("");
      setTelefone("");
      setCargo("");
    } catch (error: any) {
      setError(error.message);
      setShowModal(true);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const isEmailValid = (email: string) => {
    // Use a regular expression to check if the email is valid
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <div className="adms-page-create">
      <h4 style={{color: '#fff', marginBottom: '20px'}}>CADASTRO DE USUÁRIOS</h4>
      <div className="card-container-users">
        <form className="form-signup new-users was-validated">
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              title="Insira nome e sobrenome do colaborador!"
              autoComplete="off"
            />
          </div>
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Telefone"
              value={telefone}
              onChange={(e) => setTelefone(phoneMask(e.target.value))}
              required
              title="Insira o telefone do colaborador!"
              autoComplete="off"
              minLength={14}
              maxLength={15}
            />
          </div>
          <select
            value={cargo}
            onChange={(e) => setCargo(e.target.value)}
            className="form-control"
          >
            <option value="" hidden disabled>
              Escolha o cargo
            </option>
            <option value="secretaria">Secretária(o)</option>
            <option value="corretor">Corretor(a)</option>
            <option value="adm">Administrador(a)</option>
            <option value="professor">Professor(a)</option>
            <option value="student">Aluno(a)</option>
          </select>
          <div className="input-group mb-3">
            <input
              type="email"
              className="form-control"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
              title="Insira um email válido!"
              autoComplete="off"
            />
          </div>

          <div className="input-group mb-3">
            <input
              type={showPassword ? "text" : "password"}
              className="form-control"
              placeholder="Senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              autoComplete="off"
            />
            <div className="input-group-append">
              <span
                className="input-group-text  eye-button"
                onClick={toggleShowPassword}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
          </div>

          <div className="buttons-container">
            <button
              className="btn btn-primary adm-button"
              type="button"
              onClick={handleSignUp}
              disabled={!isEmailValid(email) || password === ""}
            >
              CONCLUIR CADASTRO
            </button>
          </div>
        </form>

        {showModal && successMessage && (
          <AlertModal message={successMessage} onClose={handleCloseModal} />
        )}

        {showModal && error && (
          <AlertModal message={error} onClose={handleCloseModal} />
        )}
      </div>
    </div>
  );
};

export default AdmsCreateNewUsers;
