import React, { useEffect, useState } from "react";
import { MaterialsData } from "../interfaces/Turmas";
import { StudentData } from "../interfaces/Students";
import { getMaterialsFirestore } from "../database/firestore";
import { useTheme } from "@mui/material/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  FaFolderOpen,
  FaFolder,
  FaFileVideo,
  FaArrowLeft,
  FaChevronDown,
} from "react-icons/fa";
import { FaRegFilePdf } from "react-icons/fa";
import ModalPdf from "../components/ModalPDF";
import "../styles/StudentsPage.css";
import "bootstrap/dist/css/bootstrap.min.css";

const StudentWebClass = (props: { state: any }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md")); // Check if screen size is less than or equal to "sm"
  const { state } = props;
  const user: StudentData = state?.userFullData;

  const [showModal, setShowModal] = useState(false);
  const [materials, setMaterials] = useState<MaterialsData[] | any>(null);
  const [folderOpen, setFolderOpen] = useState<any>({});
  const [selectedMaterial, setSelectedMaterial] = useState<MaterialsData | any>(
    null
  );
  const [selectedFile, setSelectedFile] = useState<MaterialsData | any>(null);

  useEffect(() => {
    const loadMaterials = async () => {
      try {
        const materialsData = await getMaterialsFirestore();

        // Filtrando os materiais com base no cursoCode
        const filteredMaterials = materialsData.filter(
          (material: MaterialsData) => material.turmaId === user.cursoCode
        );

        // Agrupando os materiais pelo nome da pasta
        const groupedMaterials: { [key: string]: MaterialsData[] } =
          filteredMaterials.reduce((acc: any, material) => {
            const folderName: string = material.folderName;
            if (!acc[folderName]) {
              acc[folderName] = [];
            }
            acc[folderName].push(material);
            return acc;
          }, {});

        // Convertendo o objeto em array
        const groupedMaterialsArray = Object.keys(groupedMaterials).map(
          (folderName) => ({
            folderName,
            materials: groupedMaterials[folderName],
          })
        );

        setMaterials(groupedMaterialsArray);

        // Inicializando folderOpen para abrir a primeira pasta
        const initialFolderOpen: any = {};
        groupedMaterialsArray.forEach((item) => {
          // if (item.folderName === "Aula 01") {
          //   initialFolderOpen[item.folderName] = true;
          // } else {
          //   initialFolderOpen[item.folderName] = false;
          // }
          initialFolderOpen[item.folderName] = false;
        });
        setFolderOpen(initialFolderOpen);
      } catch (error) {
        console.error(`Erro ao buscar atendimentos: ${error}`);
      }
    };

    loadMaterials();
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
    // Optionally, clear the email and password fields after successful signup
  };

  const turmaString = user
    ? user.cursoMatriculado
    : "Curso não encontrado - Modalidade - turma não encontrada - cidade não encontrada - horário não encontrado";

  // Quebrando a string usando '-'
  const [curso, modalidade, turma, cidade, horario] = turmaString.split(" - ");

  const capitalizeWords = (str: string) => {
    return str.toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
      return a.toUpperCase();
    });
  };

  const toggleFolder = (
    folderName: string,
    materialSelected: MaterialsData
  ) => {
    const updatedFolderOpen: { [key: string]: boolean } = {};
    Object.keys(folderOpen).forEach((name) => {
      updatedFolderOpen[name] = name === folderName ? !folderOpen[name] : false;
    });
    setFolderOpen({ ...updatedFolderOpen });
    setSelectedMaterial(materialSelected);
  };

  /**
   * Opens a new browser tab with the specified URL.
   *
   * @param {string} url - The URL to open in a new tab.
   * @return {void} This function does not return anything.
   */
  const handleFolderClick = (url: string) => {
    window.open(url, "_blank");
  };

  const getMaterilasListMobile = (materials: any) => {
    return (
      // <div
      //   style={{
      //     display: "flex",
      //     flexWrap: "wrap",
      //     gap: "20px",
      //     justifyContent: "center",
      //   }}
      // >
      //   {materials
      //     .sort((a: MaterialsData, b: MaterialsData) =>
      //       a.folderName.localeCompare(b.folderName)
      //     )
      //     .map((material: any) => (
      //       <div
      //         id="folder-container"
      //         key={material.folderName}
      //         style={{
      //           //width: "100%",
      //           display: "flex",
      //           flexDirection: "column",
      //           justifyContent: "center",
      //           alignItems: "center",
      //         }}
      //       >
      //         <div
      //           id="folder-icone-container"
      //           style={{
      //             width: "100%",
      //             minWidth: "120px",
      //             height: "150px",
      //             margin: "auto",
      //             fontSize: "80px",
      //             color: "#ffffff",
      //             alignItems: "center",
      //             display: "flex",
      //             justifyContent: "center",
      //             flexDirection: "column",
      //           }}
      //         >
      //           {folderOpen[material.folderName] ? (
      //             <FaFolderOpen
      //               onClick={() => toggleFolder(material.folderName, material)}
      //               style={{ cursor: "pointer" }}
      //             />
      //           ) : (
      //             <FaFolder
      //               onClick={() => toggleFolder(material.folderName, material)}
      //               style={{ cursor: "pointer" }}
      //             />
      //           )}
      //           <h6 id="folder-name">{material.folderName}</h6>
      //         </div>

      //         {folderOpen[material.folderName] && (
      //           <div
      //             style={{
      //               width: "auto",
      //               minWidth: "auto",
      //               height: "250px",
      //               margin: "20px",
      //               alignItems: "center",
      //               display: folderOpen[material.folderName] ? "block" : "none",
      //               justifyContent: "center",
      //               transition: "opacity 2s",
      //             }}
      //             id="material-container"
      //           >
      //             <div
      //               style={{
      //                 display: "flex",
      //                 width: "100%",
      //                 height: "auto",
      //                 backgroundColor: "#ffffff",
      //                 margin: "auto",
      //                 opacity: folderOpen[material.folderName] ? 1 : 0,
      //                 borderRadius: "10px",
      //                 flexWrap: "wrap",
      //                 gap: "20px",
      //                 fontSize: "60px",
      //                 justifyContent: "space-around",
      //                 alignItems: "flex-start",
      //                 padding: "40px",
      //                 position: "relative",
      //                 zIndex: "99",
      //               }}
      //               id="material-container-contents"
      //             >
      //               {material.materials
      //                 .sort((a: MaterialsData, b: MaterialsData) =>
      //                   a.fileName.localeCompare(b.fileName)
      //                 )
      //                 .map((materialFile: MaterialsData) => (
      //                   <div
      //                     key={materialFile.id}
      //                     style={{ textDecoration: "none", cursor: "pointer" }}
      //                     onClick={() => {
      //                       setSelectedFile(materialFile);
      //                       if (materialFile.type === "folder") {
      //                         setShowModal(false);
      //                         handleFolderClick(materialFile.iframeLink);
      //                       } else {
      //                         setShowModal(true);
      //                       }
      //                     }}
      //                   >
      //                     <div
      //                       id="material-icon-container"
      //                       style={{
      //                         width: "100px",
      //                         color: "#007b9c",
      //                         display: "flex",
      //                         flexDirection: "column",
      //                         justifyContent: "center",
      //                         alignItems: "center",
      //                         gap: "8px",
      //                       }}
      //                     >
      //                       {materialFile.type === "pdf" ? (
      //                         <FaRegFilePdf />
      //                       ) : materialFile.type === "folder" ? (
      //                         <FaFolder />
      //                       ) : (
      //                         <FaFileVideo />
      //                       )}
      //                       <h6 id="file-name" style={{ textAlign: "center" }}>
      //                         {materialFile.fileName}
      //                       </h6>
      //                     </div>
      //                   </div>
      //                 ))}
      //             </div>
      //           </div>
      //         )}
      //       </div>
      //     ))}
      // </div>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "20px",
          justifyContent: "center",
        }}
      >
        {materials
          .sort((a: any, b: any) => a.folderName.localeCompare(b.folderName))
          .map((material: any) => (
            <div
              key={material.folderName}
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Accordion
                expanded={folderOpen[material.folderName]}
                onChange={() => toggleFolder(material.folderName, material)}
                style={{
                  backgroundColor: "#010B49",
                  border: "1px solid #FFFFFF",
                  borderRadius: "10px",
                }}
              >
                <AccordionSummary
                  expandIcon={<FaChevronDown style={{ color: "#FFFFFF" }} />}
                  aria-controls={`${material.folderName}-content`}
                  id={`${material.folderName}-header`}
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#FFFFFF",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    {folderOpen[material.folderName] ? (
                      <FaFolderOpen
                        style={{
                          cursor: "pointer",
                          fontSize: "50px",
                          
                        }}
                      />
                    ) : (
                      <FaFolder
                        style={{
                          cursor: "pointer",
                          fontSize: "50px",
                          
                        }}
                      />
                    )}
                    <Typography sx={{color: "#FFFFFF"}}>{material.folderName}</Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "20px",
                      justifyContent: "space-around",
                      padding: "20px",
                      //backgroundColor: "#ffffff",
                      borderRadius: "10px",
                    }}
                  >
                    {material.materials
                      .sort((a: MaterialsData, b: MaterialsData) =>
                        a.fileName.localeCompare(b.fileName)
                      )
                      .map((materialFile: MaterialsData) => (
                        <div
                          key={materialFile.id}
                          style={{ textDecoration: "none", cursor: "pointer" }}
                          onClick={() => {
                            setSelectedFile(materialFile);
                            if (materialFile.type === "folder") {
                              handleFolderClick(materialFile.iframeLink);
                            } else {
                              setShowModal(true);
                            }
                          }}
                        >
                          <div
                            style={{
                              width: "100px",
                              //color: "#007b9c",
                              color: "#FFFFFF",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              gap: "8px",
                              
                            }}
                          >
                            {materialFile.type === "pdf" ? (
                              <FaRegFilePdf style={{fontSize: "40px"}}/>
                            ) : materialFile.type === "folder" ? (
                              <FaFolder style={{fontSize: "40px"}}/>
                            ) : (
                              <FaFileVideo style={{fontSize: "40px"}}/>
                            )}
                            <Typography style={{ textAlign: "center", fontSize: "12px" }}>
                              {materialFile.fileName}
                            </Typography>
                          </div>
                        </div>
                      ))}
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          ))}
      </div>
    );
  };

  const getMaterilasListDesktop = (materials: any) => {
    return (
      <div
        style={{
          display: "flex",
          //gap: "2px",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        {materials
          .sort((a: MaterialsData, b: MaterialsData) =>
            a.folderName.localeCompare(b.folderName)
          )
          .map((material: any) => (
            <div
              id="folder-container"
              key={material.folderName}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                id="folder-icone-container"
                className={`folder-button ${
                  folderOpen[material.folderName] ? "folder-open" : ""
                }`}
                onClick={() => toggleFolder(material.folderName, material)}
              >
                {folderOpen[material.folderName] ? (
                  <FaFolderOpen
                    style={{
                      cursor: "pointer",
                      color: "#ffffff",
                      fontSize: "30px",
                    }}
                  />
                ) : (
                  <FaFolder
                    style={{
                      cursor: "pointer",
                      color: "#ffffff",
                      fontSize: "30px",
                    }}
                  />
                )}
                <span id="folder-name" className="folder-name">
                  {material.folderName}
                </span>
              </button>
            </div>
          ))}
      </div>
    );
  };

  const getFilesList = (material: any) => {
    console.log(material);
    return (
      <div style={{ width: "100%", display: "flex" }}>
        {folderOpen[material.folderName] && (
          <div id="material-container" className="material-container">
            <span
              style={{
                color: "#007b9c",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "8px",
                borderBottom: "1px solid #4040405E",
                paddingBottom: "8px",
              }}
            >
              <FaArrowLeft /> {material.folderName}
            </span>
            <div
              id="material-container-contents"
              className="material-container-contents"
            >
              {material.materials
                .sort((a: MaterialsData, b: MaterialsData) =>
                  a.fileName.localeCompare(b.fileName)
                )
                .map((materialFile: MaterialsData) => (
                  <div
                    key={materialFile.id}
                    style={{ textDecoration: "none", cursor: "pointer" }}
                    onClick={() => {
                      setSelectedFile(materialFile);
                      if (materialFile.type === "folder") {
                        setShowModal(false);
                        handleFolderClick(materialFile.iframeLink);
                      } else {
                        setShowModal(true);
                      }
                    }}
                  >
                    <div
                      id="material-icon-container"
                      style={{
                        width: "100px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "8px",
                      }}
                    >
                      {materialFile.type === "pdf" ? (
                        <FaRegFilePdf />
                      ) : materialFile.type === "folder" ? (
                        <FaFolder />
                      ) : (
                        <FaFileVideo />
                      )}
                      <h6
                        id="file-name"
                        style={{ textAlign: "center", fontSize: "14px" }}
                      >
                        {materialFile.fileName}
                      </h6>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="students-page-payment">
      <div
        className="payments-content"
        style={{ justifyContent: "center", margin: "0 auto", gap: "0" }}
      >
        <h2
          style={{
            color: "#FFFFFF",
            textAlign: "center",
            marginBottom: "8px",
            fontSize: "24px",
          }}
        >
          Seja bem-vindo(a) à sala virtual da {turma}
        </h2>
        <h5
          style={{
            color: "#FAFF03",
            marginBottom: "3rem",
            textAlign: "center",
            marginTop: "0",
            fontSize: "16px",
          }}
        >
          {modalidade} de {capitalizeWords(cidade)}
        </h5>
        <div
          style={{
            width: isMobile ? "100%" : "100vw",
            display: "flex",
            margin: "0 auto",
            justifyContent: "center",
          }}
        >
          {materials !== null && isMobile && getMaterilasListMobile(materials)}

          {materials !== null &&
            !isMobile &&
            getMaterilasListDesktop(materials)}
          {materials !== null && !isMobile && (
            <div
              style={{
                backgroundColor: "white",
                width: "70%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                borderRadius: "10px",
                padding: "20px",
              }}
            >
              {materials !== null &&
                !isMobile &&
                selectedMaterial !== null &&
                getFilesList(selectedMaterial)}

              {selectedMaterial !== null &&
                !folderOpen[selectedMaterial.folderName] && (
                  <span
                    style={{
                      color: "#9A9191",
                      fontSize: "24px",
                      fontWeight: "400",
                      lineHeight: "29px",
                      alignSelf: "center",
                      margin: "auto 0",
                    }}
                  >
                    Selecione a pasta ao lado para visualizar as aulas
                  </span>
                )}

              {selectedMaterial === null && (
                <span
                  style={{
                    color: "#9A9191",
                    fontSize: "24px",
                    fontWeight: "400",
                    lineHeight: "29px",
                    margin: "auto 0",
                  }}
                >
                  Selecione a pasta ao lado para visualizar as aulas
                </span>
              )}
            </div>
          )}
        </div>
      </div>

      {showModal && (
        <ModalPdf material={selectedFile} close={handleCloseModal} />
      )}
    </div>
  );
};

export default StudentWebClass;
