import React, { useEffect, useState } from "react";
import "../styles/AdmsCreateNewUsers.css";
import { getUsersFirestore } from "../database/firestore";
import { AdmUserData } from "../interfaces/Adms";

const AdmsReportsUsers = (props: {state: any;}) => {
  // const navigate = useNavigate();
  // const { state } = props.state;
  // const role = state?.role;
  // const imgUrl = state?.imgUrl ? state?.imgUrl : loading;

  const [userData, setUserData] = useState<any>([]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userDataArray = await getUsersFirestore();
        setUserData(userDataArray);
      } catch (error) {
        console.error("Erro ao buscar dados dos usuários:", error);
      }
    };

    fetchUserData();
  }, []);

  return (
    <div className="adms-page-create">
      {/* <AdmHeader isFirstPage={false} nome={`Bem-vindo ${role}`} /> */}
      <div className="table-container-adms">
        <table>
          <thead>
            <tr>
              <th>Email</th>
              <th>Nome</th>
              <th>Telefone</th>
              <th>Função</th>
            </tr>
          </thead>
          <tbody>
            {userData.filter((user: AdmUserData) => user.role !== 'student').map((user: AdmUserData) => (
              <tr key={user.id}>
                <td>{user.email}</td>
                <td>{user.name}</td>
                <td>{user.telefone}</td>
                <td>{user.role}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdmsReportsUsers;
