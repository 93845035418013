import React, { useState } from "react";
import AlertModal from "../components/AlertModal";
import "../styles/AdmsCreateNewUsers.css";
import "../styles/AdmsCreateCourses.css";
import { ScheduleData } from "../interfaces/Turmas";
import { v4 as uuidv4 } from "uuid"; // Importa a função v4 da biblioteca uuid
import { addSchedulesFirestore } from "../database/firestore";
import { Spinner } from "react-bootstrap";


const AdmsCreateSchedules = (props: {state: any}) => {
  // const navigate = useNavigate();
  // const { state } = props;
  // const role = state?.role;
  // const imgUrl = state?.imgUrl ? state?.imgUrl : loading;

  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  let calendar = new Date().toISOString().split("T")[0];

  const [corretor, setCorretor] = useState("");
  const [scheduleDate, setScheduleDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [stopTime, setStopTime] = useState("");
  const [qtdVagas, setQtdVagas] = useState(0);
  const [cidade, setCidade] = useState("");
  const [turma, setTurma] = useState("");
  const [spinnerLoading, setSpinnerLoading] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
    setError("");
    setSuccessMessage("");
    // Optionally, clear the email and password fields after successful signup

  };

  const handleCreateSchedule = async () => {

    setSpinnerLoading(true);

    const schedule: ScheduleData = {
      id: uuidv4(),
      corretor,
      dataDoAgendamento: scheduleDate,
      disabled: false,
      startTime,
      stopTime,
      qtdVagas,
      cidade,
      turma,
      alunos: [],
    };

    try {
      await addSchedulesFirestore(schedule);
      // SignUp successful, update the state with success message
      setSuccessMessage("Cadastro do atendimento foi realizado com sucesso!");
      setShowModal(true);
      setSpinnerLoading(false);

      //limpar os inputs
      setCidade("")
      setCorretor("");
      setScheduleDate("");
      setStartTime("");
      setStopTime("");
      setQtdVagas(0);
      setTurma("");

    } catch (error: any) {
      setError(error.message);
      setShowModal(true);
      setSpinnerLoading(false);
    }
    
  };


  return (
    <div className="adms-page-create">
      <div className="card-container-users">
            <strong style={{display: 'flex', textAlign: 'center', width: '100%', color: '#fff', textTransform: 'uppercase', justifyContent: 'center', fontSize: '24px', marginBottom: '20px' }}>
              Criar atendimentos
            </strong>

            <form
              className="form-signup new-courses was-validated gap-4 d-flex flex-column"
            >
              <div className="div-form-group">
                <label className="div-input-label" htmlFor="turma">Corretor:</label>
                <select
                  className="div-input-field"
                  id="turma"
                  value={corretor}
                  onChange={(event) => setCorretor(event.target.value)}
                  required
                >
                  <option value="">ESCOLHA UMA OPÇÃO</option>
                  <option value="Elias Ancelmo">Elias Ancelmo</option>
                  <option value="João Prazeres">João Prazeres</option>
                  <option value="Jamilly Oliveira">Jamilly Oliveira</option>
                </select>
              </div>

              <div className="div-form-group">
                <label className="div-input-label" htmlFor="date">Data do atendimento:</label>
                <input
                  className="div-input-field"
                  type="date"
                  id="date"
                  value={scheduleDate}
                  min={calendar}
                  onChange={(event) => setScheduleDate(event.target.value)}
                  required
                />
              </div>

              <div className="div-form-group">
                <label className="div-input-label" htmlFor="timeIn">Início do atendimento:</label>
                <input
                  className="div-input-field"
                  type="time"
                  id="timeIn"
                  value={startTime}
                  onChange={(event) => setStartTime(event.target.value)}
                  required
                />
              </div>

              <div className="div-form-group">
                <label className="div-input-label" htmlFor="timeOut">Fim do atendimento:</label>
                <input
                  className="div-input-field"
                  type="time"
                  id="timeOut"
                  value={stopTime}
                  onChange={(event) => setStopTime(event.target.value)}
                  required
                />
              </div>

              <div className="div-form-group">
                <label className="div-input-label" htmlFor="qtdVagas">Quantidade de vagas:</label>
                <input
                  className="div-input-field"
                  type="number"
                  id="qtdVagas"
                  min={1}
                  value={qtdVagas}
                  onChange={(event) => setQtdVagas(parseInt(event.target.value))}
                  required
                />
              </div>

              <div className="div-form-group">
                <label className="div-input-label" htmlFor="cidade">Cidade do atendimento:</label>
                <select
                  className="div-input-field"
                  id="cidade"
                  value={cidade}
                  onChange={(event) => {
                    setCidade(event.target.value);
                  }}
                  required
                >
                  <option value="">ESCOLHA UMA OPÇÃO</option>
                  <option value="Limoeiro">LIMOEIRO</option>
                  <option value="Surubim">SURUBIM</option>
                  <option value="Vitória">VITÓRIA DE SANTO ANTÃO</option>
                  <option value="Online">ONLINE</option>
                </select>
              </div>

              <div className="div-form-group">
                <label className="div-input-label" htmlFor="turma">Turma do atendimento:</label>
                <select
                  className="div-input-field"
                  id="turma"
                  value={turma}
                  onChange={(event) => {
                    setTurma(event.target.value);
                  }}
                  required
                >
                  <option value="">ESCOLHA UMA OPÇÃO</option>
                  <option value="Turma 1">TURMA 1</option>
                  <option value="Turma 2">TURMA 2</option>
                  <option value="Turma 3">TURMA 3</option>
                  <option value="Turma Online">TURMA ONLINE</option>
                </select>
              </div>

              <button
                disabled={spinnerLoading}
                className="btn btn-primary"
                onClick={handleCreateSchedule}
                style={{width: '100%', maxWidth: '200px', alignSelf: 'center'}}
              >
                {spinnerLoading ? (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : (
                  "CRIAR ATENDIMENTO"
                )}
              </button>
            </form>

        {showModal && successMessage && (
          <AlertModal message={successMessage} onClose={handleCloseModal} />
        )}

        {showModal && error && (
          <AlertModal message={error} onClose={handleCloseModal} />
        )}
      </div>
    </div>
  );
};

export default AdmsCreateSchedules;
