export interface StudentData {
  id: string;
  name: string;
  email: string;
  cpf: string;
  rg: string;
  birthday: string;
  celularAluno: string;
  address: string;
  cep: string;
  cidade: string;
  cidadeDoCurso: string;
  escola: string;
  turmaDaEscola: string;
  cursoUniversidade: string;
  enem: string;
  responsavel: string;
  celularResponsavel: string;
  cpfResponsavel: string;
  rgResponsavel?: string;
  cursoCode: string;
  cursoMatriculado: string;
  valorDoCurso: number;
  valorDoCursoFormatted: string;
  created: string;
  pacoteComprado?: string;
  pacoteCode?: string;
  qtdPacotes?: number;
  pagamentoMatricula: boolean;
  pagamentoPacotes?: boolean;
  janeiroPay?: boolean;
  fevereiroPay?: boolean;
  marcoPay?: boolean;
  abrilPay?: boolean;
  maioPay?: boolean;
  junhoPay?: boolean;
  julhoPay?: boolean;
  agostoPay?: boolean;
  setembroPay?: boolean;
  outubroPay?: boolean;
  novembroPay?: boolean;
  dezembroPay?: boolean;
  bloqueado: boolean;
}

export const studentEmpty: StudentData = {
  id: "",
  name: "",
  email: "",
  cpf: "",
  rg: "",
  birthday: "",
  celularAluno: "",
  address: "",
  cep: "",
  cidade: "",
  cidadeDoCurso: "",
  escola: "",
  turmaDaEscola: "",
  cursoUniversidade: "",
  enem: "",
  responsavel: "",
  celularResponsavel: "",
  cpfResponsavel: "",
  rgResponsavel: "",
  cursoCode: "",
  cursoMatriculado: "",
  valorDoCurso: 0,
  valorDoCursoFormatted: "",
  created: "",
  pacoteComprado: "",
  pacoteCode: "",
  qtdPacotes: 0,
  pagamentoMatricula: false,
  pagamentoPacotes: false,
  janeiroPay: false,
  fevereiroPay: false,
  marcoPay: false,
  abrilPay: false,
  maioPay: false,
  junhoPay: false,
  julhoPay: false,
  agostoPay: false,
  setembroPay: false,
  outubroPay: false,
  novembroPay: false,
  dezembroPay: false,
  bloqueado: false,
}
export interface UserData {
  id: string;
  isFirstAccess: boolean;
  email: string;
  role: string;
  uid: string;
}

export interface UserHeaderProps {
  nome: string;
  nomeDoCurso: string;
  isFirstAccess?: boolean;
}

export interface UserAdmHeaderProps {
  isFirstPage: boolean;
  nome: string;
  user?: any;
  imgUrl?: any;
  role?: string;
  curso: any;
}

export interface UserStudentHeaderProps {
  isFirstPage: boolean;
  nome: string;
  user?: any;
  imgUrl?: any;
  role?: string;
  curso: any;
  studentData: StudentData;
  isSignup: boolean;
}


export interface Aluno {
  cpf: string;
  nome: string;
  id: any;
  telefone: string;
  corretor: string;
  dataAtendimento: string;
  scheduleType: string;
  createdAt?: string;
};

export interface AlunoPackages {
  cpf: string;
  nome: string;
  id: number;
  telefone: string;
  dataDeCompra: string;
  email: string;
};