import React, { useEffect, useState } from "react";
import copy from "copy-to-clipboard";
import { QrCodePix } from "qrcode-pix";
import { PayQrCodeProps } from "../interfaces/PropsModels";
import Alerts from "./Alert";
import '../styles/QrCodePix.css'

export default function CustomQrCodePix (props: PayQrCodeProps) {

  const [alertText, setAlertText] = useState("");
  const [alerts, setAlerts] = useState(false);
  const [qrCode, setQrCode] = useState<string>("");
  const [rawPix, setRawPix] = useState<string>("");
  const valor = props.valor ? props.valor : 0;

  useEffect(() => {
    async function generateDynamicPix() {
      const qrCodePix = QrCodePix({
        version: "01",
        key: "eliasancelmoo19@gmail.com",
        name: "Elias Ancelmo",
        city: "LIMOEIRO",
        transactionId: "redacao2024elias",
        message: "Redação Elias Ancelmo 2024",
        value: valor,
        cep: "55700000",
        currency: 986,
        countryCode: "BR",
      });

      const rawPixStr = qrCodePix.payload();
      const qrCodeBase64 = await qrCodePix.base64();

      setRawPix(rawPixStr);
      setQrCode(qrCodeBase64);
    }

    void generateDynamicPix();
  }, [valor]);

  function copyToClipboard(pixCode: string) {
    copy(pixCode);

    setAlertText(`Você copiou o código PIX: ${pixCode}`);
  }

  return (
    <>
      <div className="qr-code">
        <div>
          <h2 className="text-center">
            Basta escanear o QR Code abaixo ou copiar o código e enviar para o
            meu PIX
          </h2>
        </div>

        <div className="">
          <img src={qrCode} alt={"QR Code PIX"} width={250} height={250} />
        </div>
      </div>
      <div className="">
        <Alerts show={alerts} content={alertText} type="danger" />
        <button
          className="btn btn-primary button-copy"
          onClick={() => {
            copyToClipboard(rawPix);
            setAlerts(true);
            setTimeout(() => {
              setAlerts(false);
            }, 5000);
          }}
        >
          COPIAR PIX CODE
        </button>
      </div>
    </>
  );
}
