import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { HiMenu } from "react-icons/hi";
import {
  Accordion,
  AccordionDetails,
  Drawer,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import { AiOutlineClose } from "react-icons/ai";
import { UserStudentHeaderProps } from "../interfaces/Students";
import { signOut } from "../services/SingOut";
import user from "../images/students/userDefault.png";
import elias from "../images/home/elias-min.png";
import logo from "../images/home/logo.png";
import nucleoBrand from "../images/home/brand.png";
import { FaUserPlus } from "react-icons/fa6";
import { GiPapers, GiPayMoney } from "react-icons/gi";
import { PiStudentDuotone, PiStudentFill } from "react-icons/pi";
import { TfiWrite } from "react-icons/tfi";
import { BiLogOutCircle } from "react-icons/bi";
import { Fragment, useEffect, useState } from "react";
import StorageUserImageComponent from "./StorageUserImageComponent/StorageUserImageComponent";
import { IoHome } from "react-icons/io5";
import { getFileURL } from "../database/storage";
import AlertModal from "./AlertModal";
import StudentPayment from "../pages/StudentPayment";
import StudentPacotes from "../pages/StudentPacotes";
import StudentSignup from "../pages/StudentSignup";
import { GrScheduleNew } from "react-icons/gr";
import StudentAgendamentos from "../pages/StudentAgendamentos";
import '../styles/AdmHeader.css'
import StudentWebClass from "../pages/StudentWebClass";

const drawerWidth = 300;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  backgroundColor: "#fff",
  color: "#007b9c",
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function StudentDrawer(props: UserStudentHeaderProps) {
  const studentFullData = props.studentData;
  const isFirstAccess = props.user.isFirstAccess;
  const isPaymentedMatricula = studentFullData?.pagamentoMatricula;
  const isSignup = props.isSignup || false;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg")); // Check if screen size is less than or equal to "lg"
  const [open, setOpen] = useState(isMobile ? false : true);
  const [expandedItem, setExpandedItem] = useState(null);
  const [uploadPhotoPerfil, setUploadPhotoPerfil] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [userPhoto, setUserPhoto] = useState(user);
  const [studentPacotes, setStudentPacotes] = useState(false);
  const [materiaisOpen, setMateriaisOpen] = useState(false);
  const [simuladosOpen, setSimuladosOpen] = useState(false);
  const [studentPayment, setStudentPayment] = useState(false);
  const [matriculas, setMatriculas] = useState(false);
  const [schedules, setSchedules] = useState(false);
  const [webClass, setWebClass] = useState(false);

  useEffect(() => {
    // Carrega a imagem de fundo ao montar o componente
    const loadPerfilImg = async () => {
      try {
        const imageUrl = await getFileURL(
          `students-photos/${
            props.user ? props.user.uid : localStorage.getItem("code")
          }.jpg`
        );
        if (imageUrl) {
          setUserPhoto(imageUrl);
        } else {
          setUserPhoto(user);
        }
      } catch (error) {
        console.error("Erro ao carregar a imagem de fundo:", error);
      }
    };

    // Carrega a imagem de fundo ao montar o componente
    loadPerfilImg();
  }, []);

  useEffect(() => {
    if (isSignup) {
      setMatriculas(true);
    }

    if (isFirstAccess) {
      setShowModal(true);
    } else if (isFirstAccess === false) {
      setShowModal(false);
    }
    
    if (isPaymentedMatricula) {
      setShowPaymentModal(false);
    } else if (isPaymentedMatricula === false) {
      setShowPaymentModal(true);
    }

    if (studentFullData.bloqueado) {
      handleLogout();
    }
  }, [isSignup, isFirstAccess, isPaymentedMatricula, studentFullData]);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const nome = props.nome ? props.nome : "Nome do Usuário";

  // Dividindo a string em palavras
  const partesDoNome = nome.split(" ");

  // Obtendo o primeiro e o último nome
  const primeiroNome = partesDoNome[0];

  const handleLogout = async () => {
    try {
      await signOut();
    } catch (error: any) {
      console.error("Erro ao fazer logout:", error.message);
    }
  };

  const resetState = () => {
    setOpen(false);
    setExpandedItem(null);
    setUploadPhotoPerfil(false);
    setStudentPayment(false);
    setStudentPacotes(false);
    setMateriaisOpen(false);
    setSimuladosOpen(false);
    setMatriculas(false);
    setSchedules(false);
    setWebClass(false)
  };

  const updateState = (updates: any[]) => {
    resetState();
    updates.forEach((update) => update());
  };

  const openPayments = () => {
    updateState([() => setStudentPayment(true)]);
  };

  const openWebClass = () => {
    updateState([() => setWebClass(true)]);
  };

  const openPacotes = () => {
    updateState([() => setStudentPacotes(true)]);
  };

  const openMaterialPageLimited = () => {
    updateState([() => setMateriaisOpen(true)]);
  };

  const openMatriculas = () => {
    updateState([() => setMatriculas(true)]);
  };

  const openSchedules = () => {
    updateState([() => setSchedules(true)]);
  };

  const deliveryTexts = () => {
    resetState();
    const url = "https://nucleo-de-redacao.ecorrecao.com.br/student/login";
    window.open(url, "_blank");
  };

  const openMaterialsPage = () => {
    resetState();
    const url = "https://aluno.eliasancelmo.com.br/";
    window.open(url, "_blank");
  };

  const openSimuladosPage = () => {
    resetState();
    const url =
      "https://app.minhaprova.com.br/student/login?code=elias-ancelmo";
    window.open(url, "_blank");
  };

  const openSimuladoPageLimited = () => {
    updateState([() => setSimuladosOpen(true)]);
  };

  const backHome = () => {
    resetState();
  };

  const changePhoto = () => {
    updateState([() => setUploadPhotoPerfil(true)]);
  };

  const navigationIcons = [
    {
      label: "Página Inicial",
      icon: <IoHome />,
      active: true,
      click: backHome,
    },
    {
      label: "Matricule-se aqui",
      icon: <FaUserPlus />,
      active: isFirstAccess ? true : false,
      click: openMatriculas,
    },
    {
      label: "Plataforma dos Materiais Antiga",
      icon: <PiStudentFill />,
      active: false,//!isFirstAccess && isPaymentedMatricula ? true : false,
      subitems: [
        {
          label: "Acesso limitado",
          icon: <PiStudentFill />,
          active: true,
          click: openMaterialPageLimited,
        },
        {
          label: "Acesso completo",
          icon: <PiStudentFill />,
          active: true,
          click: openMaterialsPage,
        },
      ],
    },
    {
      label: "Plataforma dos Materiais",
      icon: <PiStudentFill />,
      active: !isFirstAccess && isPaymentedMatricula ? true : false,
      click: openWebClass,
    },
    {
      label: "Plataforma dos Simulados",
      icon: <PiStudentDuotone />,
      active: !isFirstAccess && isPaymentedMatricula ? true : false,
      subitems: [
        {
          label: "Acesso limitado",
          icon: <PiStudentDuotone />,
          active: true,
          click: openSimuladoPageLimited,
        },
        {
          label: "Acesso completo",
          icon: <PiStudentDuotone />,
          active: true,
          click: openSimuladosPage,
        },
      ],
    },
    {
      label: "Entrega de Redações",
      icon: <TfiWrite />,
      active: !isFirstAccess && isPaymentedMatricula ? true : false,
      click: deliveryTexts,
    },
    {
      label: "Agende seu atendimento",
      icon: <GrScheduleNew />,
      active: !isFirstAccess && isPaymentedMatricula ? true : false,
      click: openSchedules,
    },
    {
      label: "Histórico Financeiro",
      icon: <GiPayMoney />,
      active: !isFirstAccess ? true : false,
      click: openPayments,
    },
    {
      label: "Pacotes de redação",
      icon: <GiPapers />,
      active: true,
      click: openPacotes,
    },
    {
      label: "Sair",
      icon: <BiLogOutCircle />,
      active: true,
      click: handleLogout,
    },
    // Adicione mais objetos conforme necessário
  ];

  const handleItemClick = (
    index: any,
    clickFunction: any,
    hasSubitems: boolean
  ) => {
    if (expandedItem === index) {
      setExpandedItem(null);
    } else {
      if (hasSubitems) {
        clickFunction();
      } else {
        setExpandedItem(index);
      }
    }
  };

  const DrawerList = (
    <>
      <Box
        sx={{
          width: drawerWidth,
          position: "relative",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        role="presentation"
      >
        <DrawerHeader
          style={{
            flexDirection: "column",
            justifyContent: "center",
            marginBottom: open ? "20px" : 0,
            width: "100%",
            marginTop: "20px",
          }}
        >
          {open && (
            <>
              <div
                style={{
                  width: "100%",
                  justifyContent:
                    theme.direction === "rtl" ? "flex-start" : "flex-end",
                  display: "flex",
                  position: "relative",
                }}
              >
                <IconButton
                  onClick={toggleDrawer(false)}
                  sx={{ color: "#007b9c" }}
                >
                  <AiOutlineClose />
                </IconButton>
              </div>

              <div
                className="header-img-container"
                onClick={changePhoto}
                style={{ cursor: "pointer" }}
              >
                <img
                  src={userPhoto}
                  alt="Imagem do usuário"
                  style={{
                    width: "120px",
                    height: "120px",
                    marginBottom: "20px",
                  }}
                  className="header-img"
                />
                <p
                  className="header-name"
                  style={{ color: "black", fontSize: "16px" }}
                >{`Olá, ${primeiroNome}`}</p>
              </div>
            </>
          )}
        </DrawerHeader>
        <Divider />
        <div
          style={{ overflowY: "auto", overflowX: "hidden", maxHeight: "50%" }}
        >
          <List>
            {navigationIcons.map((item: any, index) => (
              <Fragment key={index}>
                {item.active && (
                  <Fragment>
                    <ListItem disablePadding sx={{ display: "block" }}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: "initial",
                          px: 2.5,
                        }}
                        onClick={() =>
                          handleItemClick(index, item.click, !item.subitems)
                        }
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: open ? 3 : "auto",
                            justifyContent: "center",
                            color: "#007b9c",
                          }}
                        >
                          {item.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={item.label}
                          sx={{ opacity: open ? 1 : 0, color: "#007b9c" }}
                        />
                      </ListItemButton>
                    </ListItem>
                    {expandedItem === index && item.subitems && (
                      <Accordion>
                        <AccordionDetails
                          sx={{ padding: open ? "8px 16px 16px" : 0 }}
                        >
                          <List>
                            {item.subitems.map(
                              (subitem: any, subindex: number) => (
                                <ListItem
                                  key={subindex}
                                  disablePadding
                                  sx={{ display: "block" }}
                                >
                                  <ListItemButton
                                    onClick={subitem.click}
                                    sx={{
                                      minHeight: 48,
                                      justifyContent: "initial",
                                      px: 2.5,
                                    }}
                                  >
                                    <ListItemIcon
                                      sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : "auto",
                                        justifyContent: "center",
                                        color: "#007b9c",
                                      }}
                                    >
                                      {subitem.icon}
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={subitem.label}
                                      sx={{
                                        opacity: open ? 1 : 0,
                                        color: "#007b9c",
                                      }}
                                    />
                                  </ListItemButton>
                                </ListItem>
                              )
                            )}
                          </List>
                        </AccordionDetails>
                      </Accordion>
                    )}
                  </Fragment>
                )}
              </Fragment>
            ))}
          </List>
        </div>

        <Divider />

        {open && (
          <div className="header-img-container">
            <img
              src={nucleoBrand}
              alt="Imagem da marca"
              style={{
                width: "150px",
                height: "130px",
                marginBottom: "20px",
                marginTop: "20px",
              }}
            />
          </div>
        )}
      </Box>
    </>
  );

  const homePage = (
    <>
      {!uploadPhotoPerfil &&
        !studentPayment &&
        !studentPacotes &&
        !materiaisOpen &&
        !simuladosOpen &&
        !matriculas &&
        !schedules && !webClass && (
          <div style={{ height: "100%", minHeight: "100vh" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img src={elias} alt="elias foto" width={isMobile ? 340 : 600} />
              <div style={{ width: "100%", maxWidth: "1200px" }}>
                <p style={{ color: "white", textAlign: "justify" }}>
                  É com imensa alegria que dou as boas-vindas ao espaço
                  especialmente criado para vocês, meus estimados estudantes
                  do Núcleo de Redação Elias Ancelmo. Este ambiente foi
                  concebido com carinho para promover a excelência de nosso
                  trabalho e o crescimento mútuo de nossa equipe. Que este seja
                  um lugar de inspiração e realização de grandes
                  conquistas!
                </p>
                <p style={{ color: "white", textAlign: "justify" }}>
                  Para garantir que todas as suas necessidades sejam atendidas
                  de forma rápida e eficiente, colocamos à disposição uma ampla
                  gama de serviços no lado esquerdo ou no canto superior
                  esquerdo da tela. Estamos aqui para ajudá-los a alcançar todo o seu
                  potencial. Sintam-se à vontade para explorar e utilizar esses
                  recursos sempre que necessário. Estamos aqui para tornar sua
                  experiência no Núcleo de Redação Elias Ancelmo ainda mais
                  gratificante e produtiva.
                </p>
              </div>
              <img src={logo} alt="elias logo" width={250} />
            </div>
          </div>
        )}
    </>
  );

  const handleCloseModal = () => {
    setShowModal(false);
    setShowPaymentModal(false);
  };

  return (
    <div className="drawer-container">
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer(true)}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <HiMenu />
            <Typography variant="h6" noWrap component="span" sx={{ ml: 1 }}>
              Serviços
            </Typography>
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Núcleo de Redação - Elias Ancelmo
          </Typography>
        </Toolbar>
      </AppBar>

      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>

      {/* PAGES DE CONTEÚDOS */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: open ? `calc(100vw - ${2 * drawerWidth}px)` : "100vw",
          ml: open ? 3 : 0,
        }}
      >
        <DrawerHeader />

        {uploadPhotoPerfil && (
          <StorageUserImageComponent uid={props.user.uid} />
        )}

        {studentPayment && (
          <StudentPayment
            state={{
              role: props.role,
              cursos: props.curso,
              user: props.user,
              userFullData: studentFullData,
            }}
          />
        )}

        {matriculas && (
          <StudentSignup
            state={{
              role: props.role,
              cursos: props.curso,
              user: props.user,
              userFullData: studentFullData,
            }}
          />
        )}

        {schedules && (
          <StudentAgendamentos
            state={{
              role: props.role,
              cursos: props.curso,
              user: props.user,
              userFullData: studentFullData,
            }}
          />
        )}

{webClass && (
          <StudentWebClass
            state={{
              role: props.role,
              cursos: props.curso,
              user: props.user,
              userFullData: studentFullData,
            }}
          />
        )}

        {homePage}

        {materiaisOpen && (
          <div style={{ width: "100%", margin: "0 auto" }}>
            <iframe
              title="Materiais Elias Ancelmo"
              width="100%"
              height="1000px"
              src="https://eliasancelmo.com.br/ead/areadoaluno/login/index.php"
              allowFullScreen
              sandbox="allow-scripts allow-same-origin allow-forms allow-downloads allow-top-navigation allow-modals allow-popups allow-popups-to-escape-sandbox"
              style={{ borderRadius: "10px" }}
            ></iframe>
          </div>
        )}

        {simuladosOpen && (
          <div style={{ width: "100%", margin: "0 auto" }}>
            <iframe
              title="Materiais Elias Ancelmo"
              width="100%"
              height="1000px"
              src="https://app.minhaprova.com.br/student/login?code=elias-ancelmo"
              allowFullScreen
              sandbox="allow-scripts allow-same-origin allow-forms allow-downloads allow-top-navigation allow-modals allow-popups allow-popups-to-escape-sandbox"
              style={{ borderRadius: "10px" }}
            ></iframe>
          </div>
        )}

        <div>
          {showModal && (
            <AlertModal
              message="Seja bem-vindo! Percebi que é seu primeiro acesso, efetue sua matrícula para concluir seu cadastro e liberar acesso ao seu espaço."
              onClose={handleCloseModal}
            />
          )}
        </div>

        <div>
          {showPaymentModal && (
            <AlertModal
              message="Percebi que você ainda não efetuou seu pagamento! Para ter acesso completo ao ambiente do Professor Elias Ancelmo é necessário primeiro confirmar seu pagamento. Caso já tenha efetuado o pagamento, favor entrar em contato com nossa secretária."
              onClose={handleCloseModal}
            />
          )}
        </div>

        <div>
          {studentPacotes && (
            <StudentPacotes aluno={studentFullData} valor={1} />
          )}
        </div>
      </Box>
    </div>
  );
}
