import { auth, firestore as db } from '../database/firebaseConfig';

export const signUp = async (email: string, password: string, role: string, isFirstAccess: boolean) => {
  try {
    // Crie o usuário no Firebase Authentication
    const userCredential = await auth.createUserWithEmailAndPassword(email, password);

    // Acesse o UID do usuário recém-criado
    const uid = userCredential.user?.uid;

    // Adicione o usuário ao banco de dados Firestore com o campo 'role'
    if (uid) {
      await db.collection('users').doc(uid).set({
        email: email,
        role: role,
        isFirstAccess: isFirstAccess,
        uid
        // outros campos específicos do usuário, se houver
      });

      // Agora, vamos recuperar os dados do usuário para verificar o papel
      const userSnapshot = await db.collection('users').doc(uid).get();
      const userData = userSnapshot.data();

      // Verifique o papel do usuário
      if (userData?.role === 'adm') {
        console.log('O usuário é um administrador.');
        // Faça algo específico para administradores
      } else {
        console.log('O usuário é um estudante.');
        // Faça algo específico para alunos
      }

      // console.log('User registered successfully!');
    } else {
      console.error('Falha ao obter o UID do usuário recém-criado.');
    }
  } catch (error: any) {
    console.error('Erro ao registrar usuário: ', error.message);
  }
};


export const updatePassword = async (uid: string, newPassword: string) => {
  try {
    await auth.currentUser?.updatePassword(newPassword);
    console.log('Senha atualizada com sucesso!');
  } catch (error: any) {
    console.error('Erro ao atualizar a senha: ', error.message);
    throw error;
  }
};


export const updateUserData = async (uid: string, newData: { email?: string, role?: string }) => {
  try {
    await db.collection('users').doc(uid).update(newData);
    console.log('Dados do usuário atualizados com sucesso!');
  } catch (error: any) {
    console.error('Erro ao atualizar os dados do usuário: ', error.message);
    throw error;
  }
};

export const updateIsFirstAccess = async (uid: string, isFirstAccess: boolean) => {
  try {
    await db.collection('users').doc(uid).update({
      isFirstAccess: isFirstAccess
    });

    //console.log('isFirstAccess updated successfully!');
  } catch (error: any) {
    console.error('Erro ao atualizar isFirstAccess:', error.message);
    throw error;
  }
};


export const signUpAdms = async (email: string, password: string, role: string, isFirstAccess: boolean, name: string, telefone: string) => {
  try {
    // Crie o usuário no Firebase Authentication
    const userCredential = await auth.createUserWithEmailAndPassword(email, password);

    // Acesse o UID do usuário recém-criado
    const uid = userCredential.user?.uid;

    // Adicione o usuário ao banco de dados Firestore com o campo 'role'
    if (uid) {
      await db.collection('users').doc(uid).set({
        email: email,
        role: role,
        isFirstAccess: isFirstAccess,
        uid,
        name,
        telefone
        // outros campos específicos do usuário, se houver
      });

      // Agora, vamos recuperar os dados do usuário para verificar o papel
      const userSnapshot = await db.collection('users').doc(uid).get();
      const userData = userSnapshot.data();

      // Verifique o papel do usuário
      if (userData?.role === 'adm') {
        console.log('O usuário é um administrador.');
        // Faça algo específico para administradores
      } else {
        console.log('O usuário é um secretário ou corretor.');
        // Faça algo específico para alunos
      }

      // console.log('User registered successfully!');
    } else {
      console.error('Falha ao obter o UID do usuário recém-criado.');
    }
  } catch (error: any) {
    console.error('Erro ao registrar usuário: ', error.message);
  }
};

export const signUpWithValidate = async (email: string, password: string, role: string, isFirstAccess: boolean) => {
  try {
    // Crie o usuário no Firebase Authentication
    const userCredential = await auth.createUserWithEmailAndPassword(email, password);

    // Acesse o UID do usuário recém-criado
    const user = userCredential.user;

    // Verifique se o usuário está disponível
    if (user) {
      // Envie e-mail de verificação
      await user.sendEmailVerification();

      // Adicione o usuário ao banco de dados Firestore com o campo 'role'
      await db.collection('users').doc(user.uid).set({
        email: email,
        role: 'student',
        isFirstAccess: isFirstAccess,
        uid: user.uid
        // outros campos específicos do usuário, se houver
      });

      console.log('Email de verificação enviado para:', email);
    } else {
      console.error('Falha ao obter o usuário recém-criado.');
    }
  } catch (error: any) {
    console.error('Erro ao registrar usuário: ', error.message);
  }
};
