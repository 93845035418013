import { TurmaOptionData } from "../interfaces/Turmas";

export const obterOpcoesTurma = (cidade: string) => {
  const opcoesTurmaData = [
    {
      value: "LT1PMPE",
      label:
        "Carreiras Policiais - PRESENCIAL LIMOEIRO - Sábados, às 8h - REDAÇÃO PMPE/BMPE/PRAÇA/POLÍCIA CIVIL",
      disabled: true,
      pay: { formattedValue: "R$ 300,00", value: 300 },
    },
    {
      value: "ONPMPE",
      label:
        "Carreiras Policiais - ONLINE - REDAÇÃO PMPE/BMPE/PRAÇA/POLÍCIA CIVIL",
      disabled: true,
      pay: { formattedValue: "R$ 240,00", value: 240 },
    },
    {
      value: "LT1PCPE",
      label:
        "Carreiras Policiais - PRESENCIAL LIMOEIRO - Sábados, às 10h - 12h - REDAÇÃO POLÍCIA CIVIL",
      disabled: cidade === "Limoeiro" ? false : true,
      pay: { formattedValue: "R$ 500,00", value: 500 },
    },
    {
      value: "ONPCPE",
      label:
        "Carreiras Policiais - ONLINE - REDAÇÃO POLÍCIA CIVIL",
      disabled: true,
      pay: { formattedValue: "R$ 240,00", value: 240 },
    },
    {
      value: "LT1",
      label:
        "Núcleo de Redação - Online - Turma 1 - LIMOEIRO - Quartas, das 18h30 às 20h30",
      disabled: cidade === "Limoeiro" ? false : true,
      pay: { formattedValue: "R$ 120,00", value: 120 },
    },
    {
      value: "LT2",
      label:
        "Núcleo de Redação - Turma 2 - LIMOEIRO - Quintas, das 18h30 às 20h30",
      disabled: cidade === "Limoeiro" ? false : true,
      pay: { formattedValue: "R$ 120,00", value: 120 },
    },
    {
      value: "LT3",
      label:
        "Núcleo de Redação - Turma 3 - LIMOEIRO - Sábados, das 7:30h às 9:30h",
      disabled: cidade === "Limoeiro" ? false : true,
      pay: { formattedValue: "R$ 120,00", value: 120 },
    },
    {
      value: "LT1COMBOHA",
      label:
        "COMBO 1: Núcleo de Redação + Humanas + Atualidades - Turma 1 - LIMOEIRO - Quartas, das 18h30 às 22h",
      disabled: cidade === "Limoeiro" ? false : true,
      pay: { formattedValue: "R$ 200,00", value: 200 },
    },
    {
      value: "LT2COMBOHA",
      label:
        "COMBO 1: Núcleo de Redação + Humanas + Atualidades - Turma 2 - LIMOEIRO - Sábados, das 7:30h às 12:30h",
      disabled: cidade === "Limoeiro" ? false : true,
      pay: { formattedValue: "R$ 200,00", value: 200 },
    },
    {
      value: "LT1HA",
      label:
        "COMBO 2: Humanas + Atualidades - Turma 1 - LIMOEIRO - Quartas, das 20:30h às 22h",
      disabled: cidade === "Limoeiro" ? false : true,
      pay: { formattedValue: "R$ 100,00", value: 100 },
    },
    {
      value: "LT2HA",
      label:
        "COMBO 2: Humanas + Atualidades - Turma 2 - LIMOEIRO - Sábados, das 9:45h às 12:30h",
      disabled: cidade === "Limoeiro" ? false : true,
      pay: { formattedValue: "R$ 100,00", value: 100 },
    },
    {
      value: "VT1",
      label: "Núcleo de Redação - Turma 1 - VITÓRIA - Segundas, das 19h às 21h",
      disabled: cidade === "Vitoria" ? false : true,
      pay: { formattedValue: "R$ 120,00", value: 120 },
    },
    {
      value: "VT2",
      label: "Núcleo de Redação - Turma 2 - VITÓRIA - Terças, das 19h às 21h",
      disabled: cidade === "Vitoria" ? false : true,
      pay: { formattedValue: "R$ 120,00", value: 120 },
    },
    {
      value: "ST1",
      label: "Núcleo de Redação - Turma 1 - SURUBIM - Sábados, das 14h às 16h",
      disabled: cidade === "Surubim" ? false : true,
      pay: { formattedValue: "R$ 120,00", value: 120 },
    },
    {
      value: "DR1000",
      label:
        "Núcleo de Redação - ONLINE - MATRÍCULAS 2024 - Quintas, às 19h",
      disabled: false,
      pay: { formattedValue: "R$ 80,00", value: 80 },
    },
    // Adicione outras opções conforme necessário
  ];
  return opcoesTurmaData;
};

export const getCourse = (courseCode: string, cidade: string, cursosData: TurmaOptionData[]) => {
  const opcoesTurmaData = obterOpcoesTurma(cidade);

  const cursoEscolhido = cursosData.find(
    (option) => option.courseCode === courseCode
  );

  if (cursoEscolhido) {
    return cursoEscolhido.nomeDoCurso;
  } else {
    return "Curso não encontrado";
  }
};

export const getCourseValue = (courseCode: string, cidade: string, qtd: number, cursosData: TurmaOptionData[]): number => {
  const opcoesTurmaData = obterOpcoesTurma(cidade);

  const cursoEscolhido = cursosData.find(
    (option) => option.courseCode === courseCode
  );

  if (cursoEscolhido) {
    if (qtd === 1) {
      return cursoEscolhido.pay.value;
    } else if (qtd === 2) {
      return cursoEscolhido.pay.value * 2;
    } else if (qtd === 3){
      return cursoEscolhido.pay.value * 3;
    } else {
      return cursoEscolhido.pay.value;
    }
  } else {
    return 0;
  }
};


export const getCourseFormattedValue = (courseCode: string, cursosData: TurmaOptionData[]): string => {

  const cursoEscolhido = cursosData.find(
    (option) => option.courseCode === courseCode
  );

  return cursoEscolhido ? cursoEscolhido?.pay.formattedValue  : '';
};


export const getCourseData = (courseCode: string, cursosData: TurmaOptionData[]): TurmaOptionData | {} => {

  const cursoEscolhido = cursosData.find(
    (option) => option.courseCode === courseCode
  );

  return cursoEscolhido ? cursoEscolhido  : {};
};
