import React from 'react';
import { IframeProps } from '../interfaces/PropsModels';

const IframePayment = ({ src, width, height, title }: IframeProps) => {
  return (
    <iframe
      title={title}
      width={width}
      height={height}
      src={src}
      allowFullScreen
    ></iframe>
  );
};

export default IframePayment;
