import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { auth } from "../database/firebaseConfig";
import loading from "../images/home/background-default.gif";
import { StudentData } from "../interfaces/Students";
import {
  getStudentFirestoreByCpfOrEmail,
  updateStudentPaymentFirestore,
} from "../database/firestore";
import AdmHeader from "../components/AdmHeader";
import AlertModal from "../components/AlertModal";
import "../styles/AdmsPage.css";
import "../styles/AdmPayments.css";
import { signOut } from "../services/SingOut";

const AdmsPayments = (props: {state: any;}) => {
  const navigate = useNavigate();
  const { state } = props;
  const role = state?.role;
  const imgUrl = state?.imgUrl ? state?.imgUrl : loading;

  const [searchType, setSearchType] = useState<"cpf" | "email">("cpf");
  const [searchValue, setSearchValue] = useState("");
  const [studentData, setStudentData] = useState<StudentData | any>(null);
  const [showModal, setShowModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState("");

  const mesMatriculado = parseInt(studentData ? studentData.created.split("-")[1] : "01", 10);
  const mesesDoAno: any = {
    janeiro: 1,
    fevereiro: 2,
    marco: 3,
    abril: 4,
    maio: 5,
    junho: 6,
    julho: 7,
    agosto: 8,
    setembro: 9,
    outubro: 10,
    novembro: 11,
    dezembro: 12,
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setShowMessageModal("");
  };

  const handleSearch = async () => {
    try {
      const result = await getStudentFirestoreByCpfOrEmail(
        searchType,
        searchValue
      );
      setStudentData(result);
    } catch (error) {
      console.error("Erro ao buscar estudante:", error);
      setShowMessageModal(`Erro ao buscar estudante: ${error}`);
      setShowModal(true);

      setStudentData(null); // Limpar os dados do aluno se não for encontrado
    }
  };

  const handleCheckboxChange = async (month: string, isChecked: boolean) => {
    if (studentData) {
      try {
        await updateStudentPaymentFirestore(studentData.id, month, isChecked, mesesDoAno[month], mesMatriculado);
        // Atualizar o estado local para refletir a alteração sem fazer uma nova consulta ao Firestore
        setStudentData((prevData: any) => ({
          ...prevData,
          [`${month}Pay`]: isChecked,
        }));
        setShowMessageModal(
          `Pagamento de ${month} atualizado na base de dados com sucesso!`
        );
        setShowModal(true);
      } catch (error) {
        console.error(
          `Erro ao atualizar pagamento de ${month} do estudante:`,
          error
        );
        setShowMessageModal(
          `Erro ao atualizar pagamento de ${month} do estudante:`
        );
        setShowModal(true);
      }
    }
  };

  const handleClear = () => {
    setSearchType("cpf");
    setSearchValue("");
    setStudentData(null);
  };

  const months = [
    "janeiro",
    "fevereiro",
    "marco",
    "abril",
    "maio",
    "junho",
    "julho",
    "agosto",
    "setembro",
    "outubro",
    "novembro",
    "dezembro",
  ];

  const dateBuilder = (dateStr: string): string => {
    const dataMatriculado = dateStr.split("-");
    return `${dataMatriculado[2]}/${dataMatriculado[1]}/${dataMatriculado[0]}`;
  }

  return (
    <div className="adms-page">
      {/* <AdmHeader isFirstPage={false} nome={`Bem-vindo ${role}`} /> */}

      <div className="card-container">
        <select
          value={searchType}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            setSearchType(e.target.value as "cpf" | "email")
          }
          className="adm-input-field adm-input-field-selected"
        >
          <option value="cpf">CPF</option>
          <option value="email">Email</option>
        </select>

        {searchType === "cpf" ? (
          <input
            type="text"
            value={searchValue}
            onChange={(e) =>
              setSearchValue(e.target.value.replace(/\D/g, "").slice(0, 11))
            }
            placeholder="Apenas números"
            className="adm-input-field"
            onKeyDown={handleSearch}
          />
        ) : (
          <input
            type="email"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="Digite o email"
            className="adm-input-field"
          />
        )}

        <button onClick={handleSearch} className="btn btn-primary adm-button">
          Buscar
        </button>
        <button onClick={handleClear} className="btn btn-secondary adm-button">
          Limpar
        </button>

        {studentData && (
          <div className="result-card">
            <h2 style={{ width: "100%", textAlign: "left", margin: "10px 0", fontWeight: 'bold' }}>Dados do Aluno</h2>
            <div className="table-container">
              <table>
                <tbody>
                  <tr>
                    <td>Nome:</td>
                    <td>{studentData.name}</td>
                  </tr>
                  <tr>
                    <td>CPF: </td>
                    <td>{studentData.cpf}</td>
                  </tr>
                  <tr>
                    <td>Telefone: </td>
                    <td>{studentData.celularAluno}</td>
                  </tr>
                  <tr>
                    <td>Curso Matriculado: </td>
                    <td>{studentData.cursoMatriculado}</td>
                  </tr>
                  <tr>
                    <td>Nome do Responsável: </td>
                    <td>{studentData.responsavel}</td>
                  </tr>
                  <tr>
                    <td>Telefone do Responsável: </td>
                    <td>{studentData.celularResponsavel}</td>
                  </tr>
                  <tr>
                    <td>Data da Matrícula: </td>
                    <td>{dateBuilder(studentData.created)}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <h2
              style={{ width: "100%", textAlign: "center", margin: "30px 0", fontWeight: 'bold' }}
            >
              Confirme os pagamentos do aluno na tabela abaixo
            </h2>
            <div className="payments">
              {months.map((month: string) => (
                <div key={month} className="payment-item">
                  <label
                    style={{
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      fontSize: "18px",
                    }}
                  >
                    {mesesDoAno[month] === mesMatriculado
                    ? "MATRÍCULA"
                    : (month === 'marco' ? 'MARÇO' :  month.charAt(0).toUpperCase() + month.slice(1))}
                  </label>
                  <input
                    type="checkbox"
                    checked={studentData[`${month}Pay`]}
                    onChange={(e) =>
                      handleCheckboxChange(month, e.target.checked)
                    }
                    disabled={mesesDoAno[month] < mesMatriculado}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      {showModal && showMessageModal && (
        <AlertModal message={showMessageModal} onClose={handleCloseModal} />
      )}
    </div>
  );
};

export default AdmsPayments;
