import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getFileURL } from "../database/storage";
import { forgotPassword, signIn } from "../services/SingIn";
import { signUp } from "../services/SingUp";
import { FaEye, FaEyeSlash, FaUser } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/LoginPage.css";
import AlertModal from "../components/AlertModal";
import RecaptchaComponent from "../components/RecaptchaComponent ";
import logoMarca from "../images/orlam-solutions/Imagem6.png";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();
  const [logo, setLogo] = useState("");
  //const [logoMarca, setLogoMarca] = useState("");
  const [imgLoading, setImgLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [showButtonForgot, setShowButtonForgot] = useState(false);
  const [recaptcha, setRecaptcha] = useState(false);

  useEffect(() => {
    // Carrega a imagem de fundo ao montar o componente
    const loadBackgroundImage = async () => {
      try {
        // const imageUrl = await getFileURL("login-page/background");
        let imageUrl = await getFileURL("login-page/background");
        // Aplique a URL no CSS
        document.documentElement.style.setProperty(
          "--background-image",
          "url(" + imageUrl + ")"
        );

        let imageUrlMobile = await getFileURL("login-page/background-mobile");
        document.documentElement.style.setProperty(
          "--background-image-mobile",
          "url(" + imageUrlMobile + ")"
        );

        const imageLogoUrl = await getFileURL("login-page/logotipo");
        //const imageLogoMarcaUrl = await getFileURL("login-page/Imagem1.png");

        setLogo(imageLogoUrl);
        //setLogoMarca(imageLogoMarcaUrl);
        setImgLoading(true);
      } catch (error) {
        console.error("Erro ao carregar a imagem de fundo:", error);
      }
    };

    // Carrega a imagem de fundo ao montar o componente
    loadBackgroundImage();
  }, []);

  const handleSignUpFirstStep = async () => {
    setShowButton(true);
  };

  const handleSignUp = async () => {
    try {
      await signUp(email, password, "student", true);
      // SignUp successful, update the state with success message
      setSuccessMessage("Cadastro realizado com sucesso!");
      setShowModal(true);
      setShowButton(false);
      // Optionally, clear the email and password fields after successful signup
      setEmail("");
      setPassword("");

      // Automatically sign in after successful signup
      handleSignIn("signup");
    } catch (error: any) {
      setError(error.message);
      setShowModal(true);
    }
  };

  const handleForgotPassword = async () => {
    const response = await forgotPassword(email);
    if (response === 200) {
      setSuccessMessage(
        "Email enviado com sucesso! Caso não tenha recebido verifique a caixa de spam ou entre em contato com nossa equipe"
      );
      setShowModal(true);
    } else {
      setError("Email não existe na nossa base de dados!");
      setShowModal(true);
    }
  };

  const handleSignIn = async (type: string) => {
    try {
      const dataUser = await signIn(email, password);
      // SignIn successful, proceed with redirect
      if (dataUser) {
        if (dataUser?.userRole === "student") {
          navigate(`/app/${dataUser?.userRole}s/logged`, {
            state: {
              role: dataUser?.userRole,
              uid: dataUser?.uid,
              email: dataUser?.email,
              signup: type === "signup" ? true : false,
              userData: dataUser,
            },
          });
        } else {
          navigate(`/app/adms/${type}`, {
            state: {
              role: dataUser?.userRole,
              uid: dataUser?.uid,
              email: dataUser?.email,
              userData: dataUser,
            },
          });
        }
        /*
        type === "signup"
          ? navigate(`/app/${dataUser?.userRole}s/${type}`, {
              state: {
                role: dataUser?.userRole,
                uid: dataUser?.uid,
                email: dataUser?.email,
              },
            })
          : navigate(`/app/${dataUser?.userRole}s/${type}`, {
              state: {
                role: dataUser?.userRole,
                uid: dataUser?.uid,
                email: dataUser?.email,
              },
            });*/
      } else {
        setError(
          "Usuário não encontrado, entre em contato com a secretaria do curso!"
        );
        setShowModal(true);
      }
    } catch (error: any) {
      setError(
        "Erro ao fazer login. Usuário e senha incorretos ou não existe na base de dados."
      );
      setShowModal(true);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSuccessMessage("");
    setError("");
  };

  const isEmailValid = (email: string) => {
    // Use a regular expression to check if the email is valid
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const notIsRobot = (value: string) => {
    if (value) {
      setRecaptcha(true);
    } else {
      setRecaptcha(false);
    }
  };

  return (
    <div className="login-page">
      {imgLoading && (
        <div className="signup-login">
          <div className="signup-form">
            <img src={logo} alt="logotipo" className="signup-logotipo" />
            <form className="form-signup was-validate">
              <div className="input-group mb-3">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  pattern="[^\s@]+@[^\s@]+\.[^\s@]+"
                  title="Insira um email válido!"
                />
                <div className="input-group-append">
                  <span className="input-group-text user-icon">
                    <FaUser />
                  </span>
                </div>
              </div>
              {!showButtonForgot && (
                <div className="input-group mb-3">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    placeholder="Senha"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <div className="input-group-append">
                    <span
                      className="input-group-text"
                      onClick={toggleShowPassword}
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                  </div>
                </div>
              )}

              <div className="buttons-container">
                {!recaptcha && (
                  <p
                    style={{
                      fontSize: "10px",
                      textAlign: "center",
                      color: "red",
                    }}
                  >
                    Marque não sou um robô para habilitar os botões
                  </p>
                )}

                {!showButton ? (
                  <>
                    {!showButtonForgot && (
                      <>
                        <button
                          className="btn btn-primary button-login"
                          type="button"
                          onClick={() => handleSignIn("logged")}
                          disabled={
                            !isEmailValid(email) ||
                            password === "" ||
                            !recaptcha
                          }
                        >
                          ENTRAR
                        </button>
                        <button
                          className="btn btn-warning button-forgot"
                          type="button"
                          onClick={() => setShowButtonForgot(true)}
                          disabled={!recaptcha}
                        >
                          ESQUECI A SENHA
                        </button>
                        <button
                          className="btn btn-success button-signup"
                          type="button"
                          onClick={handleSignUpFirstStep}
                        >
                          CADASTRE-SE
                        </button>
                      </>
                    )}
                    {showButtonForgot && (
                      <>
                        <button
                          className="btn btn-warning button-forgot"
                          type="button"
                          onClick={handleForgotPassword}
                          disabled={!isEmailValid(email) || !recaptcha}
                        >
                          ENVIAR NOVA SENHA
                        </button>

                        <button
                          className="btn btn-success button-signup-cadastro-back"
                          type="button"
                          onClick={() => setShowButtonForgot(false)}
                        >
                          VOLTAR
                        </button>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <button
                      className="btn btn-success button-signup-cadastro"
                      type="button"
                      onClick={handleSignUp}
                      disabled={
                        !isEmailValid(email) || password === "" || !recaptcha
                      }
                    >
                      CONCLUIR CADASTRO
                    </button>

                    <button
                      className="btn btn-success button-signup-cadastro-back"
                      type="button"
                      onClick={() => setShowButton(false)}
                    >
                      VOLTAR
                    </button>
                  </>
                )}
              </div>
            </form>

            <RecaptchaComponent getToken={notIsRobot} />

            {showModal && successMessage && (
              <AlertModal message={successMessage} onClose={handleCloseModal} />
            )}

            {showModal && error && (
              <AlertModal message={error} onClose={handleCloseModal} />
            )}
          </div>

          <div className="signup-logomarca">
            <div className="google-play-container">
              <a href="https://play.google.com/store/apps/details?id=com.rafaelxulipa.nucleoderedacaoappreactnative&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                <img
                  alt="Disponível no Google Play"
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/pt-br_badge_web_generic.png"
                  width={120}
                  className="google-play"
                />
              </a>
            </div>
            <a
              href="https://portfolio.or.app.br/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={logoMarca} alt="logomarca" className="logomarca" />
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
