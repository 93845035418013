import React, { useState } from "react";
import { FaCheck, FaBarcode, FaTimes } from "react-icons/fa";
import "../styles/MonthlyPaymentStatus.css";
import AlertModal from "./AlertModal";
import StudentFirstPayments from "../pages/StudentFirstPayments";

const MonthlyPaymentStatus = ({ studentData, state }: any) => {
  const [showModal, setShowModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");


  const mesMatriculado = parseInt(studentData.created.split("-")[1], 10);
  const mesesDoAno: any = {
    janeiro: 1,
    fevereiro: 2,
    marco: 3,
    abril: 4,
    maio: 5,
    junho: 6,
    julho: 7,
    agosto: 8,
    setembro: 9,
    outubro: 10,
    novembro: 11,
    dezembro: 12,
  };

  const handlePaymentClick = (month: string) => {
    const monthIndex = mesesDoAno[month.toLowerCase()];
    if (!studentData[`${month}Pay`]) {
      if (studentData.pagamentoMatricula && monthIndex === mesMatriculado) {
        // console.log("Você já efetuou o pagamento!");
        setSuccessMessage("Você já efetuou o pagamento deste mês!");
        setShowModal(true);
      } else {
        if (monthIndex < mesMatriculado) {
          // console.log("Estes pagamentos foram cancelados!");
          setSuccessMessage("Estes pagamentos foram cancelados, pois você não estava matriculado neste mês!");
          setShowModal(true);
        } else {
          // navigate("/app/student/logged/payment");
          // TODO: navigate to payment page
          if (monthIndex === mesMatriculado) {
            // navigate(`/app/students/signup/payment`, {
            //   state: { student: studentData, imgUrl, isLogged: true, typePayment: "", cursoData: cursoData },
            // });
            setPaymentStatus("signup");
          } else {
            // navigate(`/app/students/signup/payment`, {
            //   state: { student: studentData, imgUrl, isLogged: true, typePayment: "pix-mensalidade", cursoData: cursoData },
            // });

            setPaymentStatus("pix");
          }
        }
      }
    } else {
      // console.log("Você já efetuou o pagamento!");
      setSuccessMessage("Você já efetuou o pagamento deste mês!");
      setShowModal(true);
    }
  };

  const getIconAndText = (month: string) => {
    if (studentData[`${month}Pay`] === true) {
      return {
        icon: <FaCheck color="yellowgreen" />,
        text: "PAGO",
      };
    } else {
      const monthIndex = mesesDoAno[month.toLowerCase()];

      if (studentData.pagamentoMatricula && monthIndex === mesMatriculado) {
        return {
          icon: <FaCheck color="yellowgreen" />,
          text: "MATRICULADO",
        };
      } else {
        if (monthIndex < mesMatriculado) {
          return {
            icon: <FaTimes color="yellow" />,
            text: "CANCELADO",
          };
        } else {
          return {
            icon: <FaBarcode color="white" />,
            text: "PAGAR",
          };
        }
      }
    }
  };

  const renderMonthlyCards = () => {
    const months = [
      "janeiro",
      "fevereiro",
      "marco",
      "abril",
      "maio",
      "junho",
      "julho",
      "agosto",
      "setembro",
      "outubro",
      "novembro",
      "dezembro",
    ];

    const getLabelMonth = (month: string): string => {
      const monthIndex = mesesDoAno[month.toLowerCase()]
      if (monthIndex === mesMatriculado) {
        return 'MATRÍCULA'
      } else {
        return month === 'marco' ? 'MARÇO' : month.toUpperCase();
      }
    }

    return (
      <div className="monthly-cards-container">
        {months.map((month) => (
          <div
            key={month}
            className="monthly-card"
            onClick={() => handlePaymentClick(month)}
          >
            <div className="payment-card" style={{ background: "#ffffff58", borderRadius: '8px' }}>
              <div className="payment-card-header">
                <h5 style={{ color: "#fff" }}>
                  {
                    getLabelMonth(month)
                  }
              </h5>
              </div>
              {getIconAndText(month).icon}
              <p style={{ color: "#fff" }} className="payment-card-text">{getIconAndText(month).text}</p>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h2 style={{ color: "#fff", textAlign: 'center', width: '100%', marginBottom: '40px' }}>Acompanhamento das Mensalidades</h2>
      {paymentStatus === '' && renderMonthlyCards()}

      {showModal && successMessage && (
              <AlertModal message={successMessage} onClose={handleCloseModal} />
            )}

      {(paymentStatus === 'signup') && <StudentFirstPayments state={state} student={studentData} isLogged={true} typePayment="" backPage={() => setPaymentStatus('')} />}
      
      {(paymentStatus === 'pix') && <StudentFirstPayments state={state} student={studentData} isLogged={true} typePayment="pix-mensalidade" backPage={() => setPaymentStatus('')}/>}
    </div>
  );
};

export default MonthlyPaymentStatus;
