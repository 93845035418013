import React, { useEffect, useState } from "react";
import "../styles/AdmsCreateNewUsers.css";
import "../styles/AdmsCreateCourses.css";
import { getStudentsFirestore } from "../database/firestore";
import { StudentData } from "../interfaces/Students";
import DataTable from "../components/TableNew";
import { getCourse } from "../database/OptionsData";
import { TurmaOptionData } from "../interfaces/Turmas";

const AdmsReportsStudents = (props: {state: any;}) => {
  const { state } = props;
  const cursoData: TurmaOptionData[] = state?.cursos;

  const [students, setStudents] = useState<StudentData[]>([]);

  // Função para carregar os cursos ao montar o componente
  useEffect(() => {
    const loadStudents = async () => {
      try {
        const studentsData = await getStudentsFirestore();
        setStudents(studentsData);
      } catch (error) {
        console.error("Erro ao carregar cursos:", error);
      }
    };

    //Carrega apenas uma vez a chamada;
    loadStudents();
  }, []);

  const [selectedCourse, setSelectedCourse] = useState("");
  const [filteredStudents, setFilteredStudents] = useState(students);

  const uniqueCourses = Array.from(
    new Set(students.map((student) => student.cursoCode))
  );

  const handleChange = (event: any) => {
    const selectedCourseCode = event.target.value;
    setSelectedCourse(selectedCourseCode);

    if (selectedCourseCode === "") {
      setFilteredStudents(students);
    } else {
      const filtered = students.filter(
        (student) => student.cursoCode === selectedCourseCode
      );
      setFilteredStudents(filtered);
    }
  };

  return (
    <div className="adms-page-create">
      {/* <AdmHeader isFirstPage={false} nome={`Bem-vindo ${role}`} /> */}

      <h1 style={{ color: "#fff" }}>LISTA DOS MATRICULADOS</h1>

      <div
        style={{
          width: "100%",
          backgroundColor: "#fff",
          borderRadius: "8px",
          padding: "20px",
        }}
      >
        <label
          htmlFor="courseSelect"
          style={{ marginRight: "6px", fontWeight: "bold" }}
        >
          Escolha a turma para filtrar:
        </label>
        <select
          id="courseSelect"
          value={selectedCourse}
          onChange={handleChange}
          style={{ borderRadius: "4px", border: "1px solid #5c5c5c", width: "100%", maxWidth: '900px' }}
        >
          <option value="">Todos</option>
          {uniqueCourses.map((courseCode) => (
            <option key={courseCode} value={courseCode}>
              <span style={{fontWeight: 'bold'}}>{`Turma ${courseCode} - `}</span>{getCourse(courseCode, 'Limoeiro', cursoData)}
            </option>
          ))}
        </select>

        <p
          style={{
            fontSize: "24px",
            width: "100%",
            textAlign: "center",
            margin: "20px",
            fontWeight: "bold",
            color: "blue",
          }}
        >{`${filteredStudents.length} matriculados nessa turma selecionada`}</p>

        {/* DataTable usando os alunos filtrados */}
        <DataTable rows={filteredStudents} />
      </div>
    </div>
  );
};

export default AdmsReportsStudents;
