import React, { useEffect } from 'react';

const TypeBotStudent: React.FC = () => {
  useEffect(() => {
    const typebotInitScript = document.createElement("script");
    typebotInitScript.type = "module";
    typebotInitScript.innerHTML = `import Typebot from 'https://cdn.jsdelivr.net/npm/@typebot.io/js@0.2/dist/web.js'

    Typebot.initBubble({
      typebot: "nucleo-de-redacao",
      
      theme: { button: { backgroundColor: "#FFFFFF" } },
    });
    `;
    document.body.appendChild(typebotInitScript);
  }, []);

  // previewMessage: {
  //   message: "Posso ajudar em algo?",
  //   autoShowDelay: 30000,
  //   avatarUrl:
  //     "https://s3.typebot.io/public/workspaces/clvnu2o4r000647voeuez2uxx/typebots/clvnue2jy00018inr7wosn7xr/hostAvatar?v=1714570230096",
  // },

  return null;
};

export default TypeBotStudent;
