import React from "react";
import "../styles/StudentSignupPage.css";
import "../styles/StudentsPage.css";
import PaymentsComponent from "../components/PaymentsComponent";
import { StudentData } from "../interfaces/Students";
import '../styles/StudentFirstPayments.css'

const StudentFirstPayments = (props: {state: any; isLogged: boolean; typePayment: string; student: StudentData; backPage?: any }) => {
  const { state } = props;
  const studentData: StudentData = props.student;
  const isLogged = props.isLogged;
  const typePayment = props.typePayment;
  const cursoData = state?.cursos;

  const backPage = () => {
    props.backPage();
  }

  return (
    <div className="students-page-first-payment">
      <div className="first-payment-container">
        <PaymentsComponent 
          valor={studentData?.valorDoCurso} 
          valorFormatado={studentData?.valorDoCursoFormatted}
          birthday={studentData?.birthday}
          cursoCode={studentData?.cursoCode}
          nomeDoCurso={studentData?.cursoMatriculado}
          nomeDoaluno={studentData?.name}
          nomeDoResponsavel={studentData?.responsavel}
          cidade={studentData?.cidade}
          isLogged={isLogged}
          typePayment={typePayment}
          cursoData={cursoData}
          backPage={backPage}
        />
      </div>
    </div>
  );
};

export default StudentFirstPayments;
