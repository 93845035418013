import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import App from './App';
import Login from './pages/Login';
import Adms from './pages/Adms';
import Students from './pages/Students';
import StudentSignup from './pages/StudentSignup';
import StudentFirstPayments from './pages/StudentFirstPayments';
import StudentPayment from './pages/StudentPayment';
import StudentAgendamentos from './pages/StudentAgendamentos';
import StudentPacotes from './pages/StudentPacotes';
import AdmUpdateStudentData from './pages/AdmUpdateStudentData';
import AdmsPayments from './pages/AdmsPayments';
import AdmsCreateNewUsers from './pages/AdmsCreateNewUsers';
import AdmsPersonalizates from './pages/AdmsPersonalizates';
import AdmsCreateCourses from './pages/AdmsCreateCourses';
import AdmsReports from './pages/AdmsReports';
import AdmsUpdateCourses from './pages/AdmsUpdateCourses';
import AdmsReportsStudents from './pages/AdmsReportsStudents';
import AdmsReportsUsers from './pages/AdmsReportsUsers';
import AdmsCreateSchedules from './pages/AdmsCreateSchedules';
import AdmsViewSchedules from './pages/AdmsViewSchedules';
import AdmsCreatePackages from './pages/AdmsCreatePackages';

const AppRouter: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        {/* <Route path="/app" element={<App />} /> */}
        <Route path="/app/adms/logged" element={<Adms />} />
        <Route path="/app/students/logged" element={<Students />} />
        {/* <Route path="/app/adms/logged/reports" element={<AdmsReports />} /> */}
        {/* <Route path="/app/adms/logged/reports/students" element={<AdmsReportsStudents />} /> */}
        {/* <Route path="/app/adms/logged/reports/courses" element={<AdmsUpdateCourses />} /> */}
        {/* <Route path="/app/adms/logged/reports/users" element={<AdmsReportsUsers />} /> */}
        {/* <Route path="/app/adms/logged/personalizate" element={<AdmsPersonalizates />} /> */}
        {/* <Route path="/app/adms/logged/adm/new/users" element={<AdmsCreateNewUsers />} /> */}
        {/* <Route path="/app/adms/logged/adm/new/schedules" element={<AdmsCreateSchedules />} /> */}
        {/* <Route path="/app/adms/logged/adm/new/packages" element={<AdmsCreatePackages />} /> */}
        {/* <Route path="/app/adms/logged/adm/view/schedules" element={<AdmsViewSchedules />} /> */}
        {/* <Route path="/app/adms/logged/adm/new/courses" element={<AdmsCreateCourses />} /> */}
        {/* <Route path="/app/adms/logged/student/payments" element={<AdmsPayments />} /> */}
        {/* <Route path="/app/adms/logged/update/user/edit" element={<AdmUpdateStudentData />} /> */}
        {/* <Route path="/app/students/signup" element={<StudentSignup />} /> */}
        {/* <Route path="/app/students/logged/schedules" element={<StudentAgendamentos />} /> */}
        {/* <Route path="/app/students/logged/buypackages" element={<StudentPacotes />} /> */}
        {/* <Route path="/app/students/logged/payments" element={<StudentPayment />} /> */}
        {/* <Route path="/app/students/signup/payment" element={<StudentFirstPayments />} /> */}
        {/* Add additional routes as needed */}
      </Routes>
    </Router>
  );
};

export default AppRouter;
