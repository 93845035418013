import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import { MaterialsData } from "../interfaces/Turmas";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

interface ModalPdfProps {
  material: MaterialsData;
  close: any;
}

export default function ModalPdf({ material, close }: ModalPdfProps) {
  const [show, setShow] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleClose = () => {
    close();
    setShow(false);
  };

  const embedYouTubeVideo = (url: string) => {
    // Verifica se a URL fornecida é válida
    if (!url.includes("watch?v=")) {
      console.error("URL do YouTube inválida.");
      return;
    }

    // Extrai o código do vídeo da URL
    const videoCode = url.split("v=")[1].split("&")[0];

    // Cria a URL do src do iframe com o código do vídeo
    const iframeSrc = `https://www.youtube.com/embed/${videoCode}?si=9u9g2lbXaOfJOkkq`;

    // Retorna o código do iframe
    return (
      <>
        <iframe
          width="100%"
          height="620"
          src={iframeSrc}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      </>
    );
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        //backdrop="static"
        keyboard={false}
        size="xl"
        style={{ zIndex: "1201" }}
      >
        <Modal.Header style={{ textAlign: "center" }}>
          <Modal.Title
            style={{ width: "100%", color: "#000", textAlign: "center" }}
          >
            {material.fileName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {material.youtubeUrl === "" && (
            <div>
              <iframe
                src={material.iframeLink.includes("view?usp=sharing") ? material.iframeLink.replace("view?usp=sharing", "preview") : material.iframeLink}
                width="100%"
                height="700px"
                allow="autoplay"
                title={material.fileName}
              ></iframe>
            </div>
          )}
          {material.youtubeUrl !== "" && (
            <div>
              {embedYouTubeVideo(
                material.youtubeUrl ? material.youtubeUrl : ""
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div style={{display: 'flex', width: '100%', justifyContent: 'center', gap: '8px'}}>
        {material.youtubeUrl === "" && (
          <Button
            variant="primary"
            onClick={() => window.open(material.downloadLink, isMobile ? "_blank" : "_self")}
            className="btn btn-primary"
          >
            {isMobile ? 'BAIXAR' : 'BAIXAR MATERIAL'}
          </Button>
        )}
          <Button
            variant="secondary"
            onClick={handleClose}
            className="btn btn-secondary"
          >
            {material.youtubeUrl === "" ? (isMobile ? 'FECHAR' : 'FECHAR MATERIAL') : (isMobile ? 'FECHAR' : 'FECHAR VIDEOAULA')}
          </Button>

          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
